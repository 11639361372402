import { AntDesign } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import { useColorScheme } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { useQuery, gql, useMutation, useSubscription } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { TouchableHighlight, Text } from "../components/Themed";
import OperatorOrderView from "../components/OperatorOrderView";

const OPERATOR_DETAILS = gql`
  query getOperatorDetails($operatorId: ID!, $token: String!) {
    products(token: $token) {
      id
      title
      imageUrls
      capabilities
      prices
      category
      variants
    }
    categories(token: "token") {
      id
      name
      order
    }
    operator(token: $token, id: $operatorId) {
      id
      name
      timestampStart
      timestampEnd
      orders {
        id
        operator
        productIds
        quantities
        capabilities
        variants
        timestamp
        status
      }
    }
  }
`;

const OPERATORS_SUBSCRIPTION = gql`
  subscription {
    operators {
      id
      name
      timestampStart
      timestampEnd
      orders {
        id
        operator
        productIds
        quantities
        timestamp
        status
      }
    }
  }
`;

export default function OperatorBenefitsScreen(props) {
  const linkTo = useLinkTo();
  
  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(OPERATOR_DETAILS, {
    variables: { operatorId, token: token || '' },
    nextFetchPolicy: 'cache-only',
  });

  const { operatorsSubscriptionData, operatorsSubscriptionLoading } =
    useSubscription(OPERATORS_SUBSCRIPTION);

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerTitle: `Benefits`,
      headerTitleStyle: { fontFamily: "Pricedown-Bl" },
    })
  }, [props.navigation, data, token, operatorId]);

  
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  return <OperatorOrderView {...props} categories={data.categories} products={data.products} operator={data.operator} />;
}
