import { StyleSheet } from "react-native";
import {useEffect, useState} from 'react';
import { Text, View } from "../components/Themed";
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "../utils/localization";
import {Picker} from '@react-native-picker/picker';
import * as Localization from "expo-localization";

export default function MoreScreen() {
  const [locale, setLocale] = useState(Localization.locale || 'bg');

  useEffect(() => {
    async function fetchData() {
      try {
        const storedLocale = await AsyncStorage.getItem("locale");
        setLocale(storedLocale);
      } catch (ex) {}
    }
    fetchData();
  }, []);


  return (
    <View style={styles.container}>
      <Text style={{fontSize: 20, padding: 20}}>Language:</Text>
      <Picker
        selectedValue={locale || 'auto'}
        onValueChange={async (itemValue, itemIndex) => {
          setLocale(itemValue);
          if (itemValue !== 'auto') {
            i18n.locale = itemValue;
          }
          try {
            await AsyncStorage.setItem("locale", itemValue);
          } catch (ex) {}
        }}>
        <Picker.Item label="Auto" value="auto" />
        <Picker.Item label="English" value="en" />
        <Picker.Item label="Italiano" value="it" />
        <Picker.Item label="Български" value="bg" />
      </Picker>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
