import { StatusBar } from "expo-status-bar";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useQuery, useSubscription, gql } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { useLinkTo } from "@react-navigation/native";
import * as Themed from "../components/Themed";
import ComputerPicker from "../components/ComputerPicker";

const View = Themed.View;
const ThemedText = Themed.Text;

const COMPUTERS = gql`
  query getComputers($token: String!) {
    computers(token: $token) {
      id
      operator
      available
      published
      capacity
      type
      rate
      timestamp
      timestampStart
      timestampEnd
      number
      name
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        computerId
        capabilities
        timestamp
        status
      }
    }
  }
`;

const COMPUTERS_SUBSCRIPTION = gql`
  subscription {
    computers {
      id
      operator
      available
      published
      capacity
      number
      rate
      timestamp
      timestampStart
      timestampEnd
      name
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        computerId
        timestamp
        status
      }
    }
  }
`;

export default function OperatorComputersScreen({ navigation }) {
  const window = useWindowDimensions();

  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");

  const linkTo = useLinkTo();

  const { computersSubscriptionData, computersSubscriptionLoading } =
    useSubscription(COMPUTERS_SUBSCRIPTION);

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(COMPUTERS, {
    variables: { token },
  });

  if (loading) return <ThemedText>Loading...</ThemedText>;
  if (error) return <ThemedText>Error :(</ThemedText>;

  // alert(data.tables.length)

  return (
    <View style={styles.container}>
      <ComputerPicker type={'gamingpc'} computers={data.computers} operatorId={operatorId} onClick={(computer) => {
        linkTo(`/tables/operator-gaming/${computer.id}`);
      }}
      />
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
