import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Platform,
  useColorScheme,
  ImageBackground,
  TouchableHighlight,
  Animated,
  Easing,
} from "react-native";
import { View, Text } from "./Themed";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
import Image from "react-native-scalable-image";
import OutlineText from "./OutlineText";
import i18n from "../utils/localization";
import { getProductImageUrl, getCoffeeImageUrl } from "../utils/urls";

const CHANGE_ORDER_STATUS = gql`
  mutation changeOrderStatus(
    $token: String!
    $operator: ID!
    $table: ID!
    $id: ID!
    $status: String!
  ) {
    changeOrderStatus(
      token: $token
      operator: $operator
      table: $table
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      variants
      comment
      tableId
      capabilities
      timestamp
      status
    }
  }
`;

const CHANGE_DELIVERY_ORDER_STATUS = gql`
  mutation changeDeliveryOrderStatus(
    $token: String!
    $operator: ID!
    $deliveryId: ID!
    $id: ID!
    $status: String!
  ) {
    changeDeliveryOrderStatus(
      token: $token
      operator: $operator
      deliveryId: $deliveryId
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      variants
      comment
      deliveryId
      capabilities
      timestamp
      status
    }
  }
`;

const CHANGE_COMPUTER_ORDER_STATUS = gql`
  mutation changeComputerOrderStatus(
    $token: String!
    $operator: ID!
    $computer: ID!
    $id: ID!
    $status: String!
  ) {
    changeComputerOrderStatus(
      token: $token
      operator: $operator
      computer: $computer
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      comment
      variants
      capabilities
      computerId
      timestamp
      status
    }
  }
`;

const CHANGE_OPERATOR_ORDER_STATUS = gql`
  mutation changeOperatorOrderStatus(
    $token: String!
    $operator: ID!
    $id: ID!
    $status: String!
  ) {
    changeOperatorOrderStatus(
      token: $token
      operator: $operator
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      comment
      capabilities
      variants
      timestamp
      status
    }
  }
`;

export default function OrderItemGrouped({
  tableId,
  computerId,
  deliveryId,
  products,
  title,
  orders,
  capabilities,
}) {
  const colorScheme = useColorScheme();
  const [completed, setCompleted] = useState(false);

  const [operatorId, setOperatorId] = useState(null);

  const [timeoutHandle, setTimeoutHandle] = useState(null);

  const [token, setToken] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const [
    changeOrdersStatus,
    {
      changeOrdersStatusData,
      changeOrdersStatusLoading,
      changeOrdersStatusError,
    },
  ] = useMutation(CHANGE_ORDER_STATUS);

  const [
    changeDeliveryOrdersStatus,
    {
      changeDeliveryOrdersStatusData,
      changeDeliveryOrdersStatusLoading,
      changeDeliveryOrdersStatusError,
    },
  ] = useMutation(CHANGE_DELIVERY_ORDER_STATUS);

  const [
    changeComputerOrdersStatus,
    {
      changeComputerOrdersStatusData,
      changeComputerOrdersStatusLoading,
      changeComputerOrdersStatusError,
    },
  ] = useMutation(CHANGE_COMPUTER_ORDER_STATUS);

  const [
    changeOperatorOrdersStatus,
    {
      changeOperatorOrdersStatusData,
      changeOperatorOrdersStatusLoading,
      changeOperatorOrdersStatusError,
    },
  ] = useMutation(CHANGE_OPERATOR_ORDER_STATUS);

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const flyUpAnim = useRef(new Animated.Value(0)).current;
  const spinValue = useRef(new Animated.Value(0)).current;

  // Next, interpolate beginning and end values (in this case 0 and 1)
  const spinAnim = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });
  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 100,
    }).start();

    Animated.timing(flyUpAnim, {
      toValue: 5000,
      duration: 5000,
    }).start();

    Animated.timing(spinValue, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear, // Easing is an additional import from react-native
      useNativeDriver: Platform.OS !== "web", // To make use of native driver for performance
    }).start();
  };

  const fadeIn = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 5000,
    }).start();

    Animated.timing(flyUpAnim, {
      toValue: 0,
      duration: 2000,
    }).start();

    Animated.timing(spinValue, {
      toValue: 0,
      duration: 3000,
      easing: Easing.linear, // Easing is an additional import from react-native
      useNativeDriver: Platform.OS !== "web", // To make use of native driver for performance
    }).start();
  };
  const meta = {};

  const newOrders = orders.filter((o) => o.status === "new");
  let orderIndex = 0;
  for (let order of newOrders) {
    let productIndex = 0;

    for (let productId of order.productIds) {
      meta[productId] = meta[productId] || {
        count: 0,
        orderIndices: [],
        productIndices: [],
      };
      meta[productId].count++;
      meta[productId].productIndices.push(productIndex);
      meta[productId].orderIndices.push(orderIndex);
      productIndex++;
    }
    orderIndex++;
  }

  const productIds = Object.keys(meta);

  return (
    <TouchableHighlight
      style={{ flex: 1 }}
      onPress={() => {
        if (completed) {
          fadeIn();
          if (timeoutHandle) {
            clearTimeout(timeoutHandle);
            setTimeoutHandle(null);
          }
          setCompleted(false);
        } else {
          fadeOut();
          setTimeoutHandle(
            setTimeout(async () => {
              if (computerId) {
                for (let ord of orders) {
                  await changeComputerOrdersStatus({
                    variables: {
                      id: ord.id,
                      token: token,
                      operator: operatorId,
                      computer: computerId,
                      status: "ready",
                    },
                  });
                }
              } else if (tableId) {
                for (let ord of orders) {
                  await changeOrdersStatus({
                    variables: {
                      id: ord.id,
                      token: token,
                      operator: operatorId,
                      table: tableId,
                      status: "ready",
                    },
                  });
                }
              } else if (deliveryId) {
                for (let ord of orders) {
                  await changeDeliveryOrdersStatus({
                    variables: {
                      id: ord.id,
                      token: token,
                      operator: operatorId,
                      deliveryId: deliveryId,
                      status: "ready",
                    },
                  });
                }
              } else {
                for (let ord of newOrders) {
                  await changeOperatorOrdersStatus({
                    variables: {
                      id: ord.id,
                      token: token,
                      operator: operatorId,
                      status: "ready",
                    },
                  });
                }
              }
            }, 5000)
          );
          setCompleted(true);
        }
      }}
    >
      <View style={styles.item}>
        <View>
          {productIds.map((productId, index) => {
            const product = products.filter((p) => p.id === productId)[0];
            //const price = product.prices[indexOfVariant];

            let optionTitles = [];
            return (
              <View
                style={{ flexDirection: "row", padding: 0 }}
                key={productId + index}
              >
                <ImageBackground
                  imageStyle={{ flex: 1, flexGrow: 1 }}
                  style={[
                    {
                      flex: 1,
                      flexGrow: 1,
                      justifyContent: "center",
                      resizeMode: "cover",
                    },
                  ]}
                  source={{
                    uri: getProductImageUrl(product.imageUrls[0]),
                  }}
                >
                  {false &&
                    product.capabilities.map((cap, capIndex) => {
                      if (cap === "printPicture" && capabilities[capIndex]) {
                        return (
                          <Image
                            key={cap + "" + capIndex}
                            width={100}
                            style={[
                              {
                                position: "absolute",
                                right: 10,
                                bottom: 10,
                                justifyContent: "center",
                                resizeMode: "cover",
                              },
                            ]}
                            source={{
                              uri: getCoffeeImageUrl(capabilities[capIndex]),
                            }}
                          ></Image>
                        );
                      }
                      return null;
                    })}
                  <OutlineText
                    style={styles.title}
                    colorScheme={colorScheme}
                    text={meta[productId].count + " x " + product.title + " "}
                  />
                  <View
                    style={{
                      backgroundColor: "transparent",
                      paddingLeft: 20,
                    }}
                  >
                    {meta[productId].orderIndices.map(
                      (orderIndex, metaIndex) => {
                        const order = newOrders[orderIndex];
                        const productIndex =
                          meta[productId].productIndices[metaIndex];
                        const variant = order.variants[productIndex];
                        const options = order.options;
                        const indexOfVariant = product.variants.findIndex(
                          (v) => v === variant
                        );
                        let optionTitles = [];
                        const optionsCost = options
                          ? options.reduce((acc, v, i) => {
                              if (i % 2 == 0) {
                                const poption = product.options.filter(
                                  (o) => o.id === v
                                )[0];
                                const pchoice = poption.options.filter(
                                  (o) => o.id === options[i + 1]
                                )[0];
                                optionTitles.push({
                                  name: poption.title,
                                  value: pchoice.title,
                                  title: poption.title + " " + pchoice.title,
                                });
                                return acc + pchoice.prices[indexOfVariant];
                              } else {
                                return acc + 0.0;
                              }
                            }, 0.0)
                          : 0.0;

                        return (
                          <View
                            key={orderIndex + "_" + productIndex}
                            style={{
                              backgroundColor: "transparent",
                              paddingLeft: 20,
                            }}
                          >
                            <OutlineText
                              style={styles.subtitle}
                              colorScheme={colorScheme}
                              text={variant}
                            />
                            <View
                              style={{
                                backgroundColor: "transparent",
                                paddingLeft: 20,
                                flexDirection: "row",
                              }}
                            >
                              <OutlineText
                                style={styles.subtitle}
                                colorScheme={colorScheme}
                                text={optionTitles
                                  .map((o) => o.title)
                                  .join(", ")}
                              />
                            </View>
                          </View>
                        );
                      }
                    )}
                  </View>
                  {/*                     <OutlineText style={styles.title} colorScheme={colorScheme} text={optionTitles.map(opt => `${i18n.t(opt.name)}: ${i18n.t(opt.value)}`).join('\n')} />
                    <OutlineText style={styles.title} colorScheme={colorScheme} text={comment} />

                    */}
                </ImageBackground>
              </View>
            );
          })}
        </View>

        <View style={{}}>
          <Animated.View
            useNativeDriver={Platform.OS !== "web"}
            style={[
              {
                position: "absolute",
                bottom: flyUpAnim,
                transform: [{ rotate: spinAnim }],
                // Bind opacity to animated value
                opacity: fadeAnim,
              },
            ]}
          >
            <Text style={{ color: "white", fontSize: 100 }}>⭐</Text>
          </Animated.View>
          <OutlineText
            style={styles.title}
            colorScheme={colorScheme}
            text={title}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  item: {
    flexGrow: 1,
    justifyContent: "space-between",
    borderColor: "white",
    borderWidth: 1,
    margin: 5,
    fontSize: 60,
    padding: 20,
    borderRadius: 12,
  },

  buttonsContainer: {
    padding: 15,
    flexDirection: "row",
    margin: 15,
  },
  subtitle: {
    fontSize: 30,
    margin: 10,
    fontWeight: "bold",
  },
  title: {
    fontSize: 30,
    margin: 10,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
