const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

const hrlm = {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    underlay: "#eee",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    underlay: "#222",
  },
}

const nizza = {
  light: {
    text: "#000",
    background: "#fff",
    tint: "#c00a25",
    tabIconDefault: "#ccc",
    tabIconSelected: "#c00a25",
    underlay: "#eee",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: "#c00a25",
    tabIconDefault: "#ccc",
    tabIconSelected: "#c00a25",
    underlay: "#222",
  },
}

export default nizza;
