import { StyleSheet, FlatList, useWindowDimensions } from "react-native";
import Image from 'react-native-scalable-image';
import { Text, View } from "../components/Themed";
import { getMarkeringUrl } from '../utils/urls';

export default function EsportsScreen() {
  const window = useWindowDimensions();
  const numColumns = 1;
  return (
    <View style={styles.container}>
      <FlatList
        data={[
          getMarkeringUrl('csgo.jpg'),
          getMarkeringUrl('league-of-legends.jpg'),
          getMarkeringUrl('fifa2023.webp'),
          getMarkeringUrl('f12022.jpg'),
          getMarkeringUrl('forza-horizon-5.jpg'),
          getMarkeringUrl('gtaonline.webp'),
          getMarkeringUrl('cs16.jpg'),
          getMarkeringUrl('half-life.jpg'),
          getMarkeringUrl('fortnite.jpg'),
          getMarkeringUrl('overwatch.jpg'),
          getMarkeringUrl('rtx.png'),
          getMarkeringUrl('alienware.jpg'),
          getMarkeringUrl('prox.jpg'),
          getMarkeringUrl('zen3.jpg'),
        ]}
        renderItem={({ item }) => (
          <Image width={window.width / numColumns} source={{ uri: item }} />
        )}
        //Setting the number of column
        numColumns={numColumns}
        keyExtractor={(item, index) => index}
      />
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
