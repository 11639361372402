import { getStack } from "../constants/Stacks";

const stack = getStack();

export const getMarkeringUrl = (url) => {
    return url.indexOf('http') === 0 ? url : `${stack.imageCDN}/${url}`;
}

export const getProductImageUrl = (url) => {
    return url.indexOf('http') === 0 ? url : `${stack.imageCDN}/${url}`;
}

export const getCoffeeImageUrl = (url) => {
    return url.indexOf('http') === 0 ? url : `${stack.coffeeCDN}/${url}`;
}