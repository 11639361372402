import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: animate */

function SvgComponent(props) {
  return (
    <Svg
      viewBox="0 0 77.414 24.732"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <LinearGradient id="b" x1="0%" y1="100%" x2="0%" y2="0%">
          <Stop offset={0} stopColor="hsl(0, 100%, 50%)"></Stop>
          <Stop offset={1} stopColor="hsl(0, 100%, 50%)"></Stop>
        </LinearGradient>
        <LinearGradient id="d" x1="0%" y1="100%" x2="0%" y2="0%">
          <Stop offset={0} stopColor="hsl(0, 100%, 50%)"></Stop>
          <Stop offset={1} stopColor="hsl(0, 100%, 50%)"></Stop>
        </LinearGradient>
        <LinearGradient id="f" x1="0%" y1="0%" x2="0%" y2="100%">
          <Stop offset={0} stopColor="hsl(0, 100%, 50%)"></Stop>
          <Stop offset={1} stopColor="hsl(0, 100%, 50%)"></Stop>
        </LinearGradient>
      </Defs>
      <Path
        d="M43.467 8.002L42 9.469l4.418 4.417-9.433 9.433 1.467 1.467 10.9-10.9-1.467-1.467z"
        transform="translate(-.052 -.054)"
        fill="url(#a)"
        fillRule="evenodd"
        strokeWidth={0.0851596}
      />
      <Path
        d="M43.467 8.002L42 9.469l4.418 4.417-9.433 9.433 1.467 1.467 10.9-10.9-1.467-1.467z"
        transform="translate(-.052 -.054)"
        fill="url(#b)"
        fillRule="evenodd"
        strokeWidth={0.0851596}
      />
      <Path
        d="M49.351 10.953l10.9-10.9 1.466 1.467-10.9 10.9z"
        transform="translate(-.052 -.054)"
        fill="url(#c)"
        fillRule="evenodd"
        strokeWidth={0.0851596}
      />
      <Path
        d="M49.351 10.953l10.9-10.9 1.466 1.467-10.9 10.9z"
        transform="translate(-.052 -.054)"
        fill="url(#d)"
        fillRule="evenodd"
        strokeWidth={0.0851596}
      />
      <Path
        d="M53.769 21.238L49.35 16.82l1.467-1.467 4.418 4.418z"
        transform="translate(-.052 -.054)"
        fill="url(#e)"
        fillRule="evenodd"
        strokeWidth={0.054217}
      />
      <Path
        d="M53.769 21.238L49.35 16.82l1.467-1.467 4.418 4.418z"
        transform="translate(-.052 -.054)"
        fill="url(#f)"
        fillRule="evenodd"
        strokeWidth={0.054217}
      />
    </Svg>
  )
}

export default SvgComponent
