import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Platform,
  useColorScheme,
  ImageBackground,
  TouchableHighlight,
  Animated,
  Easing,
} from "react-native";
import { View, Text } from "./Themed";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
import Image from "react-native-scalable-image";

import { Entypo, FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { getProductImageUrl, getCoffeeImageUrl } from "../utils/urls";
import { useLinkTo } from "@react-navigation/native";

const CHANGE_ORDER_STATUS = gql`
  mutation changeOrderStatus(
    $token: String!
    $operator: ID!
    $table: ID!
    $id: ID!
    $status: String!
  ) {
    changeOrderStatus(
      token: $token
      operator: $operator
      table: $table
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      variants
      comment
      tableId
      capabilities
      timestamp
      status
    }
  }
`;

const CHANGE_COMPUTER_ORDER_STATUS = gql`
  mutation changeComputerOrderStatus(
    $token: String!
    $operator: ID!
    $computer: ID!
    $id: ID!
    $status: String!
  ) {
    changeComputerOrderStatus(
      token: $token
      operator: $operator
      computer: $computer
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      comment
      variants
      capabilities
      computerId
      timestamp
      status
    }
  }
`;

const CHANGE_OPERATOR_ORDER_STATUS = gql`
  mutation changeOperatorOrderStatus(
    $token: String!
    $operator: ID!
    $id: ID!
    $status: String!
  ) {
    changeOperatorOrderStatus(
      token: $token
      operator: $operator
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      comment
      capabilities
      variants
      timestamp
      status
    }
  }
`;



export default function DeliveryItem({
  id,
  tableId,
  computerId,
  productIds,
  variants,
  quantities,
  capabilities,
  comment,
  products,
  title,
  orders,
  city,
  street,
  phone,
}) {
  const colorScheme = useColorScheme();
  const [completed, setCompleted] = useState(false);

  const [operatorId, setOperatorId] = useState(null);

  const [timeoutHandle, setTimeoutHandle] = useState(null);

  const [token, setToken] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const [
    changeOrdersStatus,
    {
      changeOrdersStatusData,
      changeOrdersStatusLoading,
      changeOrdersStatusError,
    },
  ] = useMutation(CHANGE_ORDER_STATUS);

  const [
    changeComputerOrdersStatus,
    {
      changeComputerOrdersStatusData,
      changeComputerOrdersStatusLoading,
      changeComputerOrdersStatusError,
    },
  ] = useMutation(CHANGE_COMPUTER_ORDER_STATUS);

  const [
    changeOperatorOrdersStatus,
    {
      changeOperatorOrdersStatusData,
      changeOperatorOrdersStatusLoading,
      changeOperatorOrdersStatusError,
    },
  ] = useMutation(CHANGE_OPERATOR_ORDER_STATUS);

  

  const spinValue = useRef(new Animated.Value(0)).current;
  const jumpValue = useRef(new Animated.Value(0)).current;
  const moveValue = useRef(new Animated.Value(0)).current;

  // Next, interpolate beginning and end values (in this case 0 and 1)
  const spinAnim = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  const jumpAnim = jumpValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -10],
  });

  
  const moveAnim = moveValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 100],
  });

  useEffect(() => {
    Animated.loop(
      Animated.timing(spinValue, {
        toValue: 1,
        duration: 6000,
        easing: Easing.linear, // Easing is an additional import from react-native
        useNativeDriver: Platform.OS !== "web", // To make use of native driver for performance
      })
    ).start();

    Animated.loop(
      Animated.timing(jumpValue, {
        toValue: 1,
        duration: 1000,
        easing: Easing.easing, // Easing is an additional import from react-native
        useNativeDriver: Platform.OS !== "web", // To make use of native driver for performance
      })
    ).start();

    
    Animated.loop(
      Animated.timing(moveValue, {
        toValue: 1,
        duration: 10000,
        easing: Easing.easing, // Easing is an additional import from react-native
        useNativeDriver: Platform.OS !== "web", // To make use of native driver for performance
      })
    ).start();
  }, []);

  const linkTo = useLinkTo();

  const isNew = orders.filter((o) => o.status === "new").length > 0;
  const isReady =
    orders.filter((o) => o.status === "ready").length > 0;
  const isDelivering =
    orders.filter((o) => o.status === "delivering").length > 0;
  return (
    <TouchableHighlight
      style={{
        margin: 20,
      }}
      onPress={() => {
        linkTo(`/tables/deliveries/${id}`);
      }}
    >
      <View style={[styles.item, {borderColor: colorScheme === "dark" ? "white" : "black"}]}>
        <View styles={{ padding: 15 }}>
          <Text>
            {street
              ? `${street}${city ? ", " + city : ""}${
                  phone ? " - " + phone : ""
                }${comment ? " | " + comment : ""}`
              : id}
          </Text>
        </View>
        {isNew && (
          <Animated.View
            useNativeDriver={Platform.OS !== "web"}
            style={[
              {
                position: "absolute",
                bottom: 2,
                right: 2,
                transform: [{ rotate: spinAnim }],
                // Bind opacity to animated value
                opacity: 1.0,
              },
            ]}
          >
            <MaterialCommunityIcons
              name="cog-outline"
              size={24}
              color={colorScheme === "dark" ? "white" : "black"}
            />
          </Animated.View>
        )}
        {isReady && (
          <Animated.View
            useNativeDriver={Platform.OS !== "web"}
            style={[
              {
                position: "absolute",
                top: 10,
                right: 0,
                transform: [{ translateY: jumpAnim }],
                // Bind opacity to animated value
                opacity: 1.0,
              },
            ]}
          >
            <Entypo
              name="check"
              size={24}
              color={colorScheme === "dark" ? "white" : "black"}
            />
          </Animated.View>
        )}
        
        {isDelivering && (
          <Animated.View
            useNativeDriver={Platform.OS !== "web"}
            style={[
              {
                position: "absolute",
                bottom: 2,
                left: 2,
                transform: [{ translateX: moveAnim }],
                // Bind opacity to animated value
                opacity: 1.0,
              },
            ]}
          >
            <MaterialIcons
              name="electric-bike"
              size={24}
              color={colorScheme === "dark" ? "white" : "black"}
            />
          </Animated.View>
        )}
      </View>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  item: {
    borderWidth: 1,
    borderRadius: 15,
    padding: 20,
    flex: 1,
    flexGrow: 1,
    paddingBottom: 50,
    fontSize: 60,
  },

  buttonsContainer: {
    padding: 15,
    flexDirection: "row",
    margin: 15,
  },
  title: {
    flex: 1,
    fontSize: 30,
    margin: 10,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
