import { StatusBar } from "expo-status-bar";
import { useWindowDimensions, Platform, StyleSheet, FlatList, Text, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { View } from "../components/Themed";
import { useQuery, gql } from "@apollo/client";
import Config from "../constants/Config";

const SUMMARY_QUERY = gql`query getSummary($adminToken: String!, $dateString: String!){
  summary(token: $adminToken, date: $dateString){
total
operators{
  name
  amount
}}}`;



export default function OperatorSettingsSummaryScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const window = useWindowDimensions();
  const colorScheme = useColorScheme();
  const [dateString] = useState((new Date()).toISOString());


  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}

      try {
        const storedAdminToken = await AsyncStorage.getItem("adminToken");
        setAdminToken(storedAdminToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(SUMMARY_QUERY, {
    variables: { adminToken, dateString },
  });
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;
  return (
    <View style={styles.container}>
        <View style={{flex: 1}}>
          <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 80}}>Total {data.summary.total}</Text>
          <FlatList 
            data={data.summary.operators}
            keyExtractor={(item) => item.name}
            renderItem={({item}) => <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 60}}>{item.name} {item.amount}</Text>}
          />
        </View>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
