import React, {useState, useEffect, useRef} from 'react';
import { StyleSheet, Platform, useColorScheme, ImageBackground, TouchableHighlight, Animated, Easing } from "react-native";
import { View, Text } from "./Themed";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
import Image from 'react-native-scalable-image';
import OutlineText from './OutlineText';
import i18n from "../utils/localization";
import { getProductImageUrl, getCoffeeImageUrl } from "../utils/urls";

const CHANGE_ORDER_STATUS = gql`
  mutation changeOrderStatus($token: String!, $operator: ID!, $table: ID!, $id: ID!, $status: String!) {
    changeOrderStatus(token: $token, operator: $operator, table: $table, id: $id, status: $status) {
      id
      operator
      productIds
      quantities
      variants
      comment
      tableId
      capabilities
      timestamp
      status
    }
  }
`;

const CHANGE_DELIVERY_ORDER_STATUS = gql`
  mutation changeDeliveryOrderStatus($token: String!, $operator: ID!, $deliveryId: ID!, $id: ID!, $status: String!) {
    changeDeliveryOrderStatus(token: $token, operator: $operator, deliveryId: $deliveryId, id: $id, status: $status) {
      id
      operator
      productIds
      quantities
      variants
      comment
      deliveryId
      capabilities
      timestamp
      status
    }
  }
`;

const CHANGE_COMPUTER_ORDER_STATUS = gql`
  mutation changeComputerOrderStatus($token: String!, $operator: ID!, $computer: ID!, $id: ID!, $status: String!) {
    changeComputerOrderStatus(token: $token, operator: $operator, computer: $computer, id: $id, status: $status) {
      id
      operator
      productIds
      quantities
      comment
      variants
      capabilities
      computerId
      timestamp
      status
    }
  }
`;

const CHANGE_OPERATOR_ORDER_STATUS = gql`
  mutation changeOperatorOrderStatus($token: String!, $operator: ID!, $id: ID!, $status: String!) {
    changeOperatorOrderStatus(token: $token, operator: $operator, id: $id, status: $status) {
      id
      operator
      productIds
      quantities
      comment
      capabilities
      variants
      timestamp
      status
    }
  }
`;

export default function OrderItem({
  id,
  tableId,
  computerId,
  deliveryId,
  productIds,
  variants,
  quantities,
  options,
  capabilities,
  comment,
  products,
  title
}) {
  const colorScheme = useColorScheme();
  const [completed, setCompleted] = useState(false);

  const [operatorId, setOperatorId] = useState(null);

  const [timeoutHandle, setTimeoutHandle] = useState(null);

  const [token, setToken] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  
 

  const [
    changeOrdersStatus,
    { changeOrdersStatusData, changeOrdersStatusLoading, changeOrdersStatusError },
  ] = useMutation(CHANGE_ORDER_STATUS);

  const [
    changeDeliveryOrdersStatus,
    { changeDeliveryOrdersStatusData, changeDeliveryOrdersStatusLoading, changeDeliveryOrdersStatusError },
  ] = useMutation(CHANGE_DELIVERY_ORDER_STATUS);


  const [
    changeComputerOrdersStatus,
    { changeComputerOrdersStatusData, changeComputerOrdersStatusLoading, changeComputerOrdersStatusError },
  ] = useMutation(CHANGE_COMPUTER_ORDER_STATUS);

  const [
    changeOperatorOrdersStatus,
    { changeOperatorOrdersStatusData, changeOperatorOrdersStatusLoading, changeOperatorOrdersStatusError },
  ] = useMutation(CHANGE_OPERATOR_ORDER_STATUS);

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const flyUpAnim = useRef(new Animated.Value(0)).current;
  const spinValue = useRef(new Animated.Value(0)).current;

  // Next, interpolate beginning and end values (in this case 0 and 1)
  const spinAnim = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })
  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 100
    }).start();

    Animated.timing(flyUpAnim, {
      toValue: 5000,
      duration: 5000
    }).start();

    Animated.timing(
      spinValue,
      {
        toValue: 1,
        duration: 3000,
        easing: Easing.linear, // Easing is an additional import from react-native
        useNativeDriver: Platform.OS !== 'web'  // To make use of native driver for performance
      }
    ).start()
  };

  const fadeIn = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 5000
    }).start();

    Animated.timing(flyUpAnim, {
      toValue: 0,
      duration: 2000
    }).start();

    Animated.timing(
      spinValue,
      {
        toValue: 0,
        duration: 3000,
        easing: Easing.linear, // Easing is an additional import from react-native
        useNativeDriver: Platform.OS !== 'web'  // To make use of native driver for performance
      }
    ).start()
  };

  return (
    <View style={styles.item}>
        {productIds.map((productId, index) => {
          const product = products.filter((p) => p.id === productId)[0];
          const indexOfVariant = product.variants.findIndex(
            (v) => v === variants[index]
          );
          const price = product.prices[indexOfVariant];


          
          let optionTitles= [];
          const optionsCost = options ? options.reduce((acc, v, i) => {
            if (i % 2 == 0) {
              const poption = product.options.filter(o => o.id === v)[0];
              const pchoice = poption.options.filter(o => o.id === options[i+1])[0]
              optionTitles.push({name: poption.title, value: pchoice.title})
              return acc + pchoice.prices[indexOfVariant];
            } else {
              return acc + 0.0;
            }
          }, 0.0) : 0.0;
          return (
            <View style={{ flexDirection: "row", padding: 5, flex: 1, flexGrow: 1 }} key={index}>
              <TouchableHighlight style={{flex: 1, flexGrow: 1}} onPress={() => {
                if (completed) {
                  fadeIn();
                  if (timeoutHandle) {
                    clearTimeout(timeoutHandle);
                    setTimeoutHandle(null);
                  }
                  setCompleted(false);
                } else {
                  fadeOut();
                  setTimeoutHandle(setTimeout(() => {
                    if (computerId) {
                      changeComputerOrdersStatus({
                        variables: {
                          id: id,
                          token: token,
                          operator: operatorId,
                          computer: computerId,
                          status: 'ready',
                        },
                      });
                    } else if (tableId) {
                      changeOrdersStatus({
                        variables: {
                          id: id,
                          token: token,
                          operator: operatorId,
                          table: tableId,
                          status: 'ready',
                        },
                      });
                    } else if (deliveryId) {
                      changeDeliveryOrdersStatus({
                        variables: {
                          id: id,
                          token: token,
                          operator: operatorId,
                          deliveryId: deliveryId,
                          status: 'ready',
                        },
                      });
                    } else {
                      changeOperatorOrdersStatus({
                        variables: {
                          id: id,
                          token: token,
                          operator: operatorId,
                          status: 'ready',
                        },
                      });
                    }
                    
                  }, 5000));
                  setCompleted(true);
                }
                
              }}>
                <ImageBackground
                  imageStyle={{borderRadius: 20, flex: 1, flexGrow: 1}}
                  style={[
                    {
                      flex: 1, flexGrow: 1,
                      justifyContent: "center",
                      resizeMode: "cover",
                    }
                  ]}
                  source={{
                    uri: getProductImageUrl(product.imageUrls[0])
                  }}
                >
                  {product.capabilities.map((cap, capIndex) => {
                      if (cap === 'printPicture' && capabilities[capIndex]) {
                        
                        return <Image
                          key = {cap+''+capIndex}
                          width = {100}
                          style={[
                            {
                              position: 'absolute',
                              right: 10,
                              bottom: 10,
                              justifyContent: "center",
                              resizeMode: "cover",
                            }
                          ]}
                          source={{
                            uri: getCoffeeImageUrl(capabilities[capIndex])
                          }}
                        ></Image>
                      }
                      return null;
                    })}
                    <OutlineText style={styles.title} colorScheme={colorScheme} text={product.title + ' '} />
                    <OutlineText style={styles.title} colorScheme={colorScheme} text={variants[index]} />
                    <OutlineText style={styles.title} colorScheme={colorScheme} text={optionTitles.map(opt => `${i18n.t(opt.name)}: ${i18n.t(opt.value)}`).join('\n')} />
                    <OutlineText style={styles.title} colorScheme={colorScheme} text={comment} />
                    <OutlineText style={styles.title} colorScheme={colorScheme} text={title} />
                  {/* <View style={{backgroundColor: 'transparent'}}>
                    <View style={{backgroundColor: 'transparent'}}>
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {product.title} {" "}
                      </Text>
                      {variants[index] && <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {variants[index]}
                      </Text>}
                      {comment ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {comment}
                      </Text> : null}
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {title}
                      </Text>
                    </View>
                    <View style={{position: 'absolute', backgroundColor: 'transparent'}}>
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {product.title} {" "}
                      </Text>
                      {variants[index] ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {variants[index]}
                      </Text> : null}
                      {comment ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {comment}
                      </Text> : null}
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {title}
                      </Text>
                    </View>
                    <View style={{position: 'absolute', backgroundColor: 'transparent'}}>
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {product.title} {" "}
                      </Text>
                      {variants[index] ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {variants[index]}
                      </Text> : null}
                      {comment ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {comment}
                      </Text> : null}
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: -1, height: 1 },
                        textShadowRadius: 1,
                      }]}>
                        {title}
                      </Text>
                    </View>
                    <View style={{position: 'absolute', backgroundColor: 'transparent'}}>
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {product.title} {" "}
                      </Text>
                      {variants[index] ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {variants[index]}
                      </Text> : null}
                      {comment ? <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {comment}
                      </Text> : null}
                      <Text style={[styles.title, {
                        color: colorScheme === "dark" ? "white" : "black",
                        textShadowColor: colorScheme === "dark" ? "black" : "white",
                        textShadowOffset: { width: 1, height: -1 },
                        textShadowRadius: 1,
                      }]}>
                        {title}
                      </Text>
                    </View>
   
                    
                  </View> */}
                  <Animated.View useNativeDriver={Platform.OS !== 'web'} style={[
                      {
                        position: 'absolute',
                        bottom: flyUpAnim,
                        transform: [{rotate: spinAnim}],
                        // Bind opacity to animated value
                        opacity: fadeAnim
                      }
                    ]}>
                      <Text style={{color: 'white', fontSize: 100}}>⭐</Text>
                    </Animated.View>
                </ImageBackground>
              </TouchableHighlight>
            </View>
          );
        })}
    </View>
  );
}

const styles = StyleSheet.create({
  item: {
    flex: 1, flexGrow: 1,
    fontSize: 60,
  },

  buttonsContainer: {
    padding: 15,
    flexDirection: "row",
    margin: 15,
  },
  title: {
    flex: 1,
    fontSize: 30,
    margin: 10,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
