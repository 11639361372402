import React from "react";
import { StatusBar } from "expo-status-bar";
import {
  Platform,
  StyleSheet,
  useColorScheme,
  TouchableOpacity,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { Text, View, TouchableHighlight } from "../components/Themed";
import { useLinkTo } from "@react-navigation/native";
import { useQuery, gql, useSubscription } from "@apollo/client";
import * as Themed from "../components/Themed";
import ComputerOrderView from "../components/ComputerOrderView";
import i18n from "../utils/localization";

const ThemedText = Themed.Text;

const COMPUTER_DETAILS = gql`
  query getComputerDetails($computerId: ID!, $token: String!) {
    products(token: $token) {
      id
      title
      imageUrls
      prices
      capabilities
      category
      variants
    }
    
    computers(token: $token) {
      id
      operator
      available
      published
      capacity
      type
      rate
      timestamp
      timestampStart
      timestampEnd
      name
      token
      x
      y
      orders {
        id
        operator
        productIds
        capabilities
        quantities
        computerId
        timestamp
        status
      }
    }
    computer(token: $token, id: $computerId) {
      id
      operator
      available
      published
      capacity
      type
      rate
      timestamp
      timestampStart
      timestampEnd
      name
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        capabilities
        variants
        computerId
        timestamp
        status
      }
    }
  }
`;

const COMPUTERS_SUBSCRIPTION = gql`
  subscription {
    computers {
      id
      operator
      available
      published
      capacity
      type
      rate
      timestamp
      timestampStart
      timestampEnd
      name
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        computerId
        variants
        timestamp
        status
      }
    }
  }
`;

export default function PublicComputerScreen(props) {
  const linkTo = useLinkTo();
  const computerId = props.route.params && props.route.params.computerId;
  const token = props.route.params && props.route.params.computerToken;
  const colorScheme = useColorScheme;

  const { loading, error, data } = useQuery(COMPUTER_DETAILS, {
    variables: { computerId, token: token },
    nextFetchPolicy: 'cache-only',
  });

  console.log(data)

  const ret =  useSubscription(COMPUTERS_SUBSCRIPTION);
  
  if (loading) return <ThemedText>Loading...</ThemedText>;
  
  if (!data || (data && !data.computer.token))
    return (
      <View style={styles.container}>
        <Text style={styles.title}>wasted</Text>
        <TouchableOpacity
          onPress={() => props.navigation.replace("Menu")}
          style={styles.link}
        >
          <Text style={styles.linkText}>Please, ask staff for help! | Click for main menu</Text>
        </TouchableOpacity>
      </View>
    );

  let computer = data.computer;
  if (ret.data) {
    computer = ret.data.computers.filter(t => t.id === computerId)[0];
  }


  return <ComputerOrderView banner={i18n.t('Поръчай тук')} {...props} products={data.products} computer={computer} categories={data.categories}/>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Pricedown-Bl",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    fontFamily: "Pricedown-Bl",
    color: "#2e78b7",
  },
});
