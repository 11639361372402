import { StatusBar } from "expo-status-bar";
import { Button, Platform, StyleSheet, TextInput, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { useLinkTo } from "@react-navigation/native";
import { View } from "../components/Themed";

export default function OperatorSettingsProfileScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const linkTo = useLinkTo();
  const colorScheme = useColorScheme();

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}

      try {
        const storedAdminToken = await AsyncStorage.getItem("adminToken");
        setAdminToken(storedAdminToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  return (
    <View style={styles.container}>
      <TextInput
        style={[
          styles.input,
          colorScheme === "dark"
            ? styles.darkThemeColors
            : styles.lightThemeColors,
        ]}
        onChangeText={async (value) => {
          try {
            await AsyncStorage.setItem("operatorId", value);
            setOperatorId(value);
          } catch (ex) {}
        }}
        value={operatorId}
      />

      <TextInput
        style={[
          styles.input,
          colorScheme === "dark"
            ? styles.darkThemeColors
            : styles.lightThemeColors,
        ]}
        onChangeText={async (value) => {
          try {
            await AsyncStorage.setItem("token", value);
            setToken(value);
          } catch (ex) {}
        }}
        value={token}
      />

      <TextInput
        style={[
          styles.input,
          colorScheme === "dark"
            ? styles.darkThemeColors
            : styles.lightThemeColors,
        ]}
        onChangeText={async (value) => {
          try {
            await AsyncStorage.setItem("adminToken", value);
            setAdminToken(value);
          } catch (ex) {}
        }}
        value={adminToken}
      />
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
