
const ENVIRONMENTS = {
  production: {
    subscriptions: "wss://hrlm.bg/subscriptions",
    graphql: "https://hrlm.bg/graphql",
    web: "https://hrlm.bg",
    imageCDN: 'https://hrlm.bg/cdn',
    coffeeCDN: 'https://hrlm.bg/coffeePrint'
  },
  nizza: {
    subscriptions: "wss://nizza.refractionx.com/subscriptions",
    graphql: "https://nizza.refractionx.com/graphql",
    web: "https://nizza.refractionx.com",
    imageCDN: 'https://nizza.refractionx.com/cdn',
    coffeeCDN: 'https://nizza.refractionx.com/coffeePrint'
  },
  local: {
    subscriptions: "ws://localhost:8080/subscriptions",
    graphql: "http://localhost:8080/graphql",
    web: "http://localhost:19006",
    imageCDN: 'http://localhost:8080/cdn',
    coffeeCDN: 'http://localhost:8080/coffeePrint'
  },
    ['local-eu-east-2b']: {
    subscriptions: "wss://harlem-api-local-eu-east-2b.refractionx.com/subscriptions",
    graphql: "https://harlem-api-local-eu-east-2b.refractionx.com/graphql",
    web: "https://harlem-local-eu-east-2b.refractionx.com",
    imageCDN: 'https://harlem-api-local-eu-east-2b.refractionx.com/cdn',
    coffeeCDN: 'https://harlem-api-local-eu-east-2b.refractionx.com/coffeePrint'
  },
  next: {
    subscriptions: "wss://harlem-next.refractionx.com/subscriptions",
    graphql: "https://harlem-next.refractionx.com/graphql",
    web: 'https://harlem-next.refractionx.com',
    imageCDN: 'https://harlem-next.refractionx.com/cdn',
    coffeeCDN: 'https://harlem-next.refractionx.com/coffeePrint'
    //web: "exp://harlem-local.refractionx.com:19000/--",
  },
  next2: {
    subscriptions: "wss://harlem-api-local2.refractionx.com/subscriptions",
    graphql: "https://harlem-api-local2.refractionx.com/graphql",
    web: 'https://harlem-local2.refractionx.com:19006',
    imageCDN: 'https://harlem-api-local2.refractionx.com:8082',
    coffeeCDN: 'https://harlem-api-local2.refractionx.com/coffeePrint'
    //web: "exp://harlem-local.refractionx.com:19000/--",
  },
  nextSingle: {
    subscriptions: "wss://harlem-api-local.refractionx.com/subscriptions",
    graphql: "https://harlem-api-local.refractionx.com/graphql",
    web: 'https://harlem-local.refractionx.com',
    imageCDN: 'https://harlem-api-local.refractionx.com:8082',
    coffeeCDN: 'https://harlem-api-local.refractionx.com/coffeePrint'
    //web: "exp://harlem-local.refractionx.com:19000/--",
  },
};

let stack = ENVIRONMENTS[process.env.EXPO_PUBLIC_STACK];

export function getStack() {
  return stack;
}

export function setStack(stackName) {
  stack = ENVIRONMENTS[stackName];
  return stack;
}

export default getStack;
