
import Svg, { Circle, G, Rect, Text } from "react-native-svg";
import {Platform, useWindowDimensions} from 'react-native';
import { processFontFamily } from "expo-font";

export default function ComputerPicker({computers, type, operatorId, onClick, onClose, excludeComputerId}) {

    
    const window = useWindowDimensions();

    const svgCloseProps = {};
    const touchClosePropName = Platform.OS === 'web' ? 'onClick' : 'onPress';
    svgCloseProps[touchClosePropName] = () => {
      if (onClose) {
        onClose();
      }
      return true;
    };

    return (
      <Svg
      height={`${window.height}`}
      width={`${window.width}`}
    >
      <Rect x="0" width={window.width/2} y="0" height={window.height/2} fill="rgb(60, 60, 60)" {...svgCloseProps}/>
      <Rect x={window.width/2} width={window.width/2} y="0" height={window.height/2} fill="rgb(80, 80, 80)" {...svgCloseProps} />
      <Rect x="0" width={window.width} y={window.height/2} height={window.height/2} fill="rgb(70, 70, 70)" {...svgCloseProps} />
      {computers.filter(c => c.type === type).map((computer) => {

        if (computer.id === excludeComputerId) {
          return;
        }
        let strokeColor = "black";
        let fillColor = "#a9a9a9";

        if (computer.timestampStart) {
          strokeColor = "white";
          fillColor = "blue";

          if (computer.orders.filter((o) => o.status === "new").length > 0) {
            fillColor = "#8b0000";
          }
        }

        if (computer.timestampEnd) {
           fillColor = "#8b0000";
        }

        if (computer.orders.filter((o) => o.status === "new").length > 0) {
          strokeColor = "red";
        }

        const svgProps = {};
        const touchPropName = Platform.OS === 'web' ? 'onClick' : 'onPress';
        svgProps[touchPropName] = () => {
          onClick(computer);
          return true;
        };

        return (
          <G key={computer.id}>
            <Circle
              cx={computer.x * window.width}
              cy={computer.y * window.height}
              r="30"
              stroke={strokeColor}
              strokeWidth="4.0"
              fill={fillColor}
              {...svgProps}
            />
            <Text
              x={computer.number > 9 ? computer.x * window.width - 16 : computer.x * window.width - 8}
              y={computer.y * window.height + 10}
              fill="white"
              stroke="black"
              strokeWidth="2"
              fontSize="2em"
              fontFamily={processFontFamily("Pricedown-Bl")}
              {...svgProps}
            >
              {computer.name}
            </Text>
          </G>
        );
      })}
    </Svg>)
}