import * as Linking from "expo-linking";

const linking = {
  prefixes: [
    Linking.createURL("/"),
    "harlem://",
    "exp://harlem-local.refractionx.com:19000/--/",
  ],
  config: {
    screens: {
      Root: {
        path: "",
        screens: {
          MenuRoot: {
            path: "menu",
            screens: {
              Menu: {
                path: "",
              },
              PublicTable: {
                path: "tables/:tableId/token/:tableToken",
              },
              PublicComputer: {
                path: "computers/:computerId/token/:computerToken",
              },
            },
          },
          VR: {
            path: "vr",
          },
          Esports: {
            path: "esports",
          },
          More: {
            path: "more",
          }
        },
      },
      PublicInvite: {
        path: "invite/:token"
      },
      OrdersRoot: {
        path: "orders",
        screens: {
          Orders: "orders",
        },
      },
      DeliveriesRoot: {
        path: "deliveries",
        screens: {
          Deliveries: "deliveries",
        },
      },
      OperatorRoot: {
        path: "tables",
        screens: {
          TablesRoot: {
            path: "",
            screens: {
              Tables: {
                path: "",
              },
              TableDetails: {
                path: ":tableId",
              },
            },
          },

          GamingRoot: {
            path: "operator-gaming",
            screens: {
              Gaming: {
                path: "",
              },
              GamingDetails: {
                path: ":computerId",
              },
            },
          },

          VRRoot: {
            path: "operator-vr",
            screens: {
              VRGaming: {
                path: "",
              },
              VRGamingDetails: {
                path: ":computerId",
              },
            },
          },


          OperatorBenefits: {
            path: "operator-benefits",
          },

          OperatorSettingsRoot: {
            path: "operator-settings",
            screens: {
              OperatorSettings: {
                path: '',
              },
              OperatorSettingsLightsAndColors: {
                path: 'lights&colors'
              },
              OperatorSettingsProfile: {
                path: 'profile'
              },
              OperatorSettingsSummary: {
                path: 'summary'
              },
              OperatorSettingsReport: {
                path: 'report'
              },
              OperatorSettingsInvite: {
                path: 'invite'
              },
            }
          },

          Orders: {
            path: "orders",
            screens: {
              Orders: "orders",
            },
          },
          DeliveriesRoot: {
            path: "deliveries",
            screens: {
              Deliveries: "",
              DeliveryDetails: {
                path: ":deliveryId",
              },
            },
          },
        },
      },
      QRCodeModal: "qr/tables/:tableId/token/:tableToken",
      QRCodeModalPC: "qr/computers/:computerId/token/:computerToken",
      NotFound: "*",
    },
  },
};

export default linking;
