import {
  StyleSheet,
  TouchableHighlight,
  ScrollView,
  ImageBackground,
} from "react-native";

import { View, Text } from "./Themed";

import OutlineText from './OutlineText';
import i18n from "../utils/localization";
import { getProductImageUrl } from "../utils/urls";

export default function ProductItem({
  id,
  index,
  title,
  subtitle,
  imageUrls,
  selectedOptions,
  prices,
  variants,
    volumes,
  variant,
  comment,
  options,
  showAdd,
  showRemove,
  onAdd,
  onRemove,
  onChooseVariant = () => {},
  style = {},
  imageStyle = {},
  itemStyle = {},
  tempId,
  chooseVariantValue,
  chooseVariant,
    displayMode,
    setDisplayMode,
  colorScheme,
}) {

  const choosenVariant = variant ? variant : (chooseVariantValue ? chooseVariantValue : 0);

  let optionTitles= [];
    let optionKeys = {};
  const optionsCost = selectedOptions ? selectedOptions.reduce((acc, v, i) => {
    if (i % 2 == 0) {
      const poption = options.filter(o => o.id === v)[0];
      const pchoice = poption.options.filter(o => o.id === selectedOptions[i+1])[0]
      //optionTitles.push({name: poption.title, value: pchoice.title})
	optionKeys[poption.title] = optionKeys[poption.title] || [];
	optionKeys[poption.title].push(pchoice.title);
	
      return acc + pchoice.prices[choosenVariant];
    } else {
      return acc + 0.0;
    }
  }, 0.0) : 0.0;

    Object.keys(optionKeys).forEach(k => optionTitles.push({name: k, value: optionKeys[k]}))
    
  return (
    <View style={[styles.item, style, {minHeight: 159, maxHeight: 159, borderWidth: 1, margin: 0}]}>
      <ImageBackground
        style={[
          {
            flex: 1,
            justifyContent: "center",
            resizeMode: "contain",
            paddingTop: 0,
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 0,
            flexDirection: 'column',
            backgroundColor: 'transparent',
          },
          imageStyle
        ]}
        source={{
          uri: getProductImageUrl(imageUrls[0]),
        }}
      >
        <View style={[{ flexDirection: "row", backgroundColor: "transparent" }, itemStyle]}>
          <View style={[{ flex: 4, flexDirection: "row", backgroundColor: "transparent" }]}>
            <OutlineText style={[
                styles.titleB,
                {
                  textShadowColor: colorScheme === "dark" ? "black" : "white",
                  textShadowOffset: { width: 2, height: 2 },
                  textShadowRadius: 2,
                },
                { minWidth: showAdd ? 90 : 130 },
              ]} colorScheme={colorScheme} text={i18n.t(title) + ' ' + (variant > -1  && variants[variant] ? ` - ${variants[variant]}` : '') + ' ' + (comment ? ' ' + comment : '')} />
            
          </View>

          <View style={[{ flex: 1, flexDirection: 'row', backgroundColor: "transparent" }]}>
            <OutlineText style={[showAdd ? styles.priceSmallB : styles.priceB]} text={prices[choosenVariant] ? `${(prices[choosenVariant] + optionsCost).toFixed(2)} ${i18n.t('лв')}` : null} />
          </View>
        </View>
        { optionTitles.length > 0 && <View style={[{ flexDirection: "row", backgroundColor: "transparent" }, itemStyle]}>
          <ScrollView horizontal contentContainerStyle={[{ flex: 2, flexDirection: "row", backgroundColor: "transparent" }]}>
            <OutlineText
              style={[
                styles.subtitleB,
                { minWidth: showAdd ? 90 : 130 },
              ]}
          text={optionTitles.map(opt => `${i18n.t(opt.name)}: ${opt.value.map(v => i18n.t(v)).join(', ')}`).join('\n')}
	  
            />
          </ScrollView>

        </View>
        }
          <View style={[{ flexDirection: "row", backgroundColor: "transparent" }, itemStyle, {paddingTop: 0}]}>
          <View style={[{ flex: 4, flexDirection: "row", backgroundColor: "transparent" }]}>
            <ScrollView horizontal
            showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <OutlineText
              style={[
                styles.subtitleB,
                { minWidth: showAdd ? 90 : 130 },
              ]}
              text={i18n.t(subtitle)}
            />
            </ScrollView>
          </View>

        </View>
        <ScrollView horizontal={true} contentContainerStyle={{alignItems: 'flex-end'}} style={{alignSelf: 'flex-start', flexShrink: 1, backgroundColor: 'transparent'}}>
          {typeof variant === "undefined" &&
           variants.map((v, i) => {
	       const defaultText = i18n.t(v)
	       const volumeText = volumes ? i18n.t(volumes[i]) : defaultText; 
	       let text = displayMode && displayMode === 'volume' ? volumeText : defaultText;
              return v && (
                <View key={v} style={styles.buttonsContainer}>
                  <TouchableHighlight
                    onPress={() => {
			if (choosenVariant === i && text === defaultText && volumes && volumes[i]) {
			    setDisplayMode(index, 'volume')
			} else if (choosenVariant == i && text !== defaultText) {
			    setDisplayMode(index, null)
			} else {
			    chooseVariant(index, i);
			    onChooseVariant({ id, variant: i });
			}
                    }}
                  >
                    <Text
                      style={{
                        padding: 5,
                        backgroundColor:
                          choosenVariant === i ? "white" : "black",
                        color: choosenVariant === i ? "black" : "white",
                        borderColor: choosenVariant === i ? "black" : "white",
                        borderWidth: 2,
                      }}
                    >
                      {text}
                    </Text>
                  </TouchableHighlight>
                </View>
              );
            })}

          {showAdd && (
            <View style={styles.buttonsContainer}>
              <TouchableHighlight
                onPress={() =>
                  onAdd({
                    id,
                    variant: choosenVariant,
                  })
                }
              >
                <Text
                  style={{
                    padding: 5,
                    marginTop: 2,
                    backgroundColor: "blue",
                    color: "white",
                    fontStyle: "bold",
                  }}
                >
                  Add
                </Text>
              </TouchableHighlight>
            </View>
          )}

          {showRemove && (
            <View style={styles.buttonsContainer}>
              <TouchableHighlight
                onPress={() =>
                  onRemove({
                    id,
                    variant: choosenVariant,
                    tempId,
                  })
                }
              >
                <Text
                  style={{
                    padding: 15,
                    backgroundColor: "red",
                    color: "white",
                    fontStyle: "bold",
                  }}
                >
                  Remove
                </Text>
              </TouchableHighlight>
            </View>
          )}
        </ScrollView>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsContainer: {
    backgroundColor: "transparent",
    paddingLeft: 0,
    paddingRight: 2,
    paddingBottom: 2,
    paddingTop: 5,
  },

  item: {
    flex: 1,
    flexDirection: "column",
  },
  price: {
    position: 'absolute',
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "right",
    padding: 5,
  },
  priceSmall: {
    position: 'absolute',
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right",
    padding: 5,
  },
  priceB: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "right",
  },
  priceSmallB: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right",
    padding: 5,
  },
  titleB: {
    fontSize: 20,
    fontWeight: "normal",
    textAlign: "left",
  },
  subtitleB: {
    fontSize: 16,
    fontWeight: "normal",
    textAlign: "left",
  },
  title: {
    position: "absolute",
    fontSize: 20,
    fontWeight: "normal",
    textAlign: "left",
  },
  logo: {
    width: 66,
    height: 58,
    marginLeft: 5,
    marginTop: 5,
    borderRadius: 5,
    borderWidth: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  lightThemeBorder: {
    borderColor: "black",
  },
  darkThemeBorder: {
    borderColor: "white",
  },
});
