import React, {useState} from 'react'
import { View, Text} from 'react-native'
import {Picker} from '@react-native-picker/picker';

export default function TimeSelect(props) {
    const [selected, setSelected] = useState('')
    return (<View style={{padding: 15}}>
        <Text>{props.title}</Text>
      <Picker
          selectedValue={selected}
          onValueChange={(itemValue, itemIndex) => {
            setSelected(itemValue)
            if (props.onValueChange) {
                props.onValueChange(itemValue)
            }
          }}>
          <Picker.Item label="Unlimited" value="" />
          <Picker.Item label="1 min" value="1" />
          <Picker.Item label="2 min" value="2" />
          <Picker.Item label="5 min" value="5" />
          <Picker.Item label="10 min" value="10" />
          <Picker.Item label="15 min" value="15" />
          <Picker.Item label="20 min" value="20" />
          <Picker.Item label="30 min" value="30" />
          <Picker.Item label="40 min" value="40" />
          <Picker.Item label="45 min" value="45" />
          <Picker.Item label="50 min" value="50" />
          <Picker.Item label="60 min" value="60" />
          <Picker.Item label="1h 30 min" value="90" />
          <Picker.Item label="2h" value="120" />
          <Picker.Item label="2h 30 min" value="150" />
          <Picker.Item label="3h" value="180" />
          <Picker.Item label="3h 30 min" value="210" />
          <Picker.Item label="4h" value="240" />
          <Picker.Item label="4h 30 min" value="270" />
          <Picker.Item label="5h" value="300" />
          <Picker.Item label="6h" value="360" />
          <Picker.Item label="7h" value="420" />
          <Picker.Item label="8h" value="480" />
        </Picker>
        </View>)
}