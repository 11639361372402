import { AntDesign } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import { useColorScheme } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { useQuery, gql, useMutation, useSubscription } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { TouchableHighlight, Text } from "../components/Themed";
import ComputerOrderView from "../components/ComputerOrderView";

const COMPUTER_DETAILS = gql`
  query getComputerDetails($computerId: ID!, $token: String!) {
    products(token: $token) {
      id
      title
      imageUrls
      capabilities
      prices
      category
      variants
    }
    categories(token: "token") {
      id
      name
      order
    }
    computers(token: $token) {
      id
      operator
      available
      published
      capacity
      number
      name
      rate
      timestamp
      timestampStart
      timestampEnd
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        capabilities
        computerId
        timestamp
        status
      }
    }
    computer(token: $token, id: $computerId) {
      id
      operator
      available
      published
      capacity
      number
      name
      rate
      timestamp
      timestampStart
      timestampEnd
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        capabilities
        computerId
        timestamp
        status
      }
    }
  }
`;

const GENERATE_COMPUTER_TOKEN = gql`
  mutation generateComputerToken($token: String!, $operator: ID!, $computer: ID!) {
    generateComputerToken(token: $token, operator: $operator, computer: $computer) {
      id
      operator
      available
      published
      capacity
      number
      rate
      timestamp
      timestampStart
      timestampEnd
      name
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        computerId
        timestamp
        status
      }
    }
  }
`;

const COMPUTERS_SUBSCRIPTION = gql`
  subscription {
    computers {
      id
      operator
      available
      published
      capacity
      rate
      timestamp
      timestampStart
      timestampEnd
      number
      token
      type
      name
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        computerId
        timestamp
        status
      }
    }
  }
`;

export default function OperatorGamingDetailsScreen(props) {
  const linkTo = useLinkTo();
  const computerId = props.route.params && props.route.params.computerId;
  const computerToken = props.route.params && props.route.params.computerToken;
  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(COMPUTER_DETAILS, {
    variables: { computerId, token: token || computerToken || '' },
    nextFetchPolicy: 'cache-only',
  });

  

  const [
    generateComputerToken,
    {
      generateComputerTokenData,
      generateComputerTokenLoading,
      generateComputerTokenError,
    },
  ] = useMutation(GENERATE_COMPUTER_TOKEN);

  const { computersSubscriptionData, computersSubscriptionLoading } =
    useSubscription(COMPUTERS_SUBSCRIPTION);
  
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  return <ComputerOrderView {...props} categories={data.categories} products={data.products} computer={data.computer} computers={data.computers} />;
}
