import React from "react";
import { StatusBar } from "expo-status-bar";
import {
  Platform,
  StyleSheet,
  useColorScheme,
  TouchableOpacity,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { Text, View, TouchableHighlight } from "../components/Themed";
import { useLinkTo } from "@react-navigation/native";
import { useQuery, gql, useSubscription } from "@apollo/client";
import TableOrderView from "../components/TableOrderView";
import * as Themed from "../components/Themed";

const ThemedText = Themed.Text;

const TABLE_DETAILS = gql`
  query getTableDetails($tableId: ID!, $token: String!) {
    products(token: $token) {
      id
      title
      imageUrls
      prices
      capabilities
      category
      variants
    }
    tables(token: $token) {
      id
      operator
      available
      published
      capacity
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        capabilities
        quantities
        tableId
        timestamp
        status
      }
    }
    categories(token: "token") {
      id
      name
      order
    }
    table(token: $token, id: $tableId) {
      id
      operator
      available
      published
      capacity
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        capabilities
        variants
        tableId
        timestamp
        status
      }
    }
  }
`;

const TABLES_SUBSCRIPTION = gql`
  subscription {
    tables {
      id
      operator
      available
      published
      capacity
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        tableId
        variants
        timestamp
        status
      }
    }
  }
`;

export default function TableDetailsScreen(props) {
  const linkTo = useLinkTo();
  const tableId = props.route.params && props.route.params.tableId;
  const token = props.route.params && props.route.params.tableToken;
  const colorScheme = useColorScheme;

  const { loading, error, data } = useQuery(TABLE_DETAILS, {
    variables: { tableId, token: token },
    nextFetchPolicy: 'cache-only',
  });

  const ret =  useSubscription(TABLES_SUBSCRIPTION);
  
  if (loading) return <ThemedText>Loading...</ThemedText>;
  
  if (!data || (data && !data.table.token))
    return (
      <View style={styles.container}>
        <Text style={styles.title}>wasted</Text>
        <TouchableOpacity
          onPress={() => props.navigation.replace("Menu")}
          style={styles.link}
        >
          <Text style={styles.linkText}>Please, ask staff for help! | Click for main menu</Text>
        </TouchableOpacity>
      </View>
    );

  let table = data.table;
  if (ret.data) {
    table = ret.data.tables.filter(t => t.id === tableId)[0];
  }


  return <TableOrderView {...props} products={data.products} table={table} categories={data.categories}/>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Pricedown-Bl",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    fontFamily: "Pricedown-Bl",
    color: "#2e78b7",
  },
});
