import { StyleSheet, FlatList, useWindowDimensions } from "react-native";
import Image from 'react-native-scalable-image';
import { Text, View } from "../components/Themed";

import { getMarkeringUrl } from '../utils/urls';

export default function VRScreen() {
  const window = useWindowDimensions();
  const numColumns = 1;
  return (
    <View style={styles.container}>
      <FlatList
        data={[
          getMarkeringUrl('beatsaber.gif'),
          getMarkeringUrl('superhot.gif'),
          getMarkeringUrl('racing-vr.gif'),
          getMarkeringUrl('boxing.gif'),
          getMarkeringUrl('alyx.gif'),
          getMarkeringUrl('xplane11.gif'),
          getMarkeringUrl('googleearth.webp'),
          getMarkeringUrl('escaperoom.gif'),
        ]}
        renderItem={({ item }) => (
          <Image width={window.width / numColumns} source={{ uri: item }} />
        )}
        //Setting the number of column
        numColumns={numColumns}
        keyExtractor={(item, index) => index}
      />
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
