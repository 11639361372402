import React, {useEffect, useRef, useState} from 'react';
import {
  StyleSheet,
  FlatList,
  Platform,
  useWindowDimensions,
  TouchableHighlight,
  useColorScheme,
} from "react-native";

import StickyContainer from 'recyclerlistview/dist/reactnative/core/StickyContainer';
import { useQuery, gql } from "@apollo/client";
import i18n from "../utils/localization";
import { SafeAreaView } from "react-native-safe-area-context";
import { Text, View } from "../components/Themed";
import ProductItem from "../components/ProductItem";
import Config from '../constants/Config';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";

const PRODUCTS = gql`
  query {
    categories(token: "token") {
      id
      name
      order
    }
    products(token: "token") {
      id
      category
      title
      subtitle
      imageUrls
      prices
      variants
      volumes
    }
  }
`;

const Jared = { width: 1920, numColumns: 1 };

export default function MenuScreen(params) {
 

  const { loading, error, data, refetch } = useQuery(PRODUCTS);
  const window = useWindowDimensions();
  Jared.width = window.width;
  const productsListRef = useRef(null);
  const colorScheme = useColorScheme();
  const [dataProvider, setDataProvider] = useState(() => { return new DataProvider((r1, r2) => { return r1.id !== r2.id }) });
    const [extendedState, setExtendedState] = useState({chooseVariantValue: {}, displayMode: {}});
  const [numColumns, setNumColumns] = useState(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    return numColumns;
  });

  const [layoutProvider, setLayoutProvider] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 17) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  useEffect(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    setNumColumns(numColumns);
    Jared.width = window.width;
    Jared.numColumns = numColumns;
  }, [window]);

  useEffect(() => {
    if (data && data.products) {
      setDataProvider(dataProvider.cloneWithRows(data.products))
    }
  }, [data]);

  const renderItem = (type, item, index, extendedState) => <ProductItem {...item} index={index} chooseVariantValue={extendedState.chooseVariantValue[index]}
    chooseVariant={(index, variant) => {
      const newState = {
        ...extendedState,
        chooseVariantValue: {
          ...extendedState.chooseVariantValue,
        }
      };
      newState.chooseVariantValue[index] = variant;
      setExtendedState(newState);
    }}
    displayMode={extendedState.displayMode[index]}				
    setDisplayMode={(index, displayMode) => {
      const newState = {
          ...extendedState,
	  displayMode: {
	      ...extendedState.displayMode,
	  }
      };
      newState.displayMode[index] = displayMode;						         setExtendedState(newState);
    }}
    colorScheme={colorScheme}
  />;

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;
  
  

  const products = data.products;
  const categories = data.categories;


  const renderStickyItem = (type, item, index) => (
    <FlatList
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{backgroundColor: colorScheme === 'light' ? 'white' : 'black'}}
                  data={categories}
                  keyExtractor={(item, index) => item.id}
                  renderItem={({ item, index }) => (
                    <TouchableHighlight onPress={() => {
                      if (productsListRef.current) {
                        let firstIndex = 0;
                        try {
                          firstIndex = Math.floor(products.findIndex(p => p.category === item.id) / numColumns);
                          productsListRef.current.scrollToOffset(0, firstIndex * 160, true);
                        } catch (e) {
                          console.log(firstIndex, e);
                        }
                      }
                    }}>
                      <View style={{ padding: 5 }}>
                          <Text style={{ fontSize: 32, fontFamily: Config.theme.logoFontFamily }}>{i18n.t(item.name)}{index === categories.length - 1 ? '' : ' |'}</Text>
                      </View>
                    </TouchableHighlight>
                  )}
                />
  );


  return (
    <SafeAreaView style={{flex: 1}} edges={['left', 'top']}>
      {data.products && data.products.length > 0 ? <StickyContainer
                stickyHeaderIndices={[0]}
                overrideRowRenderer={renderStickyItem}
              >
              <RecyclerListView
                style={{ flex: 1, paddingTop: 55, }}
                ref={(ref) => { productsListRef.current = ref}}
                contentContainerStyle={{ margin: 3 }}
                dataProvider={dataProvider}
                layoutProvider={layoutProvider}
                canChangeSize={true}
                renderAheadOffset={data.products.length}
                rowRenderer={renderItem}
                extendedState={extendedState}
              />
            </StickyContainer>
            : null}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    padding: 15,
  },
  separator: {
    marginBottom: 0,
    height: 1,
    width: "100%",
  },
  logo: {
    width: 66,
    height: 58,
  },
});
