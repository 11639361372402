import { StatusBar } from "expo-status-bar";
import { Platform, StyleSheet } from "react-native";
import { useQuery, useSubscription, gql } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { useLinkTo } from "@react-navigation/native";
import * as Themed from "../components/Themed";
import TablePicker from "../components/TablePicker";

const View = Themed.View;
const ThemedText = Themed.Text;

const TABLES = gql`
  query getTables($token: String!) {
    tables(token: $token) {
      id
      operator
      available
      published
      finalized
      location
      capacity
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        tableId
        options
        timestamp
        status
      }
    }
  }
`;

const TABLES_SUBSCRIPTION = gql`
  subscription {
    tables {
      id
      operator
      available
      published
      finalized
      capacity
      location
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        tableId
        options
        timestamp
        status
      }
    }
  }
`;

export default function TablesScreen({ navigation }) {

  const [operatorId, setOperatorId] = useState("");
  const [locationId, setLocationId] = useState("All");
  const [token, setToken] = useState("");

  const linkTo = useLinkTo();

  const { tablesSubscriptionData, tablesSubscriptionLoading } =
    useSubscription(TABLES_SUBSCRIPTION);

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const locationId = await AsyncStorage.getItem("location");
        if (locationId) {
          setLocationId(locationId);
        }
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(TABLES, {
    variables: { token },
  });

  if (loading) return <ThemedText>Loading...</ThemedText>;
  if (error) return <ThemedText>Error :(</ThemedText>;
  console.log(locationId)
  return (
    <View style={styles.container}>
      <TablePicker tables={locationId === 'All' ? data.tables : data.tables.filter(t => t.location === locationId)} operatorId={operatorId} onClick={(table) => {
        linkTo(`/tables/${table.id}`);
      }} />
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
