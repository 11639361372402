import * as Localization from "expo-localization";
import AsyncStorage from "@react-native-async-storage/async-storage";

const translations = {
  en: {
    "Поръчай тук": "Order here",
    "Кафе Верняно": "Vergnano Coffee",
    "Кафе Верняно - промо 10:00": "Vergnano Coffee - promo 10:00",
    Фреш: "Fresh juice",
    "Кафе Верняно - 100% Арабика": "Vergnano Coffee - 100% Arabica",
    "Кафе Верняно - Безкофеиново": "Vergnano Coffee - Decaf",
    Банкя: "Mineral water",
    "Кока-Кола": "Coca-Cola",
    Капи: "Cappy juice",
    Швепс: "Schweppes",
    Спрайт: "Sprite",
    "Студен чай": "Iced Tea",
    Чай: "Tea",
    Сода: "Soda",
    "3в1": "Nescafe 3in1",
    Лате: "Latte",
    Лимонада: "Lemonade",
    Фрапе: "Frappe",
    Нескафе: "Nescafé",
    "Капучино Верняно": "Cappuccino Vergnano",
    "Мляко с какао Верняно": "Vergnano Chocolate milk",
    "Топъл Шоколад Верняно": "Vergnano Hot Chocolate",
    "Външна батерия": "Powerbank",
    Цитронада: "Citronade",
    Самърсби: "Sommersby Cider",
    "Банкя - стъклена бутилка": "Mineral water - glass bottle",
    Корона: "Corona",
    "Jack Daniel's Honey Lemonade": "Jack Daniel's Honey Lemonade",
    "Bickens Pink & Pink Tonic Style": "Bickens Pink & Pink Tonic Style",
    "Bacardi Mojito": "Bacardi Mojito",
    Шот: "Shot",
    Шейкър: "Shaker",
    Фанта: "Fanta",
    Загорка: "Zagorka beer",
    "Хайникен наливна": "Heineken draft",
    Шприц: "Spriz",
    Аперол: "Aperol",
    Мартини: "Martini",
    Бианко: "Bianco",
    Кампари: "Campari",
    Бакарди: "Bacardi",
    Ликьор: "Liqueur",
    Дисароно: "Disaronno",
    Бушмилс: "Bushmills",
    Джони: "Johnnie",
    Джеймсън: "Jameson",
    "J&B": "J&B",
    "Джак Даниелс": "Jack Daniels",
    "Сингъл Барел": "Single Barrel",
    Житная: "Zytnia",
    Абсолют: "Absolut",
    "Руски Стандарт": "Russkii Standart",
    Финландия: "Finlandia",
    Бифитър: "Beefeater",
    Горданс: "Gordon's",
    "Горданс Пинк": "Gordon's Pink",
    Джин: "Gin",
    Бомбай: "Bombay",
    12: "12",
    7: "7",
    Пломари: "Plomari",
    Тсантали: "Tsantali",
    "Чивас Регал": "Chivas Regal",
    Кардю: "Cardhu",
    "Джентълмен Джак": "Gentleman Jack",
    Гленфидиш: "Glenfiddich",
    РедБул: "RedBull",
    "Сайкъл Сов Блан": "Cycle Sauvignon blanc",
    Столично: "Stolichno",
    Хайникен: "Heineken",
    Ариана: "Ariana",
    Старобърно: "Starobrno",
    Ботега: "Bottega",
    Морети: "Moretti",
    "Афтър Шок": "After Shock",
    Савой: "Savoy",
    "Зельоная Марка": "Green Mark",
    Столичная: "Stolichnaya",
    Търговище: "Targovishte",
    "Грей Гус": "Gray Goose",
    Белуга: "Beluga",
    "Бургас 63": "Burgas 63",
    "Бургаска Мускатова": "Burgas' Muscat grapes",
    "Кайлъшка гроздова": "Kaylashka",
    Карнобатска: "Karnobatska",
    Пещерска: "Peshterska",
    "Плиска Преслав": "Pliska Preslav",
    "Мента Пещера": "Peshtera Mint",
    "Къти Сарк": "Cutty Sark",
    "Джийм Бийм": "Jim Beam",
    "Джак Даниелс": "Jack",
    Грантс: "Grants",
    Метакса: "Metaxa",
    "Сайкъл Траминер": "Cycle Traminer",
    "Сайкъл Мерло и Пино Ноар": "Cycle Merlot & Pinot noir",
    "Сайкъл Розе": "Cycle Rose",
    "Едоардо Миролио Совиньон Блан": "Edoardo Miroglio Sauvignon Blanc",
    "JP Chenet - 250 мл": "JP. Chenet - 250 ml.",
    "Моет Шандон Брут": "Moët & Chandon Impérial Brut",
    Напитки: "Beverages",
    Коктейли: "Cocktails",
    "Друг алкохол": "Other alcohol",

    Кафетерия: "Coffee shop",
    Топли: "Hot",
    Фрешове: "Juices",
    късо: "Espresso",
    нормално: "Normal",
    дълго: "Long",
    лв: "BGN",
    "25 мл": "25 ml.",
    "300 мл": "300 ml.",
    "50 мл": "50 ml.",
    "200 мл": "200 ml.",
    "250 мл": "250 ml.",
    "330 мл": "330 ml.",
    "350 мл": "350 ml.",
    "355 мл": "355 ml.",
    "375 мл": "375 ml.",
    "250 мл": "250 ml.",
    "500 мл": "500 ml.",
    "750 мл": "750 ml.",
    Безалкохолни: "Beverages",
    Бири: "Beers",
    Водка: "Vodka",
    Уиски: "Whisky",
    "без захар": "diet",
    "с лимон": "with Lemon",
    портокал: "orange",
    праскова: "peach",
    кайсия: "apricot",
    ананас: "pineapple",
    мандарина: "tangerine",
    тоник: "tonic",
    лимон: "lemon",
    нар: "pomegranate",
    зелен: "green",
    "горски плодове": "berries",
    бяло: "white",
    черно: "black",
    "-": "-",
    ягода: "strawberry",
    "лайм-джинджър": "lime-ginger",
    мента: "mint",
    топло: "hot",
    студено: "cold",
    черен: "dark",
    бял: "white",
    "с кокос": "coconut",
    лате: "latte",
    "Micro USB": "Micro USB",
    "USB C": "USB C",
    iPhone: "iPhone",
    Боровинка: "blueberry",
    Ябълка: "apple",
    Круша: "pear",
    Манго: "mango",
    грейпфрут: "grapefruit",
    микс: "mix",
    малко: "single",
    червено: "red label",
    малка: "single",
    "12 годишно": "12 year old",
    боквайс: "bock weiss",
    вайс: "weiss",
    традиционна: "tradizionale",
    розе: "rose",

    "Additional comment:": "Notes:",

    // Nizza
    средна: "средна",
    голяма: "голяма",
    Пица: "Пица",
    Бургери: "Бургери",
    Гарнитури: "Гарнитури",
    Десерти: "Десерти",
    Сосове: "Сосове",
    Порции: "Порции",
    Сандвичи: "Сандвичи",
    Салати: "Салати",
    "From:": "От:",
    "City:": "Град:",
    "Street:": "Улица:",
    "Comment:": "Коментар:",
    Deliveries: "Доставки",
    Delivery: "Доставка",
    "Phone:": "Тел:",
    "Добавкa": "Добавка",
    Тесто: "Тесто",
    "Сусамово семе": "Сусамово семе",
    "Слънчогледово семе": "Слънчогледово семе",
    "Ленено семе": "Ленено семе",
    "Маково семе": "Маково семе",
    Борд: "Борд",
    Моцарела: "Моцарела",
    Чеснов: "Чеснов",
    Чедър: "Чедър",
    Филаделфия: "Филаделфия",
    "": "",
  'Маргарита': 'Маргарита',
  'Доматен сос и моцарела': 'Доматен сос и моцарела',
  'малка - 26 см': 'малка - 26 см',
  'Пресни домати': 'Пресни домати',
  'Чери домати': 'Чери домати',
  'Кисели краставички': 'Кисели краставички',
  'Сушени домати': 'Сушени домати',
  'Карамелизиран Лук': 'Карамелизиран Лук',
  'Халапеньо': 'Халапеньо',
  'Авокадо': 'Авокадо',
  'Свинска шунка': 'Свинска шунка',
  'Бекон': 'Бекон',
  'Пилешка шунка': 'Пилешка шунка',
  'Луканков салам': 'Луканков салам',
  'Прошуто': 'Прошуто',
  'Пеперони': 'Пеперони',
  'Кюфте': 'Кюфте',
  'Свинско Конци': 'Свинско Конци',
  'Пилешко филе': 'Пилешко филе',
  'Доматен сос': 'Доматен сос',
  'Сметана': 'Сметана',
  'Моцарела': 'Моцарела',
  'Синьо сирене': 'Синьо сирене',
  'Пармезан': 'Пармезан',
  'Бяло сирене': 'Бяло сирене',
  'Пушено сирене': 'Пушено сирене',
  'Яйце': 'Яйце',
  'Рукола': 'Рукола',
  'Айсберг': 'Айсберг',
  'Зелена чушка': 'Зелена чушка',
  'Гъби': 'Гъби',
  'Царевица': 'Царевица',
  'Червен лук': 'Червен лук',
  'Маслини': 'Маслини',
  'Люта чушка': 'Люта чушка',
  'Куатро Формаджи': 'Куатро Формаджи',
  'Доматен сос, моцарела, синьо сирене, чедър и топено сирене': 'Доматен сос, моцарела, синьо сирене, чедър и топено сирене',
  'Куатро Ди Карне': 'Куатро Ди Карне',
  'Доматен сос, моцарела, шунка, пилешко филе, бекон и луканов салам': 'Доматен сос, моцарела, шунка, пилешко филе, бекон и луканов салам',
  'Капричоза': 'Капричоза',
  'Доматен сос, моцарела, шунка, гъби и чушки': 'Доматен сос, моцарела, шунка, гъби и чушки',
  'Поло': 'Поло',
  'Доматен сос, моцарела, пилешко филе, гъби и чушки': 'Доматен сос, моцарела, пилешко филе, гъби и чушки',
  'Карбонара': 'Карбонара',
  'Сметанов сос, моцарела, бекон, гъби и пармезан': 'Сметанов сос, моцарела, бекон, гъби и пармезан',
  'Ница': 'Ница',
  'Доматен сос, моцарела, шунка, бекон, гъби и сметана': 'Доматен сос, моцарела, шунка, бекон, гъби и сметана',
  'Диаволо': 'Диаволо',
  'Доматен сос, моцарела, шунка, пеперони, люта чушка и чери домати': 'Доматен сос, моцарела, шунка, пеперони, люта чушка и чери домати',
  'Американо BBQ': 'Американо BBQ',
  'Доматен сос, BBQ сос, горчица, моцарела, пилешко филе, пеперони, чушки и маслини': 'Доматен сос, BBQ сос, горчица, моцарела, пилешко филе, пеперони, чушки и маслини',
  'Алфредо': 'Алфредо',
  'Сметанов сос, моцарела, пилешко филе, царевица и топено сирене': 'Сметанов сос, моцарела, пилешко филе, царевица и топено сирене',
  'Доматен сос, моцарела и пеперони': 'Доматен сос, моцарела и пеперони',
  'Мексикана': 'Мексикана',
  'Доматен сос, моцарела, пеперони, червен лук, маслини, царевица, люта чушка': 'Доматен сос, моцарела, пеперони, червен лук, маслини, царевица, люта чушка',
  'Италия': 'Италия',
  'Доматен сос, моцарела, сушени домати, пармезан и песто': 'Доматен сос, моцарела, сушени домати, пармезан и песто',
  'Салами': 'Салами',
  'Доматен сос, моцарела, луканков салам, чушка и маслини': 'Доматен сос, моцарела, луканков салам, чушка и маслини',
  'Вегетариана': 'Вегетариана',
  'Доматен сос, моцарела, гъби, царевица, чушка, червен лук и маслини': 'Доматен сос, моцарела, гъби, царевица, чушка, червен лук и маслини',
  'Прошуто фунги': 'Прошуто фунги',
  'Доматен сос, моцарела, прошуто крудо, гъби, рукола и пармезан': 'Доматен сос, моцарела, прошуто крудо, гъби, рукола и пармезан',
  'Бургер Пица': 'Бургер Пица',
  'Доматен сос, моцарела, телешко, халапеньо, чедър и гъби': 'Доматен сос, моцарела, телешко, халапеньо, чедър и гъби',
  'Сицилиана': 'Сицилиана',
  'Доматен сос, моцарела, пилешко филе, сушени домати, топени сирене и песто': 'Доматен сос, моцарела, пилешко филе, сушени домати, топени сирене и песто',
  'Калцоне': 'Калцоне',
  'Доматен сос, моцарела, шунка, гъби, кисели краствички': 'Доматен сос, моцарела, шунка, гъби, кисели краствички',
  'средна - 30 см': 'средна - 30 см',
  'голяма - 50 см': 'голяма - 50 см',
  'Ница Бургер': 'Ница Бургер',
  'Питка бриош, айсберг, домат, телешко, чедър, яйце, бекон, карамелизиран лук и ница сос': 'Питка бриош, айсберг, домат, телешко, чедър, яйце, бекон, карамелизиран лук и ница сос',
  'Класик Бургер': 'Класик Бургер',
  'Питка бриош, айсберг, кисели краставички, телешко, ница сос': 'Питка бриош, айсберг, кисели краставички, телешко, ница сос',
  'Чийз Бургер': 'Чийз Бургер',
  'Питка бриош, айсберг, кисели краставици, домат, телешко, чедър, червен лук и ница сос': 'Питка бриош, айсберг, кисели краставици, домат, телешко, чедър, червен лук и ница сос',
  'Пилешки Бургер': 'Пилешки Бургер',
  'Питка бриош, рукола, сушени домати, гриловано пилешко, червен лук, ементал и домашна майонеза': 'Питка бриош, рукола, сушени домати, гриловано пилешко, червен лук, ементал и домашна майонеза',
  'Криспи Чикън Бургер': 'Криспи Чикън Бургер',
  'Питка бриош, айсберг, кисели краставици, хрупкаво, панирано пилешко филе, бекон и японска майонеза': 'Питка бриош, айсберг, кисели краставици, хрупкаво, панирано пилешко филе, бекон и японска майонеза',
  'Смоуки Бургер': 'Смоуки Бургер',
  'Питка бриош, айсберг, телешко, бекон, лучени кръгчета, моцарела и ница сос': 'Питка бриош, айсберг, телешко, бекон, лучени кръгчета, моцарела и ница сос',
  'Пулд Порк Бургер': 'Пулд Порк Бургер',
  'Питка бриош, айсберг, кисели краставици, бавно готвено свинско месо, чедър, карамелизиран лук и барбекю сос': 'Питка бриош, айсберг, кисели краставици, бавно готвено свинско месо, чедър, карамелизиран лук и барбекю сос',
  'Спайси Найси Бургер': 'Спайси Найси Бургер',
  'Питка бриош, телешко, айсберг, чедър, халапеньо, доматено пикантно чътни и ница сос': 'Питка бриош, телешко, айсберг, чедър, халапеньо, доматено пикантно чътни и ница сос',
  'Веджи Бургер': 'Веджи Бургер',
  'Бургер от киноа и халуми, айсберг, чедър, домат, кисели краставички, червен лук': 'Бургер от киноа и халуми, айсберг, чедър, домат, кисели краставички, червен лук',
  'Детски Бургер (МЕНЮ)': 'Детски Бургер (МЕНЮ)',
  'Питка бриош, телешко, чедър, домат, кисели краставички и майонеза + пържени картофки и плодов сок': 'Питка бриош, телешко, чедър, домат, кисели краставички и майонеза + пържени картофки и плодов сок',
  'Лучени кръгчета': 'Лучени кръгчета',
  '200 гр': '200 гр',
  'Пържени картофи': 'Пържени картофи',
  'Хрупкави пилешки филенца': 'Хрупкави пилешки филенца',
  'хрупкави панирани пилешки филенца с чеснов сос': 'хрупкави панирани пилешки филенца с чеснов сос',
  'Порция с хрупкаво пиле': 'Порция с хрупкаво пиле',
  'Панирано филе, басмати ориз, микс салатки': 'Панирано филе, басмати ориз, микс салатки',
  'Порция пилешко филе': 'Порция пилешко филе',
  'Пилешко филе, басмати ориз, микс салатки  ': 'Пилешко филе, басмати ориз, микс салатки  ',
  'Порция телешко': 'Порция телешко',
  '2бр. телешки кюфтенца, басмати ориз, микс салатки': '2бр. телешки кюфтенца, басмати ориз, микс салатки',
  'Порция веджи': 'Порция веджи',
  'Кюфтенце от киноа, басмати ориз, микс салатки': 'Кюфтенце от киноа, басмати ориз, микс салатки',
  'Ницета с пеперони': 'Ницета с пеперони',
  'Пеперони, моцарела и масло': 'Пеперони, моцарела и масло',
  'Ницета с шунка': 'Ницета с шунка',
  'Шунка, моцарела и масло': 'Шунка, моцарела и масло',
  'Ницета с пилешко филе': 'Ницета с пилешко филе',
  'Пилешко филе, моцарела и масло': 'Пилешко филе, моцарела и масло',
  'Ницета с бекон': 'Ницета с бекон',
  'Бекон, моцарела и масло': 'Бекон, моцарела и масло',
  'Тортиля НИЦА': 'Тортиля НИЦА',
  'Мляно свинско – телешко месо,айсберг, домат, кисела краставичка,моцарела,карамелизиран лук,картофки,ница сос': 'Мляно свинско – телешко месо,айсберг, домат, кисела краставичка,моцарела,карамелизиран лук,картофки,ница сос',
  'Тортиля с пилешко': 'Тортиля с пилешко',
  'Пилешко филе,моцарела, рукола, сушени домати, червен лук, картофки, домашна майонеза': 'Пилешко филе,моцарела, рукола, сушени домати, червен лук, картофки, домашна майонеза',
  'Тортиля със свинско': 'Тортиля със свинско',
  'Бавно готвено свинско, айсберг, кисели краставички,айсберг,карамелизиран лук, картофки, моцарела,BBQ сос': 'Бавно готвено свинско, айсберг, кисели краставички,айсберг,карамелизиран лук, картофки, моцарела,BBQ сос',
  'Тортиля с хрупкаво пиле': 'Тортиля с хрупкаво пиле',
  'Хрупкаво панирано пилешко, айсберг, кисели краставички, домат,  моцарела, картофки, чеснов сос': 'Хрупкаво панирано пилешко, айсберг, кисели краставички, домат,  моцарела, картофки, чеснов сос',
  'Джоб Ница': 'Джоб Ница',
  'Телешко, айсберг, домати, кисели краставички ,моцарела, карамелизиран лук, картофи , ница сос': 'Телешко, айсберг, домати, кисели краставички ,моцарела, карамелизиран лук, картофи , ница сос',
  'Джоб Пулд порк': 'Джоб Пулд порк',
  'Бавно готвено свинско, айсберг, кисели краставички, червен лук, чедър, картофи, BBQ сос': 'Бавно готвено свинско, айсберг, кисели краставички, червен лук, чедър, картофи, BBQ сос',
  'Джоб Чийз BBQ бекон': 'Джоб Чийз BBQ бекон',
  'Хрупков бекон, чедър, айсберг, домат, червен лук, картофи, BBQ сос': 'Хрупков бекон, чедър, айсберг, домат, червен лук, картофи, BBQ сос',
  'Джоб Поло': 'Джоб Поло',
  'Пилешка шунка, моцарела, айсберг, домат, картофи, чеснов сос': 'Пилешка шунка, моцарела, айсберг, домат, картофи, чеснов сос',
  'Джоб Вегетериански': 'Джоб Вегетериански',
  'Моцарела, чедър, топено сирене, яйце, картофи, айсберг, домат, ница сос': 'Моцарела, чедър, топено сирене, яйце, картофи, айсберг, домат, ница сос',
  'Джоб Капризен': 'Джоб Капризен',
  'Прошуто кото, айсберг, домат, зелена чушка, моцарела, каротфи, майонеза': 'Прошуто кото, айсберг, домат, зелена чушка, моцарела, каротфи, майонеза',
  'Джоб сандвич Мексикански': 'Джоб сандвич Мексикански',
  'Телешко, домати, айсберг, царевица, халапеньо, червен лук, картофи, чили сос': 'Телешко, домати, айсберг, царевица, халапеньо, червен лук, картофи, чили сос',
  'Салата Цезар': 'Салата Цезар',
  'Айсберг, сос Цезар, пилешки филенца, чери домати, бекон, кротони и пармезан - 350 гр': 'Айсберг, сос Цезар, пилешки филенца, чери домати, бекон, кротони и пармезан - 350 гр',
  'Айсберг с рукола': 'Айсберг с рукола',
  'Айсберг, рукола, чери домати, сос Винегрет, пармезан - 250 гр': 'Айсберг, рукола, чери домати, сос Винегрет, пармезан - 250 гр',
  'Домати с печена чушка': 'Домати с печена чушка',
  'Белени домати, печени чушки, маслина, сирене, зехтин 400гр': 'Белени домати, печени чушки, маслина, сирене, зехтин 400гр',
  'Капрезе': 'Капрезе',
  'Домати, свежа моцарела, песто, рукола и пармезан - 400 гр': 'Домати, свежа моцарела, песто, рукола и пармезан - 400 гр',
  'Салата Кобб': 'Салата Кобб',
  'Айсберг, пилешко филе, сушени домати, авокадо, бекон, синьо сирене и лимонов дресинг - 350 гр': 'Айсберг, пилешко филе, сушени домати, авокадо, бекон, синьо сирене и лимонов дресинг - 350 гр',
  'Салата хрупкаво пиле': 'Салата хрупкаво пиле',
  'Айсбег, домат, царевица, панирано пиле, чеснов сос, пармезан': 'Айсбег, домат, царевица, панирано пиле, чеснов сос, пармезан',
  'Шоколадова кариолка': 'Шоколадова кариолка',
  'Шоколад , масло , ядки - 70 гр. / бр': 'Шоколад , масло , ядки - 70 гр. / бр',
  'Крем Ден и нощ': 'Крем Ден и нощ',
  'масло, сметана, шоколад, пандишпан, ядки - 160 гр. / бр': 'масло, сметана, шоколад, пандишпан, ядки - 160 гр. / бр',
  'Кока кола – 330ml': 'Кока кола – 330ml',
  '': '',
  'Фанта Портокал – 330ml': 'Фанта Портокал – 330ml',
  'Спрайт – 330ml': 'Спрайт – 330ml',
  'Кока кола – 500мл': 'Кока кола – 500мл',
  'Фанта Портокал – 500мл': 'Фанта Портокал – 500мл',
  'Спрайт – 500мл': 'Спрайт – 500мл',
  'Фанта Лимон – 500мл': 'Фанта Лимон – 500мл',
  'Кока кола – 2L': 'Кока кола – 2L',
  'Фанта Портокал – 2L': 'Фанта Портокал – 2L',
  'Нестий праскова – 500ml': 'Нестий праскова – 500ml',
  'Нестий лимон – 500ml': 'Нестий лимон – 500ml',
  'Нестий горски плод – 500ml': 'Нестий горски плод – 500ml',
  'Нестий манго и ананас – 500ml': 'Нестий манго и ананас – 500ml',
  'Минерална вода – 500ml': 'Минерална вода – 500ml',
  'Минерална вода – 1,5L': 'Минерална вода – 1,5L',
  'Айран – 330ml': 'Айран – 330ml',
  'Загорка кен – 500ml': 'Загорка кен – 500ml',
  'Хайникен кен – 500ml': 'Хайникен кен – 500ml',
  'Хайнекен 330': 'Хайнекен 330',
  'Кетчуп': 'Кетчуп',
  '50 гр': '50 гр',
  'Майонеза': 'Майонеза',
  'Горчица': 'Горчица',
  'Чили сос': 'Чили сос',
  'Чеснов сос': 'Чеснов сос',
  'Барбекю': 'Барбекю',
  'Сладко чили': 'Сладко чили',
  'Цезар': 'Цезар',
  'Песто': 'Песто',
  'Ница сос': 'Ница сос'

      },
  it: {
    "Поръчай тук": "Ordina qui",
    "Кафе Верняно": "Caffè Vergnano",
    "Кафе Верняно - промо 10:00": "Caffè Vergnano - promo 10:00",
    Фреш: "Succo fresco",
    "Кафе Верняно - 100% Арабика": "Caffè Vergnano - 100% Arabica",
    "Кафе Верняно - Безкофеиново": "Caffè Vergnano - Decaffeinato",
    Банкя: "Aqua Minerale Naturale",
    "Кока-Кола": "Coca-Cola",
    Капи: "succo Cappy",
    Швепс: "Schweppes",
    Спрайт: "Sprite",
    "Студен чай": "Tè Freddo",
    Чай: "Tè",
    Сода: "Acqua Minerale Frizzante",
    "3в1": "Nescafe 3in1",
    Лате: "Latte",
    Лимонада: "Limonata",
    Фрапе: "Frappé",
    Нескафе: "Nescafé",
    "Капучино Верняно": "Cappuccino Vergnano",
    "Мляко с какао Верняно": "Vergnano Latte al cioccolato",
    "Топъл Шоколад Верняно": "Vergnano Cioccolata Calda",
    "Външна батерия": "Caricabatterie",
    Цитронада: "Citronata",
    Самърсби: "Sidro Sommersby",
    "Банкя - стъклена бутилка": "Aqua Minerale Naturale - bottiglia di vetro",
    Корона: "Corona",
    "Jack Daniel's Honey Lemonade": "Jack Daniel's Limonata di miele",
    "Bickens Pink & Pink Tonic Style": "Bickens Pink & Pink Tonic Style",
    "Bacardi Mojito": "Bacardi Mojito",
    Шот: "Shottino",
    Шейкър: "Shaker",
    Фанта: "Fanta",
    Загорка: "Birra Zagorka",
    "Хайникен наливна": "Heineken alla spina",
    Шприц: "Spriz",
    Аперол: "Aperol",
    Лимончело: "Limoncello",
    Маркати: "Marcati",
    Мартини: "Martini",
    Бианко: "Bianco",
    Кампари: "Campari",
    Бакарди: "Bacardi",
    Ликьор: "Amaro",
    Дисароно: "Disaronno",
    Бушмилс: "Bushmills",
    Джони: "Johnnie Walker",
    Джеймсън: "Jameson",
    "J&B": "J&B",
    "Джак Даниелс": "Jack Daniels",
    "Сингъл Барел": "Single Barrel",
    Житная: "Zytnia",
    Абсолют: "Absolut",
    "Руски Стандарт": "Russkii Standart",
    Финландия: "Finlandia",
    Бифитър: "Beefeater",
    Горданс: "Gordon's",
    "Горданс Пинк": "Gordon's Pink",
    Джин: "Gin",
    Бомбай: "Bombay",
    Узо: "Ouzo",
    12: "12",
    7: "7",
    Пломари: "Plomari",
    Тсантали: "Tsantali",
    "Чивас Регал": "Chivas Regal",
    Кардю: "Cardhu",
    "Джентълмен Джак": "Gentleman Jack",
    Гленфидиш: "Glenfiddich",
    РедБул: "RedBull",
    "Сайкъл Сов Блан": "Cycle Sauvignon blanc",
    Столично: "Stolichno",
    Хайникен: "Heineken",
    Ариана: "Arianna",
    Старобърно: "Starobrno",
    Ботега: "Bottega",
    Морети: "Moretti",
    "Афтър Шок": "After Shock",
    Савой: "Savoy",
    "Зельоная Марка": "Green Mark",
    Столичная: "Stolichnaya",
    Търговище: "Targovishte",
    "Грей Гус": "Gray Goose",
    Белуга: "Beluga",
    "Бургас 63": "Burgas 63",
    "Бургаска Мускатова": "Uva Moscato di Burgas",
    "Кайлъшка гроздова": "Kaylashka",
    Карнобатска: "Karnobatska",
    Пещерска: "Peshterska",
    "Плиска Преслав": "Pliska Preslav",
    "Мента Пещера": "Peshtera Mint",
    "Къти Сарк": "Cutty Sark",
    "Джийм Бийм": "Jim Beam",
    "Джак Даниелс": "Jack",
    Грантс: "Grants",
    Метакса: "Metaxa",
    "Сайкъл Траминер": "Cycle Traminer",
    "Сайкъл Мерло и Пино Ноар": "Cycle Merlot & Pinot noir",
    "Сайкъл Розе": "Cycle Rose",
    "Едоардо Миролио Совиньон Блан": "Edoardo Miroglio Sauvignon Blanc",
    "JP Chenet - 250 мл": "JP. Chenet - 250 ml.",
    "Моет Шандон Брут": "Moët & Chandon Impérial Brut",
    Напитки: "Bevande",
    Коктейли: "Cocktail",
    "Друг алкохол": "Altro alcol",

    Кафетерия: "Caffetteria",
    Топли: "Calde",
    Фрешове: "Succhi",
    късо: "Corto",
    нормално: "Espresso",
    дълго: "Lungo",
    лв: "BGN",
    "25 мл": "25 ml.",
    "300 мл": "300 ml.",
    "50 мл": "50 ml.",
    "200 мл": "200 ml.",
    "250 мл": "250 ml.",
    "330 мл": "330 ml.",
    "350 мл": "350 ml.",
    "355 мл": "355 ml.",
    "375 мл": "375 ml.",
    "250 мл": "250 ml.",
    "500 мл": "500 ml.",
    "750 мл": "750 ml.",
    Безалкохолни: "Bevande",
    Бири: "Birre",
    Водка: "Vodka",
    Уиски: "Whisky",
    "без захар": "zero",
    "с лимон": "gusto limone",
    портокал: "arancia",
    праскова: "pesca",
    кайсия: "albicocca",
    ананас: "ananas",
    мандарина: "mandarino",
    тоник: "tonica",
    лимон: "limone",
    нар: "melograno",
    зелен: "verde",
    "горски плодове": "frutti di bosco",
    бяло: "bianco",
    черно: "nero",
    "-": "-",
    ягода: "fragola",
    "лайм-джинджър": "lime-zenzero",
    мента: "menta",
    топло: "caldo",
    студено: "freddo",
    черен: "fondente",
    бял: "bianca",
    "с кокос": "cocco",
    лате: "latte",
    "Micro USB": "Micro USB",
    "USB C": "USB C",
    iPhone: "iPhone",
    Боровинка: "mirtillo",
    Ябълка: "mela",
    Круша: "pera",
    Манго: "mango",
    грейпфрут: "pompelmo",
    микс: "misto",
    малко: "singolo",
    червено: "rosso",
    малка: "singolo",
    "12 годишно": "12 anni",
    боквайс: "bock weiss",
    вайс: "weiss",
    традиционна: "tradizionale",
    розе: "rose",

    "Additional comment:": "Note\naggiuntive:",

    // Nizza
  средна: "средна",
    голяма: "голяма",
    Пица: "Пица",
    Бургери: "Бургери",
    Гарнитури: "Гарнитури",
    Десерти: "Десерти",
    Сосове: "Сосове",
    Порции: "Порции",
    Сандвичи: "Сандвичи",
    Салати: "Салати",
    "From:": "От:",
    "City:": "Град:",
    "Street:": "Улица:",
    "Comment:": "Коментар:",
    Deliveries: "Доставки",
    Delivery: "Доставка",
    "Phone:": "Тел:",
    Добавка: "Добавка",
    Тесто: "Тесто",
    "Сусамово семе": "Сусамово семе",
    "Слънчогледово семе": "Слънчогледово семе",
    "Ленено семе": "Ленено семе",
    "Маково семе": "Маково семе",
    Борд: "Борд",
    Моцарела: "Моцарела",
    Чеснов: "Чеснов",
    Чедър: "Чедър",
    Филаделфия: "Филаделфия",
    "": "",

  'Маргарита': 'Маргарита',
  'Доматен сос и моцарела': 'Доматен сос и моцарела',
  'малка - 26 см': 'малка - 26 см',
  'Пресни домати': 'Пресни домати',
  'Чери домати': 'Чери домати',
  'Кисели краставички': 'Кисели краставички',
  'Сушени домати': 'Сушени домати',
  'Карамелизиран Лук': 'Карамелизиран Лук',
  'Халапеньо': 'Халапеньо',
  'Авокадо': 'Авокадо',
  'Свинска шунка': 'Свинска шунка',
  'Бекон': 'Бекон',
  'Пилешка шунка': 'Пилешка шунка',
  'Луканков салам': 'Луканков салам',
  'Прошуто': 'Прошуто',
  'Пеперони': 'Пеперони',
  'Кюфте': 'Кюфте',
  'Свинско Конци': 'Свинско Конци',
  'Пилешко филе': 'Пилешко филе',
  'Доматен сос': 'Доматен сос',
  'Сметана': 'Сметана',
  'Моцарела': 'Моцарела',
  'Синьо сирене': 'Синьо сирене',
  'Пармезан': 'Пармезан',
  'Бяло сирене': 'Бяло сирене',
  'Пушено сирене': 'Пушено сирене',
  'Яйце': 'Яйце',
  'Рукола': 'Рукола',
  'Айсберг': 'Айсберг',
  'Зелена чушка': 'Зелена чушка',
  'Гъби': 'Гъби',
  'Царевица': 'Царевица',
  'Червен лук': 'Червен лук',
  'Маслини': 'Маслини',
  'Люта чушка': 'Люта чушка',
  'Куатро Формаджи': 'Куатро Формаджи',
  'Доматен сос, моцарела, синьо сирене, чедър и топено сирене': 'Доматен сос, моцарела, синьо сирене, чедър и топено сирене',
  'Куатро Ди Карне': 'Куатро Ди Карне',
  'Доматен сос, моцарела, шунка, пилешко филе, бекон и луканов салам': 'Доматен сос, моцарела, шунка, пилешко филе, бекон и луканов салам',
  'Капричоза': 'Капричоза',
  'Доматен сос, моцарела, шунка, гъби и чушки': 'Доматен сос, моцарела, шунка, гъби и чушки',
  'Поло': 'Поло',
  'Доматен сос, моцарела, пилешко филе, гъби и чушки': 'Доматен сос, моцарела, пилешко филе, гъби и чушки',
  'Карбонара': 'Карбонара',
  'Сметанов сос, моцарела, бекон, гъби и пармезан': 'Сметанов сос, моцарела, бекон, гъби и пармезан',
  'Ница': 'Ница',
  'Доматен сос, моцарела, шунка, бекон, гъби и сметана': 'Доматен сос, моцарела, шунка, бекон, гъби и сметана',
  'Диаволо': 'Диаволо',
  'Доматен сос, моцарела, шунка, пеперони, люта чушка и чери домати': 'Доматен сос, моцарела, шунка, пеперони, люта чушка и чери домати',
  'Американо BBQ': 'Американо BBQ',
  'Доматен сос, BBQ сос, горчица, моцарела, пилешко филе, пеперони, чушки и маслини': 'Доматен сос, BBQ сос, горчица, моцарела, пилешко филе, пеперони, чушки и маслини',
  'Алфредо': 'Алфредо',
  'Сметанов сос, моцарела, пилешко филе, царевица и топено сирене': 'Сметанов сос, моцарела, пилешко филе, царевица и топено сирене',
  'Доматен сос, моцарела и пеперони': 'Доматен сос, моцарела и пеперони',
  'Мексикана': 'Мексикана',
  'Доматен сос, моцарела, пеперони, червен лук, маслини, царевица, люта чушка': 'Доматен сос, моцарела, пеперони, червен лук, маслини, царевица, люта чушка',
  'Италия': 'Италия',
  'Доматен сос, моцарела, сушени домати, пармезан и песто': 'Доматен сос, моцарела, сушени домати, пармезан и песто',
  'Салами': 'Салами',
  'Доматен сос, моцарела, луканков салам, чушка и маслини': 'Доматен сос, моцарела, луканков салам, чушка и маслини',
  'Вегетариана': 'Вегетариана',
  'Доматен сос, моцарела, гъби, царевица, чушка, червен лук и маслини': 'Доматен сос, моцарела, гъби, царевица, чушка, червен лук и маслини',
  'Прошуто фунги': 'Прошуто фунги',
  'Доматен сос, моцарела, прошуто крудо, гъби, рукола и пармезан': 'Доматен сос, моцарела, прошуто крудо, гъби, рукола и пармезан',
  'Бургер Пица': 'Бургер Пица',
  'Доматен сос, моцарела, телешко, халапеньо, чедър и гъби': 'Доматен сос, моцарела, телешко, халапеньо, чедър и гъби',
  'Сицилиана': 'Сицилиана',
  'Доматен сос, моцарела, пилешко филе, сушени домати, топени сирене и песто': 'Доматен сос, моцарела, пилешко филе, сушени домати, топени сирене и песто',
  'Калцоне': 'Калцоне',
  'Доматен сос, моцарела, шунка, гъби, кисели краствички': 'Доматен сос, моцарела, шунка, гъби, кисели краствички',
  'средна - 30 см': 'средна - 30 см',
  'голяма - 50 см': 'голяма - 50 см',
  'Ница Бургер': 'Ница Бургер',
  'Питка бриош, айсберг, домат, телешко, чедър, яйце, бекон, карамелизиран лук и ница сос': 'Питка бриош, айсберг, домат, телешко, чедър, яйце, бекон, карамелизиран лук и ница сос',
  'Класик Бургер': 'Класик Бургер',
  'Питка бриош, айсберг, кисели краставички, телешко, ница сос': 'Питка бриош, айсберг, кисели краставички, телешко, ница сос',
  'Чийз Бургер': 'Чийз Бургер',
  'Питка бриош, айсберг, кисели краставици, домат, телешко, чедър, червен лук и ница сос': 'Питка бриош, айсберг, кисели краставици, домат, телешко, чедър, червен лук и ница сос',
  'Пилешки Бургер': 'Пилешки Бургер',
  'Питка бриош, рукола, сушени домати, гриловано пилешко, червен лук, ементал и домашна майонеза': 'Питка бриош, рукола, сушени домати, гриловано пилешко, червен лук, ементал и домашна майонеза',
  'Криспи Чикън Бургер': 'Криспи Чикън Бургер',
  'Питка бриош, айсберг, кисели краставици, хрупкаво, панирано пилешко филе, бекон и японска майонеза': 'Питка бриош, айсберг, кисели краставици, хрупкаво, панирано пилешко филе, бекон и японска майонеза',
  'Смоуки Бургер': 'Смоуки Бургер',
  'Питка бриош, айсберг, телешко, бекон, лучени кръгчета, моцарела и ница сос': 'Питка бриош, айсберг, телешко, бекон, лучени кръгчета, моцарела и ница сос',
  'Пулд Порк Бургер': 'Пулд Порк Бургер',
  'Питка бриош, айсберг, кисели краставици, бавно готвено свинско месо, чедър, карамелизиран лук и барбекю сос': 'Питка бриош, айсберг, кисели краставици, бавно готвено свинско месо, чедър, карамелизиран лук и барбекю сос',
  'Спайси Найси Бургер': 'Спайси Найси Бургер',
  'Питка бриош, телешко, айсберг, чедър, халапеньо, доматено пикантно чътни и ница сос': 'Питка бриош, телешко, айсберг, чедър, халапеньо, доматено пикантно чътни и ница сос',
  'Веджи Бургер': 'Веджи Бургер',
  'Бургер от киноа и халуми, айсберг, чедър, домат, кисели краставички, червен лук': 'Бургер от киноа и халуми, айсберг, чедър, домат, кисели краставички, червен лук',
  'Детски Бургер (МЕНЮ)': 'Детски Бургер (МЕНЮ)',
  'Питка бриош, телешко, чедър, домат, кисели краставички и майонеза + пържени картофки и плодов сок': 'Питка бриош, телешко, чедър, домат, кисели краставички и майонеза + пържени картофки и плодов сок',
  'Лучени кръгчета': 'Лучени кръгчета',
  '200 гр': '200 гр',
  'Пържени картофи': 'Пържени картофи',
  'Хрупкави пилешки филенца': 'Хрупкави пилешки филенца',
  'хрупкави панирани пилешки филенца с чеснов сос': 'хрупкави панирани пилешки филенца с чеснов сос',
  'Порция с хрупкаво пиле': 'Порция с хрупкаво пиле',
  'Панирано филе, басмати ориз, микс салатки': 'Панирано филе, басмати ориз, микс салатки',
  'Порция пилешко филе': 'Порция пилешко филе',
  'Пилешко филе, басмати ориз, микс салатки  ': 'Пилешко филе, басмати ориз, микс салатки  ',
  'Порция телешко': 'Порция телешко',
  '2бр. телешки кюфтенца, басмати ориз, микс салатки': '2бр. телешки кюфтенца, басмати ориз, микс салатки',
  'Порция веджи': 'Порция веджи',
  'Кюфтенце от киноа, басмати ориз, микс салатки': 'Кюфтенце от киноа, басмати ориз, микс салатки',
  'Ницета с пеперони': 'Ницета с пеперони',
  'Пеперони, моцарела и масло': 'Пеперони, моцарела и масло',
  'Ницета с шунка': 'Ницета с шунка',
  'Шунка, моцарела и масло': 'Шунка, моцарела и масло',
  'Ницета с пилешко филе': 'Ницета с пилешко филе',
  'Пилешко филе, моцарела и масло': 'Пилешко филе, моцарела и масло',
  'Ницета с бекон': 'Ницета с бекон',
  'Бекон, моцарела и масло': 'Бекон, моцарела и масло',
  'Тортиля НИЦА': 'Тортиля НИЦА',
  'Мляно свинско – телешко месо,айсберг, домат, кисела краставичка,моцарела,карамелизиран лук,картофки,ница сос': 'Мляно свинско – телешко месо,айсберг, домат, кисела краставичка,моцарела,карамелизиран лук,картофки,ница сос',
  'Тортиля с пилешко': 'Тортиля с пилешко',
  'Пилешко филе,моцарела, рукола, сушени домати, червен лук, картофки, домашна майонеза': 'Пилешко филе,моцарела, рукола, сушени домати, червен лук, картофки, домашна майонеза',
  'Тортиля със свинско': 'Тортиля със свинско',
  'Бавно готвено свинско, айсберг, кисели краставички,айсберг,карамелизиран лук, картофки, моцарела,BBQ сос': 'Бавно готвено свинско, айсберг, кисели краставички,айсберг,карамелизиран лук, картофки, моцарела,BBQ сос',
  'Тортиля с хрупкаво пиле': 'Тортиля с хрупкаво пиле',
  'Хрупкаво панирано пилешко, айсберг, кисели краставички, домат,  моцарела, картофки, чеснов сос': 'Хрупкаво панирано пилешко, айсберг, кисели краставички, домат,  моцарела, картофки, чеснов сос',
  'Джоб Ница': 'Джоб Ница',
  'Телешко, айсберг, домати, кисели краставички ,моцарела, карамелизиран лук, картофи , ница сос': 'Телешко, айсберг, домати, кисели краставички ,моцарела, карамелизиран лук, картофи , ница сос',
  'Джоб Пулд порк': 'Джоб Пулд порк',
  'Бавно готвено свинско, айсберг, кисели краставички, червен лук, чедър, картофи, BBQ сос': 'Бавно готвено свинско, айсберг, кисели краставички, червен лук, чедър, картофи, BBQ сос',
  'Джоб Чийз BBQ бекон': 'Джоб Чийз BBQ бекон',
  'Хрупков бекон, чедър, айсберг, домат, червен лук, картофи, BBQ сос': 'Хрупков бекон, чедър, айсберг, домат, червен лук, картофи, BBQ сос',
  'Джоб Поло': 'Джоб Поло',
  'Пилешка шунка, моцарела, айсберг, домат, картофи, чеснов сос': 'Пилешка шунка, моцарела, айсберг, домат, картофи, чеснов сос',
  'Джоб Вегетериански': 'Джоб Вегетериански',
  'Моцарела, чедър, топено сирене, яйце, картофи, айсберг, домат, ница сос': 'Моцарела, чедър, топено сирене, яйце, картофи, айсберг, домат, ница сос',
  'Джоб Капризен': 'Джоб Капризен',
  'Прошуто кото, айсберг, домат, зелена чушка, моцарела, каротфи, майонеза': 'Прошуто кото, айсберг, домат, зелена чушка, моцарела, каротфи, майонеза',
  'Джоб сандвич Мексикански': 'Джоб сандвич Мексикански',
  'Телешко, домати, айсберг, царевица, халапеньо, червен лук, картофи, чили сос': 'Телешко, домати, айсберг, царевица, халапеньо, червен лук, картофи, чили сос',
  'Салата Цезар': 'Салата Цезар',
  'Айсберг, сос Цезар, пилешки филенца, чери домати, бекон, кротони и пармезан - 350 гр': 'Айсберг, сос Цезар, пилешки филенца, чери домати, бекон, кротони и пармезан - 350 гр',
  'Айсберг с рукола': 'Айсберг с рукола',
  'Айсберг, рукола, чери домати, сос Винегрет, пармезан - 250 гр': 'Айсберг, рукола, чери домати, сос Винегрет, пармезан - 250 гр',
  'Домати с печена чушка': 'Домати с печена чушка',
  'Белени домати, печени чушки, маслина, сирене, зехтин 400гр': 'Белени домати, печени чушки, маслина, сирене, зехтин 400гр',
  'Капрезе': 'Капрезе',
  'Домати, свежа моцарела, песто, рукола и пармезан - 400 гр': 'Домати, свежа моцарела, песто, рукола и пармезан - 400 гр',
  'Салата Кобб': 'Салата Кобб',
  'Айсберг, пилешко филе, сушени домати, авокадо, бекон, синьо сирене и лимонов дресинг - 350 гр': 'Айсберг, пилешко филе, сушени домати, авокадо, бекон, синьо сирене и лимонов дресинг - 350 гр',
  'Салата хрупкаво пиле': 'Салата хрупкаво пиле',
  'Айсбег, домат, царевица, панирано пиле, чеснов сос, пармезан': 'Айсбег, домат, царевица, панирано пиле, чеснов сос, пармезан',
  'Шоколадова кариолка': 'Шоколадова кариолка',
  'Шоколад , масло , ядки - 70 гр. / бр': 'Шоколад , масло , ядки - 70 гр. / бр',
  'Крем Ден и нощ': 'Крем Ден и нощ',
  'масло, сметана, шоколад, пандишпан, ядки - 160 гр. / бр': 'масло, сметана, шоколад, пандишпан, ядки - 160 гр. / бр',
  'Кока кола – 330ml': 'Кока кола – 330ml',
  '': '',
  'Фанта Портокал – 330ml': 'Фанта Портокал – 330ml',
  'Спрайт – 330ml': 'Спрайт – 330ml',
  'Кока кола – 500мл': 'Кока кола – 500мл',
  'Фанта Портокал – 500мл': 'Фанта Портокал – 500мл',
  'Спрайт – 500мл': 'Спрайт – 500мл',
  'Фанта Лимон – 500мл': 'Фанта Лимон – 500мл',
  'Кока кола – 2L': 'Кока кола – 2L',
  'Фанта Портокал – 2L': 'Фанта Портокал – 2L',
  'Нестий праскова – 500ml': 'Нестий праскова – 500ml',
  'Нестий лимон – 500ml': 'Нестий лимон – 500ml',
  'Нестий горски плод – 500ml': 'Нестий горски плод – 500ml',
  'Нестий манго и ананас – 500ml': 'Нестий манго и ананас – 500ml',
  'Минерална вода – 500ml': 'Минерална вода – 500ml',
  'Минерална вода – 1,5L': 'Минерална вода – 1,5L',
  'Айран – 330ml': 'Айран – 330ml',
  'Загорка кен – 500ml': 'Загорка кен – 500ml',
  'Хайникен кен – 500ml': 'Хайникен кен – 500ml',
  'Хайнекен 330': 'Хайнекен 330',
  'Кетчуп': 'Кетчуп',
  '50 гр': '50 гр',
  'Майонеза': 'Майонеза',
  'Горчица': 'Горчица',
  'Чили сос': 'Чили сос',
  'Чеснов сос': 'Чеснов сос',
  'Барбекю': 'Барбекю',
  'Сладко чили': 'Сладко чили',
  'Цезар': 'Цезар',
  'Песто': 'Песто',
  'Ница сос': 'Ница сос'
    
    },
  bg: {
    "Поръчай тук": "Поръчай тук",
    "Кафе Верняно": "Кафе Верняно",
    "Кафе Верняно - промо 10:00": "Кафе Верняно - промо 10:00",
    Фреш: "Фреш",
    "Кафе Верняно - 100% Арабика": "Кафе Верняно - 100% Арабика",
    "Кафе Верняно - Безкофеиново": "Кафе Верняно - Безкофеиново",
    Банкя: "Банкя",
    "Кока-Кола": "Кока-Кола",
    Капи: "Капи",
    Швепс: "Швепс",
    Спрайт: "Спрайт",
    "Студен чай": "Студен Чай",
    Чай: "Чай",
    Сода: "Сода",
    Фрапе: "Фрапе",
    "3в1": "3 в 1",
    Лате: "Лате",
    Лимонада: "Лимонада",
    Нескафе: "Нескафе",
    "Капучино Верняно": "Капучино Верняно",
    "Мляко с какао Верняно": "Мляко с какао Верняно",
    "Топъл Шоколад Верняно": "Топъл Шоколад Верняно",
    "Външна батерия": "Външна батерия",
    Цитронада: "Цитронада",
    Самърсби: "Самърсби",
    "Банкя - стъклена бутилка": "Банкя - стъклена бутилка",
    Корона: "Корона",
    "Jack Daniel's Honey Lemonade": "Jack Daniel's Honey Lemonade",
    "Bickens Pink & Pink Tonic Style": "Bickens Pink & Pink Tonic Style",
    "Bacardi Mojito": "Бакарди Мохито",
    Шот: "Шот",
    Шейкър: "Шейкър",
    Фанта: "Фанта",
    Загорка: "Загорка",
    "Хайникен наливна": "Хайникен наливна",
    Шприц: "Шприц",
    Аперол: "Аперол",
    Лимончело: "Limoncello",
    Маркати: "Marcati",
    Мартини: "Мартини",
    Бианко: "Бианко",
    Кампари: "Кампари",
    Бакарди: "Бакарди",
    Ликьор: "Ликьор",
    Дисароно: "Дисаронно",
    Бушмилс: "Бушмилс",
    Джони: "Джони",
    Джеймсън: "Джеймсън",
    "J&B": "J&B",
    "Джак Даниелс": "Джак Даниелс",
    "Сингъл Барел": "Сингъл Барел",
    Житная: "Житная",
    Абсолют: "Абсолют",
    "Руски Стандарт": "Руски Стандарт",
    Финландия: "Финландия",
    Бифитър: "Бифитър",
    Горданс: "Горданс",
    "Горданс Пинк": "Горданс Пинк",
    Джин: "Джин",
    Бомбай: "Бомбай",
    Узо: "Узо",
    12: "12",
    7: "7",
    Пломари: "Пломари",
    Тсантали: "Тсантали",
    "Чивас Регал": "Чивас Регал",
    Кардю: "Кардю",
    "Джентълмен Джак": "Джентълмен Джак",
    Гленфидиш: "Гленфидиш",
    РедБул: "РедБул",
    "Сайкъл Сов Блан": "Сайкъл Сов. Блан",
    Столично: "Столично",
    Хайникен: "Хайникен",
    Ариана: "Ариана",
    Старобърно: "Старобърно",
    Ботега: "Ботега",
    Морети: "Морети",
    "Афтър Шок": "Афтър Шок",
    Савой: "Савой",
    "Зельоная Марка": "Зельоная Марка",
    Столичная: "Столичная",
    Търговище: "Търговище",
    "Грей Гус": "Грей Гус",
    Белуга: "Белуга",
    "Бургас 63": "Бургас 63",
    "Бургаска Мускатова": "Бургаска Мускатова",
    "Кайлъшка гроздова": "Кайлъшка гроздова",
    Карнобатска: "Карнобатска",
    Пещерска: "Пещерска",
    "Плиска Преслав": "Плиска Преслав",
    "Мента Пещера": "Мента Пещера",
    "Къти Сарк": "Къти Сарк",
    "Джийм Бийм": "Джим Бийм",
    "Джак Даниелс": "Джак",
    Грантс: "Грантс",
    Метакса: "Метакса",
    "Сайкъл Траминер": "Сайкъл Траминер",
    "Сайкъл Мерло и Пино Ноар": "Сайкъл Мерло и Пино Ноар",
    "Сайкъл Розе": "Сайкъл Розе",
    "Едоардо Миролио Совиньон Блан": "Едоардо Миролио Совиньон Блан",
    "JP Chenet - 250 мл": "JP. Chenet - 250 мл.",
    "Моет Шандон Брут": "Moët & Chandon Impérial Brut",
    Напитки: "Напитки",
    Коктейли: "Коктейли",
    "Друг алкохол": "Друг алкохол",

    Кафетерия: "Кафетерия",
    Топли: "Топли",
    Фрешове: "Фрешове",
    късо: "късо",
    нормално: "нормално",
    дълго: "дълго",
    лв: "лв.",
    "25 мл": "25 мл.",
    "300 мл": "300 мл.",
    "50 мл": "50 мл.",
    "100 мл": "100 мл.",
    "200 мл": "200 мл.",
    "250 мл": "250 мл.",
    "330 мл": "330 мл.",
    "350 мл": "350 мл.",
    "355 мл": "355 мл.",
    "375 мл": "375 мл.",
    "250 мл": "250 мл.",
    "500 мл": "500 мл.",
    "750 мл": "750 мл.",
    Безалкохолни: "Безалкохолни",
    Бири: "Бири",
    Водка: "Водка",
    Уиски: "Уиски",
    "без захар": "без захар",
    "с лимон": "с лимон",
    портокал: "портокал",
    праскова: "праскова",
    кайсия: "кайсия",
    ананас: "ананас",
    мандарина: "мандарина",
    тоник: "тоник",
    лимон: "лимон",
    нар: "нар",
    зелен: "зелен",
    "горски плодове": "горски плодове",
    бяло: "бяло",
    черно: "черно",
    "-": "-",
    ягода: "ягода",
    "лайм-джинджър": "лайм-джинджър",
    мента: "мента",
    топло: "топло",
    студено: "студено",
    черен: "черен",
    бял: "бял",
    "с кокос": "с кокос",
    лате: "лате",
    "Micro USB": "Micro USB",
    "USB C": "USB C",
    iPhone: "iPhone",
    Боровинка: "боровинка",
    Ябълка: "ябълка",
    Круша: "круша",
    Манго: "манго",
    грейпфрут: "грейпфрут",
    микс: "микс",
    малко: "малко",
    червено: "червено",
    малка: "малка",
    "12 годишно": "12 годишно",
    боквайс: "боквайс",
    вайс: "вайс",
    традиционна: "традиционна",
    розе: "розе",
    "Additional comment:": "Забележки:",

    // Nizza
        средна: "средна",
    голяма: "голяма",
    Пица: "Пица",
    Бургери: "Бургери",
    Гарнитури: "Гарнитури",
    Десерти: "Десерти",
    Сосове: "Сосове",
    Порции: "Порции",
    Сандвичи: "Сандвичи",
    Салати: "Салати",
    "From:": "От:",
    "City:": "Град:",
    "Street:": "Улица:",
    "Comment:": "Коментар:",
    Deliveries: "Доставки",
    Delivery: "Доставка",
    "Phone:": "Тел:",
    Добавка: "Добавка",
    Тесто: "Тесто",
    "Сусамово семе": "Сусамово семе",
    "Слънчогледово семе": "Слънчогледово семе",
    "Ленено семе": "Ленено семе",
    "Маково семе": "Маково семе",
    Борд: "Борд",
    Моцарела: "Моцарела",
    Чеснов: "Чеснов",
    Чедър: "Чедър",
    Филаделфия: "Филаделфия",
    "": "",


  'Маргарита': 'Маргарита',
  'Доматен сос и моцарела': 'Доматен сос и моцарела',
  'малка - 26 см': 'малка - 26 см',
  'Пресни домати': 'Пресни домати',
  'Чери домати': 'Чери домати',
  'Кисели краставички': 'Кисели краставички',
  'Сушени домати': 'Сушени домати',
  'Карамелизиран Лук': 'Карамелизиран Лук',
  'Халапеньо': 'Халапеньо',
  'Авокадо': 'Авокадо',
  'Свинска шунка': 'Свинска шунка',
  'Бекон': 'Бекон',
  'Пилешка шунка': 'Пилешка шунка',
  'Луканков салам': 'Луканков салам',
  'Прошуто': 'Прошуто',
  'Пеперони': 'Пеперони',
  'Кюфте': 'Кюфте',
  'Свинско Конци': 'Свинско Конци',
  'Пилешко филе': 'Пилешко филе',
  'Доматен сос': 'Доматен сос',
  'Сметана': 'Сметана',
  'Моцарела': 'Моцарела',
  'Синьо сирене': 'Синьо сирене',
  'Пармезан': 'Пармезан',
  'Бяло сирене': 'Бяло сирене',
  'Пушено сирене': 'Пушено сирене',
  'Яйце': 'Яйце',
  'Рукола': 'Рукола',
  'Айсберг': 'Айсберг',
  'Зелена чушка': 'Зелена чушка',
  'Гъби': 'Гъби',
  'Царевица': 'Царевица',
  'Червен лук': 'Червен лук',
  'Маслини': 'Маслини',
  'Люта чушка': 'Люта чушка',
  'Куатро Формаджи': 'Куатро Формаджи',
  'Доматен сос, моцарела, синьо сирене, чедър и топено сирене': 'Доматен сос, моцарела, синьо сирене, чедър и топено сирене',
  'Куатро Ди Карне': 'Куатро Ди Карне',
  'Доматен сос, моцарела, шунка, пилешко филе, бекон и луканов салам': 'Доматен сос, моцарела, шунка, пилешко филе, бекон и луканов салам',
  'Капричоза': 'Капричоза',
  'Доматен сос, моцарела, шунка, гъби и чушки': 'Доматен сос, моцарела, шунка, гъби и чушки',
  'Поло': 'Поло',
  'Доматен сос, моцарела, пилешко филе, гъби и чушки': 'Доматен сос, моцарела, пилешко филе, гъби и чушки',
  'Карбонара': 'Карбонара',
  'Сметанов сос, моцарела, бекон, гъби и пармезан': 'Сметанов сос, моцарела, бекон, гъби и пармезан',
  'Ница': 'Ница',
  'Доматен сос, моцарела, шунка, бекон, гъби и сметана': 'Доматен сос, моцарела, шунка, бекон, гъби и сметана',
  'Диаволо': 'Диаволо',
  'Доматен сос, моцарела, шунка, пеперони, люта чушка и чери домати': 'Доматен сос, моцарела, шунка, пеперони, люта чушка и чери домати',
  'Американо BBQ': 'Американо BBQ',
  'Доматен сос, BBQ сос, горчица, моцарела, пилешко филе, пеперони, чушки и маслини': 'Доматен сос, BBQ сос, горчица, моцарела, пилешко филе, пеперони, чушки и маслини',
  'Алфредо': 'Алфредо',
  'Сметанов сос, моцарела, пилешко филе, царевица и топено сирене': 'Сметанов сос, моцарела, пилешко филе, царевица и топено сирене',
  'Доматен сос, моцарела и пеперони': 'Доматен сос, моцарела и пеперони',
  'Мексикана': 'Мексикана',
  'Доматен сос, моцарела, пеперони, червен лук, маслини, царевица, люта чушка': 'Доматен сос, моцарела, пеперони, червен лук, маслини, царевица, люта чушка',
  'Италия': 'Италия',
  'Доматен сос, моцарела, сушени домати, пармезан и песто': 'Доматен сос, моцарела, сушени домати, пармезан и песто',
  'Салами': 'Салами',
  'Доматен сос, моцарела, луканков салам, чушка и маслини': 'Доматен сос, моцарела, луканков салам, чушка и маслини',
  'Вегетариана': 'Вегетариана',
  'Доматен сос, моцарела, гъби, царевица, чушка, червен лук и маслини': 'Доматен сос, моцарела, гъби, царевица, чушка, червен лук и маслини',
  'Прошуто фунги': 'Прошуто фунги',
  'Доматен сос, моцарела, прошуто крудо, гъби, рукола и пармезан': 'Доматен сос, моцарела, прошуто крудо, гъби, рукола и пармезан',
  'Бургер Пица': 'Бургер Пица',
  'Доматен сос, моцарела, телешко, халапеньо, чедър и гъби': 'Доматен сос, моцарела, телешко, халапеньо, чедър и гъби',
  'Сицилиана': 'Сицилиана',
  'Доматен сос, моцарела, пилешко филе, сушени домати, топени сирене и песто': 'Доматен сос, моцарела, пилешко филе, сушени домати, топени сирене и песто',
  'Калцоне': 'Калцоне',
  'Доматен сос, моцарела, шунка, гъби, кисели краствички': 'Доматен сос, моцарела, шунка, гъби, кисели краствички',
  'средна - 30 см': 'средна - 30 см',
  'голяма - 50 см': 'голяма - 50 см',
  'Ница Бургер': 'Ница Бургер',
  'Питка бриош, айсберг, домат, телешко, чедър, яйце, бекон, карамелизиран лук и ница сос': 'Питка бриош, айсберг, домат, телешко, чедър, яйце, бекон, карамелизиран лук и ница сос',
  'Класик Бургер': 'Класик Бургер',
  'Питка бриош, айсберг, кисели краставички, телешко, ница сос': 'Питка бриош, айсберг, кисели краставички, телешко, ница сос',
  'Чийз Бургер': 'Чийз Бургер',
  'Питка бриош, айсберг, кисели краставици, домат, телешко, чедър, червен лук и ница сос': 'Питка бриош, айсберг, кисели краставици, домат, телешко, чедър, червен лук и ница сос',
  'Пилешки Бургер': 'Пилешки Бургер',
  'Питка бриош, рукола, сушени домати, гриловано пилешко, червен лук, ементал и домашна майонеза': 'Питка бриош, рукола, сушени домати, гриловано пилешко, червен лук, ементал и домашна майонеза',
  'Криспи Чикън Бургер': 'Криспи Чикън Бургер',
  'Питка бриош, айсберг, кисели краставици, хрупкаво, панирано пилешко филе, бекон и японска майонеза': 'Питка бриош, айсберг, кисели краставици, хрупкаво, панирано пилешко филе, бекон и японска майонеза',
  'Смоуки Бургер': 'Смоуки Бургер',
  'Питка бриош, айсберг, телешко, бекон, лучени кръгчета, моцарела и ница сос': 'Питка бриош, айсберг, телешко, бекон, лучени кръгчета, моцарела и ница сос',
  'Пулд Порк Бургер': 'Пулд Порк Бургер',
  'Питка бриош, айсберг, кисели краставици, бавно готвено свинско месо, чедър, карамелизиран лук и барбекю сос': 'Питка бриош, айсберг, кисели краставици, бавно готвено свинско месо, чедър, карамелизиран лук и барбекю сос',
  'Спайси Найси Бургер': 'Спайси Найси Бургер',
  'Питка бриош, телешко, айсберг, чедър, халапеньо, доматено пикантно чътни и ница сос': 'Питка бриош, телешко, айсберг, чедър, халапеньо, доматено пикантно чътни и ница сос',
  'Веджи Бургер': 'Веджи Бургер',
  'Бургер от киноа и халуми, айсберг, чедър, домат, кисели краставички, червен лук': 'Бургер от киноа и халуми, айсберг, чедър, домат, кисели краставички, червен лук',
  'Детски Бургер (МЕНЮ)': 'Детски Бургер (МЕНЮ)',
  'Питка бриош, телешко, чедър, домат, кисели краставички и майонеза + пържени картофки и плодов сок': 'Питка бриош, телешко, чедър, домат, кисели краставички и майонеза + пържени картофки и плодов сок',
  'Лучени кръгчета': 'Лучени кръгчета',
  '200 гр': '200 гр',
  'Пържени картофи': 'Пържени картофи',
  'Хрупкави пилешки филенца': 'Хрупкави пилешки филенца',
  'хрупкави панирани пилешки филенца с чеснов сос': 'хрупкави панирани пилешки филенца с чеснов сос',
  'Порция с хрупкаво пиле': 'Порция с хрупкаво пиле',
  'Панирано филе, басмати ориз, микс салатки': 'Панирано филе, басмати ориз, микс салатки',
  'Порция пилешко филе': 'Порция пилешко филе',
  'Пилешко филе, басмати ориз, микс салатки  ': 'Пилешко филе, басмати ориз, микс салатки  ',
  'Порция телешко': 'Порция телешко',
  '2бр. телешки кюфтенца, басмати ориз, микс салатки': '2бр. телешки кюфтенца, басмати ориз, микс салатки',
  'Порция веджи': 'Порция веджи',
  'Кюфтенце от киноа, басмати ориз, микс салатки': 'Кюфтенце от киноа, басмати ориз, микс салатки',
  'Ницета с пеперони': 'Ницета с пеперони',
  'Пеперони, моцарела и масло': 'Пеперони, моцарела и масло',
  'Ницета с шунка': 'Ницета с шунка',
  'Шунка, моцарела и масло': 'Шунка, моцарела и масло',
  'Ницета с пилешко филе': 'Ницета с пилешко филе',
  'Пилешко филе, моцарела и масло': 'Пилешко филе, моцарела и масло',
  'Ницета с бекон': 'Ницета с бекон',
  'Бекон, моцарела и масло': 'Бекон, моцарела и масло',
  'Тортиля НИЦА': 'Тортиля НИЦА',
  'Мляно свинско – телешко месо,айсберг, домат, кисела краставичка,моцарела,карамелизиран лук,картофки,ница сос': 'Мляно свинско – телешко месо,айсберг, домат, кисела краставичка,моцарела,карамелизиран лук,картофки,ница сос',
  'Тортиля с пилешко': 'Тортиля с пилешко',
  'Пилешко филе,моцарела, рукола, сушени домати, червен лук, картофки, домашна майонеза': 'Пилешко филе,моцарела, рукола, сушени домати, червен лук, картофки, домашна майонеза',
  'Тортиля със свинско': 'Тортиля със свинско',
  'Бавно готвено свинско, айсберг, кисели краставички,айсберг,карамелизиран лук, картофки, моцарела,BBQ сос': 'Бавно готвено свинско, айсберг, кисели краставички,айсберг,карамелизиран лук, картофки, моцарела,BBQ сос',
  'Тортиля с хрупкаво пиле': 'Тортиля с хрупкаво пиле',
  'Хрупкаво панирано пилешко, айсберг, кисели краставички, домат,  моцарела, картофки, чеснов сос': 'Хрупкаво панирано пилешко, айсберг, кисели краставички, домат,  моцарела, картофки, чеснов сос',
  'Джоб Ница': 'Джоб Ница',
  'Телешко, айсберг, домати, кисели краставички ,моцарела, карамелизиран лук, картофи , ница сос': 'Телешко, айсберг, домати, кисели краставички ,моцарела, карамелизиран лук, картофи , ница сос',
  'Джоб Пулд порк': 'Джоб Пулд порк',
  'Бавно готвено свинско, айсберг, кисели краставички, червен лук, чедър, картофи, BBQ сос': 'Бавно готвено свинско, айсберг, кисели краставички, червен лук, чедър, картофи, BBQ сос',
  'Джоб Чийз BBQ бекон': 'Джоб Чийз BBQ бекон',
  'Хрупков бекон, чедър, айсберг, домат, червен лук, картофи, BBQ сос': 'Хрупков бекон, чедър, айсберг, домат, червен лук, картофи, BBQ сос',
  'Джоб Поло': 'Джоб Поло',
  'Пилешка шунка, моцарела, айсберг, домат, картофи, чеснов сос': 'Пилешка шунка, моцарела, айсберг, домат, картофи, чеснов сос',
  'Джоб Вегетериански': 'Джоб Вегетериански',
  'Моцарела, чедър, топено сирене, яйце, картофи, айсберг, домат, ница сос': 'Моцарела, чедър, топено сирене, яйце, картофи, айсберг, домат, ница сос',
  'Джоб Капризен': 'Джоб Капризен',
  'Прошуто кото, айсберг, домат, зелена чушка, моцарела, каротфи, майонеза': 'Прошуто кото, айсберг, домат, зелена чушка, моцарела, каротфи, майонеза',
  'Джоб сандвич Мексикански': 'Джоб сандвич Мексикански',
  'Телешко, домати, айсберг, царевица, халапеньо, червен лук, картофи, чили сос': 'Телешко, домати, айсберг, царевица, халапеньо, червен лук, картофи, чили сос',
  'Салата Цезар': 'Салата Цезар',
  'Айсберг, сос Цезар, пилешки филенца, чери домати, бекон, кротони и пармезан - 350 гр': 'Айсберг, сос Цезар, пилешки филенца, чери домати, бекон, кротони и пармезан - 350 гр',
  'Айсберг с рукола': 'Айсберг с рукола',
  'Айсберг, рукола, чери домати, сос Винегрет, пармезан - 250 гр': 'Айсберг, рукола, чери домати, сос Винегрет, пармезан - 250 гр',
  'Домати с печена чушка': 'Домати с печена чушка',
  'Белени домати, печени чушки, маслина, сирене, зехтин 400гр': 'Белени домати, печени чушки, маслина, сирене, зехтин 400гр',
  'Капрезе': 'Капрезе',
  'Домати, свежа моцарела, песто, рукола и пармезан - 400 гр': 'Домати, свежа моцарела, песто, рукола и пармезан - 400 гр',
  'Салата Кобб': 'Салата Кобб',
  'Айсберг, пилешко филе, сушени домати, авокадо, бекон, синьо сирене и лимонов дресинг - 350 гр': 'Айсберг, пилешко филе, сушени домати, авокадо, бекон, синьо сирене и лимонов дресинг - 350 гр',
  'Салата хрупкаво пиле': 'Салата хрупкаво пиле',
  'Айсбег, домат, царевица, панирано пиле, чеснов сос, пармезан': 'Айсбег, домат, царевица, панирано пиле, чеснов сос, пармезан',
  'Шоколадова кариолка': 'Шоколадова кариолка',
  'Шоколад , масло , ядки - 70 гр. / бр': 'Шоколад , масло , ядки - 70 гр. / бр',
  'Крем Ден и нощ': 'Крем Ден и нощ',
  'масло, сметана, шоколад, пандишпан, ядки - 160 гр. / бр': 'масло, сметана, шоколад, пандишпан, ядки - 160 гр. / бр',
  'Кока кола – 330ml': 'Кока кола – 330ml',
  '': '',
  'Фанта Портокал – 330ml': 'Фанта Портокал – 330ml',
  'Спрайт – 330ml': 'Спрайт – 330ml',
  'Кока кола – 500мл': 'Кока кола – 500мл',
  'Фанта Портокал – 500мл': 'Фанта Портокал – 500мл',
  'Спрайт – 500мл': 'Спрайт – 500мл',
  'Фанта Лимон – 500мл': 'Фанта Лимон – 500мл',
  'Кока кола – 2L': 'Кока кола – 2L',
  'Фанта Портокал – 2L': 'Фанта Портокал – 2L',
  'Нестий праскова – 500ml': 'Нестий праскова – 500ml',
  'Нестий лимон – 500ml': 'Нестий лимон – 500ml',
  'Нестий горски плод – 500ml': 'Нестий горски плод – 500ml',
  'Нестий манго и ананас – 500ml': 'Нестий манго и ананас – 500ml',
  'Минерална вода – 500ml': 'Минерална вода – 500ml',
  'Минерална вода – 1,5L': 'Минерална вода – 1,5L',
  'Айран – 330ml': 'Айран – 330ml',
  'Загорка кен – 500ml': 'Загорка кен – 500ml',
  'Хайникен кен – 500ml': 'Хайникен кен – 500ml',
  'Хайнекен 330': 'Хайнекен 330',
  'Кетчуп': 'Кетчуп',
  '50 гр': '50 гр',
  'Майонеза': 'Майонеза',
  'Горчица': 'Горчица',
  'Чили сос': 'Чили сос',
  'Чеснов сос': 'Чеснов сос',
  'Барбекю': 'Барбекю',
  'Сладко чили': 'Сладко чили',
  'Цезар': 'Цезар',
  'Песто': 'Песто',
  'Ница сос': 'Ница сос'
  
  },
};

let locale = 'en';

setTimeout(async () => {
  try {
    const storedLocale = await AsyncStorage.getItem("locale");
    if (storedLocale) {
      locale = storedLocale;
    }
  } catch (ex) {}
}, 0);


export default {
    t: function (text) {
	return translations[locale][text] ? translations[locale][text] : text
    }
};
