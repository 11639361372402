import React, { useState } from "react";
import {
  Animated,
  Platform,
  Button,
  Image,
  TouchableHighlight,
  useColorScheme,
  Pressable,
  ScrollView,
  TextInput,
} from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { RectButton } from "react-native-gesture-handler";
import * as ImagePicker from "expo-image-picker";
import { Picker } from "@react-native-picker/picker";
import { MaterialIcons } from "@expo/vector-icons";
import { v4 as uuidv4 } from "uuid";
import ProductItem from "./ProductItem";
import { Text, View } from "./Themed";
import i18n from "../utils/localization";
export default function SwipeableProductItem({
  products,
  item,
  onCreate,
  index,
  chooseVariantValue,
  chooseVariant,
  imageValue,
  setImageValue,
  optionsValue,
  setOptionsValue,
  commentValue,
  setCommentValue,
  colorScheme,
  openOnTap,
    displayMode,
    setDisplayMode,
}) {
  let swipeableRow;
  function updateRef(ref) {
    swipeableRow = ref;
  }

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    //let result = await ImagePicker.launchCameraAsync({
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      base64: true,
      exif: true,
      quality: 0.2,
    });

    console.log(result);

    if (!result.canceled) {
      setImageValue(index, {
        ...result.assets[0],
      });
    }
  };
  const idx = products.findIndex((product) => product.id === item.id);
  const product = products[idx];

  return (
    <Swipeable
      ref={updateRef}
      useNativeAnimations={Platform.OS !== "web"}
      renderRightActions={(progress, dragX) => {
        const trans = dragX.interpolate({
          inputRange: [0, 50, 100, 101],
          outputRange: [0, 0, 0, 1],
        });
        return (
          <TouchableHighlight
            onPress={(p) => {
              if (openOnTap) {
                swipeableRow.close();
              }
            }}
            style={{
              flex: 1,
              flexDirection: "row",
              transform: [{ translateX: 0 }],
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              
              <ScrollView
                conentContainerStyle={{
                  alignItems: "center",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
		  <View style={{flexDirection: 'row', flex: 1, paddingLeft: 5, paddingTop: 5}}>
                <Text>{i18n.t("Additional comment:")}</Text>
                <TouchableHighlight style={{paddingLeft: 5}}>
                  <TextInput
                    style={{
                      fontSize: 20,
                      width: 150,
                      color: colorScheme === "dark" ? "white" : "black",
                      borderColor: colorScheme === "dark" ? "white" : "black",
                      borderWidth: 1,
                    }}
                    onChangeText={(value) => {
                      setCommentValue(index, value);
                    }}
                    value={commentValue || ""}
                  ></TextInput>
                </TouchableHighlight>

              <Pressable
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
		      flex: 2,
                }}
                onPress={() => {
                  setTimeout(() => {
                    
                    onCreate({
                      id: uuidv4(),
                      productIds: [product.id],
                      capabilities: product.capabilities.map((cap) => {
                        if (cap === "printPicture" && imageValue) {
                          setImageValue(index, null);
                          return imageValue.base64;
                        }
                        return null;
                      }),
                      comment: commentValue || "",
                      quantities: [1],
                      options: optionsValue ? Object.keys(optionsValue).flatMap(o => Array.isArray(optionsValue[o]) ? optionsValue[o].flatMap(ov => [o, ov]): [o, optionsValue[o]]) : [],
                      variants: [product.variants[chooseVariantValue || 0]],
                    });
                  }, 30);
                  setCommentValue(index, "");
                  if (swipeableRow) {
                    swipeableRow.close();
                  }
                }}
              >
                <Animated.Text
                  style={[
                    {
                      color: "white",
                    },
                  ]}
                >
                  <MaterialIcons
                    name="send"
                    size={24}
                    color={colorScheme === "dark" ? "white" : "black"}
                  />
                </Animated.Text>
              </Pressable>
		</View>
		  <View>
			{product.options &&
                product.options.map((opt) => {
		  console.log(optionsValue)
                  if (opt.type === "add") {
                    return (
                      <View
                        key={opt.id}
                        style={{
			  flexDirection: 'row',
			  flexWrap: 'wrap',
                          padding: 5,
                        }}
                      >
			    { optionsValue && optionsValue[opt.id] && Array.isArray(optionsValue[opt.id]) && optionsValue[opt.id].map((ov, ovi) => {
                                const optionMeta = opt.options.filter(om => om.id === ov)[0];
				return <Pressable key={ov+ovi} style={{backgroundColor: 'gray', padding: 5, margin: 5, borderRadius: 2}} onPress={() => {
				   const newValues = [...optionsValue[opt.id]];
				   newValues.splice(ovi, 1);
			           
				   setOptionsValue(index, opt.id, newValues);

				}}><Text>{optionMeta.title} X</Text></Pressable>
			    })}
                      </View>
                    );
                  }
			
                })}

		</View>
              </ScrollView>
		<View style={{flex: 1, flexDirection: 'row'}}>
              {product.capabilities &&
                product.capabilities.map((cap) => {
                  if (cap === "printPicture") {
                    return (
                      <View
                        key={cap}
                        style={{
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {!imageValue && (
                          <Button
                            title="Coffee print image"
                            onPress={pickImage}
                          />
                        )}
                        {imageValue && (
                          <TouchableHighlight onPress={pickImage}>
                            <Image
                              source={{ uri: imageValue.uri }}
                              style={{
                                width: imageValue.width,
                                height: imageValue.height,
                                maxWidth: 100,
                                maxHeight: 100,
                              }}
                            />
                          </TouchableHighlight>
                        )}
                      </View>
                    );
                  }
                })}
              {product.options &&
                product.options.map((opt) => {
                  if (opt.type === "conf") {
                    return (
                      <View
                        key={opt.id}
                        style={{
                          alignItems: "center",
                          flex: 3,
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: 5,
                        }}
                      >
                        <Text>{opt.title}:</Text>
                        <Pressable>
                          <Picker
                            style={{width: 150, padding: 5}}
                            value={optionsValue && optionsValue[opt.id]}
                            onValueChange={(value) => {
                              setOptionsValue(index, opt.id, value);
                            }}
                          >
                            {!opt.required && (
                              <Picker.Item
                                key={"none"}
                                label={""}
                                value={""}
                                style={{
                                  color:
                                    colorScheme === "dark" ? "white" : "black",
                                }}
                              />
                            )}
                            {opt.options.map((o) => (
                              <Picker.Item
                                key={o.id}
                                label={o.title + ' +' + o.prices[chooseVariantValue || 0] + ' лв.'} 
                                value={o.id}
                                style={{
                                  color:
                                    colorScheme === "dark" ? "white" : "black",
                                }}
                              />
                            ))}
                          </Picker>
                        </Pressable>
                      </View>
                    );
                  }
                  
	          else if (opt.type === "add") {
                    return (
                      <View
                        key={opt.id}
                        style={{
                          alignItems: "center",
                          flex: 3,
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: 5,
                        }}
                      >
                        <Text>{opt.title}:</Text>
                        <Pressable>
                          <Picker
                            style={{width: 150, padding: 5}}
                            value={optionsValue && optionsValue[opt.id]}
                            onValueChange={(value) => {
				    if (value) {
                              setOptionsValue(index, opt.id, optionsValue && optionsValue[opt.id] ? [...optionsValue[opt.id], value] : [value]);
				    }
                            }}
                          >
                            {!opt.required && (
                              <Picker.Item
                                key={"none"}
                                label={""}
                                value={""}
                                style={{
                                  color:
                                    colorScheme === "dark" ? "white" : "black",
                                }}
                              />
                            )}
                            {opt.options.map((o) => (
                              <Picker.Item
                                key={o.id}
                                label={o.title + ' +' + o.prices[chooseVariantValue || 0] + ' лв.'} 
                                value={o.id}
                                style={{
                                  color:
                                    colorScheme === "dark" ? "white" : "black",
                                }}
                              />
                            ))}
                          </Picker>
                        </Pressable>
                      </View>
                    );
                  }
			
                })}
                </View>
            </View>
          </TouchableHighlight>
        );
      }}
    >
      {/* <TouchableHighlight
            onPress={() => console.log("You touched me")}
            style={styles.rowFront}
            underlayColor={"#AAA"}
          > */}
      <TouchableHighlight
        style={{ flex: 1 }}
        onPress={() => {
          if (openOnTap) {
            swipeableRow.openRight();
          }
        }}
      >
        <ProductItem
          {...item}
          index={index}
          colorScheme={colorScheme}
          chooseVariantValue={chooseVariantValue}
          chooseVariant={chooseVariant}
          onChooseVariant={({ id, variant }) => {
            chooseVariant(index, variant);
          }}
	    displayMode={displayMode}
	    setDisplayMode={setDisplayMode}
        />
      </TouchableHighlight>
      {/* </TouchableHighlight> */}
    </Swipeable>
  );
}
