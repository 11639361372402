
import React, { useLayoutEffect } from 'react';
import {TouchableHighlight} from 'react-native';
import Alert from "./Alert";
import {View, Text} from './Themed';

import Config from "../constants/Config";

export function useActionsHeader(navigation, item, token, operatorId, itemId, children) {
    useLayoutEffect(() => {
        navigation.setOptions({
          headerTitle: `${item.name || item.number}`,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          headerRight: () =>
            <View style={{flex:1, flexDirection: 'row', backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center', paddingRight: 20}}>
              <View style={{flex:4, flexDirection: 'row', backgroundColor: 'transparent',  alignItems: 'center', justifyContent: 'center'}}>
                {children.map((child, i) => {
                    return child.condition && (
                          <View key={i} style={{flex:1, flexDirection: 'row', backgroundColor: 'transparent'}}>
                            <View style={{flex:1, backgroundColor: 'transparent'}}>
                              <TouchableHighlight
                                onPress={() => {
                                    if (!child.onPress) return;
                                    if (child.confirm) {
                                        Alert.alert(
                                        "Please confirm",
                                        child.confirm,
                                        [
                                            {
                                            text: "Cancel",
                                            onPress: () => { }
                                            },
                                            {
                                            text: "Yes",
                                            style: "cancel",
                                            onPress: () => { child.onPress() }
                                                
                                            },
                                        ],
                                        { cancelable: false },
                                        )
                                    } else {
                                        child.onPress()
                                    }
                                }}
                                underlayColor={"transparent"}
                              >
                                <Text
                                  style={{
                                    color: "white",
                                    backgroundColor: "transparent",
                                    fontFamily: Config.theme.defaultFontFamily,
                                    padding: 5,
                                  }}
                                >
                                    {child.icon}
                                </Text>
                              </TouchableHighlight>
                            </View>
                          </View>
                        )
                })}
                
              </View>

            </View>,
        });
      }, [navigation, item, token, operatorId, itemId, children]);
}