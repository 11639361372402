import { StatusBar } from "expo-status-bar";
import {
  Animated,
  Platform,
  Button,
  Modal,
  StyleSheet,
  FlatList,
  TouchableHighlight,
  useWindowDimensions,
  useColorScheme,
  TextInput,
} from "react-native";
import Slider from '@react-native-community/slider';
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState, useEffect, useRef } from "react";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import { useLinkTo } from "@react-navigation/native";
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { RectButton } from 'react-native-gesture-handler';
import { MaterialIcons, MaterialCommunityIcons, Feather  } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { Text, View } from "./Themed";
import Alert, { AlertRoot } from "./Alert";
import ProductItem from "./ProductItem";
import SwipeableProductItem from './SwipeableProductItem';
import { useActionsHeader } from "./ActionsHeader";
import i18n from "../utils/localization";
import StickyContainer from 'recyclerlistview/dist/reactnative/core/StickyContainer';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
import TimeSelect from './TimeSelect';

import ComputerPicker from "./ComputerPicker";

const FINALIZE_COMPUTER = gql`
  mutation finalizeComputer(
    $token: String!
    $operator: ID!
    $computer: ID!
    $computerToken: String!
    $paymentType: String!
    $discount: Int!
  ) {
    finalizeComputer(
      token: $token
      operator: $operator
      computer: $computer
      computerToken: $computerToken
      paymentType: $paymentType
      discount: $discount
    )
  }
`;

const START_COMPUTER_TIME = gql`
  mutation startComputerTime(
    $token: String!
    $operator: ID!
    $computer: ID!
    $computerToken: String!
    $autostop: Int
  ) {
    startComputerTime(
      token: $token
      operator: $operator
      computer: $computer
      computerToken: $computerToken
      autostop: $autostop
    )
  }
`;

const STOP_COMPUTER_TIME = gql`
  mutation stopComputerTime(
    $token: String!
    $operator: ID!
    $computer: ID!
    $computerToken: String!
  ) {
    stopComputerTime(
      token: $token
      operator: $operator
      computer: $computer
      computerToken: $computerToken
    )
  }
`;

const SWAP_COMPUTERS = gql`
  mutation swapComputers(
    $token: String!
    $operator: ID!
    $computer: ID!
    $otherComputer: ID!
  ) {
    swapComputers(
      token: $token
      operator: $operator
      computer: $computer
      otherComputer: $otherComputer
    )
  }
`;

const CREATE_COMPUTER_ORDER = gql`
  mutation createComputerOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $computer: ID!
    $productIds: [ID!]!
    $quantities: [Int!]!
    $variants: [String!]!
    $comment: String
    $capabilities: [String]!
  ) {
    createComputerOrder(
      id: $id
      token: $token
      operator: $operator
      computer: $computer
      productIds: $productIds
      quantities: $quantities
      variants: $variants
      comment: $comment
      capabilities: $capabilities
    ) {
      id
      operator
      available
      published
      capacity
      number
      rate
      timestamp
      timestampStart
      timestampEnd
      name
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        comment
        quantities
        variants
        computerId
        capabilities
        timestamp
        status
      }
    }
  }
`;


const DELETE_COMPUTER_ORDER = gql`
  mutation deleteComputerOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $computer: ID!
  ) {
    deleteComputerOrder(
      id: $id
      token: $token
      operator: $operator
      computer: $computer
    ) {
      id
      operator
      available
      published
      capacity
      rate
      timestamp
      timestampStart
      timestampEnd
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        computerId
        capabilities
        timestamp
        status
      }
    }
  }
`;


const CLEAR_COMPUTER_ORDERS = gql`
  mutation clearComputerOrders($token: String!, $operator: ID!, $computer: ID!) {
    clearComputerOrders(token: $token, operator: $operator, computer: $computer) {
      id
      operator
      available
      published
      capacity
      number
      name
      rate
      timestamp
      timestampStart
      timestampEnd
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        capabilities
        computerId
        timestamp
        status
      }
    }
  }
`;

const COMPUTERS_SUBSCRIPTION = gql`
  subscription {
    computers {
      id
      operator
      available
      published
      capacity
      number
      name
      type
      token
      rate
      timestamp
      timestampStart
      timestampEnd
      x
      y
      orders {
        id
        operator
        productIds
        comment
        quantities
        computerId
        capabilities
        timestamp
        status
      }
    }
  }
`;

const TAKE_COMPUTER_OWNERSHIP = gql`
  mutation takeComputerOwnership(
    $token: String!
    $operator: ID!
    $computer: ID!
  ) {
    takeComputerOwnership(
      token: $token
      operator: $operator
      computer: $computer
    )
  }
`;

const Jared = { width: 1920, numColumns: 1 };


export default function ComputerOrderView({ banner, route, navigation, products, computer, categories, computers }) {
  const computerId = route.params && route.params.computerId;
  const computerToken = route.params && route.params.computerToken;

  const ordersRef = useRef(null);
  const autostopRef = useRef('');
  const window = useWindowDimensions();
  const [computerPickerVisible, setComputerPickerVisible] = useState(false);
  const [newOrderItems, setNewOrderItems] = useState([]);
  const [rowTranslateAnimatedValues, setTranslateAnimatedValues] = useState({});
  const [variants, setVariants] = useState({});

  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState(null);
  const [role, setRole] = useState(null);
  const [token, setToken] = useState(null);

  const [comment, setComment] = useState('');

  const [discount, setDiscount] = useState(0);

  const linkTo = useLinkTo();

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedRole = await AsyncStorage.getItem("role");
        setRole(storedRole);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { computerSubscriptionData, computerSubscriptionLoading } =
    useSubscription(COMPUTERS_SUBSCRIPTION);

  const [
    finalizeComputer,
    { finalizeComputerData, finalizeComputerLoading, finalizeComputerError },
  ] = useMutation(FINALIZE_COMPUTER);

  const [
    startComputerTime,
  ] = useMutation(START_COMPUTER_TIME);

  const [
    stopComputerTime,
  ] = useMutation(STOP_COMPUTER_TIME);

  const [
    createComputerOrder,
    { createComputerOrderData, createComputerOrderLoading, createComputerOrderError },
  ] = useMutation(CREATE_COMPUTER_ORDER, {
    ignoreResults: true,
  });

  const [
    swapComputers,
  ] = useMutation(SWAP_COMPUTERS);

  const [
    takeComputerOwnership
  ] = useMutation(TAKE_COMPUTER_OWNERSHIP);

  const [
    clearComputerOrders,
    { clearComputerOrdersData, clearComputerOrdersLoading, clearComputerOrdersError },
  ] = useMutation(CLEAR_COMPUTER_ORDERS);

  const [
    deleteComputerOrder
  ] = useMutation(DELETE_COMPUTER_ORDER, {
    ignoreResults: true
  });

  const productsListRef = useRef(null);

  Jared.width = window.width;
  const [dataProvider, setDataProvider] = useState(() => { return new DataProvider((r1, r2) => r1 !== r2) });
  const [dataProvider2, setDataProvider2] = useState(() => { return new DataProvider((r1, r2) => r1 !== r2) });
  const [extendedState, setExtendedState] = useState({
    chooseVariantValue: {},
    commentValue: {},
    imageValue: {},
  });
  const [numColumns, setNumColumns] = useState(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    return numColumns;
  });

  const [layoutProvider, setLayoutProvider] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 30) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  const [layoutProvider2, setLayoutProvider2] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 30) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  useEffect(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    setNumColumns(numColumns);
    Jared.width = window.width;
    Jared.numColumns = numColumns;
  }, [window]);

  useEffect(() => {
    if (products) {
      setDataProvider(dataProvider.cloneWithRows(products))
    }
  }, [products]);

  useEffect(() => {
    if (computer && computer.orders) {
      setDataProvider2(dataProvider2.cloneWithRows(computer.orders))
    }
  }, [computer]);

  const [autostop, setAutostop] = useState('')

  const totalOrders = computer ? computer.orders
    .map((order, i) => {
      return order.productIds
        .map((pis, pisindex) => {
          const product = products.filter((p) => p.id === pis)[0];
          const indexOfVariant = product.variants.findIndex(
            (v) => v === order.variants[pisindex]
          );
          return product.prices[indexOfVariant] * order.quantities[pisindex];
        })
        .reduce((a, b) => a + b, 0);
    })
    .reduce((a, b) => a + b, 0) : [];

  let [total, setTotal] = useState(totalOrders);
  let [minutes, setMinutes] = useState(0);
  let [totalDiscount, setTotalDiscount] = useState(totalOrders);

  useEffect(() => {
    const interval = setInterval(() => {
      
      //console.log(computer.timestampEnd);
      //console.log(new Date(computer.timestampEnd * 1000));
      // console.log(computer.timestampStart);
      // console.log(new Date(parseInt(computer.timestampStart)));
      const endDate = computer.timestampEnd ? new Date(parseInt(computer.timestampEnd)) : new Date();
      const totalMinutes = ((endDate - new Date(parseInt(computer.timestampStart)))/1000/60 );
      const computerComponent = computer.timestampStart ? ((computer.rate/60) * totalMinutes) : 0;
      setMinutes(totalMinutes || 0);
      setTotal(totalOrders + computerComponent);
      setTotalDiscount(totalOrders + (computerComponent-((computerComponent * discount) / 100)));
    }, 100);

    return () => {
      clearInterval(interval);
    }
  }, [computer.orders, computer.timestampEnd, computer.timestampStart, discount]);


  useActionsHeader(navigation, computer, token, operatorId, computerId, [
    {
      condition: role === 'admin' && (operatorId === computer.operator || computer.operator === null) && (computer.orders.length > 0 || total > 0) && !computerToken,
      confirm: 'Are you sure you want to delete ALL orders?',
      onPress: () => {
        clearComputerOrders({
          variables: {
            token: token,
            operator: operatorId,
            computer: computer.id,
          },
        })
      },
      icon: <AntDesign name="delete" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: (operatorId === computer.operator || computer.operator === null) && (computer.orders.length > 0 || total > 0) && !computerToken,
      confirm: 'Are you sure you want to finalize this computer?',
      onPress: () => {
        finalizeComputer({
          variables: {
            token: token,
            operator: operatorId,
            computer: computer.id,
            computerToken: "",
            paymentType: "cash",
            discount: discount,
          },
        })
      },
      icon: <MaterialIcons name="attach-money" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: (operatorId === computer.operator || computer.operator === null) && (computer.orders.length > 0 || total > 0) && !computerToken,
      confirm: <View>
        <Text>{'Are you sure you want to finalize this computer?'}</Text>
      </View>,
      onPress: () => {
        finalizeComputer({
          variables: {
            token: token,
            operator: operatorId,
            computer: computer.id,
            computerToken: "",
            paymentType: "card",
            discount: discount,
          },
        })
        setAutostop('')
        autostopRef.current = ''
      },
      icon: <MaterialIcons name="credit-card" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: (operatorId === computer.operator || computer.operator === null) && !computerToken,
      confirm: <View>
        <Text>{`Are you sure you want to ${computer.timestampStart && !computer.timestampEnd ? 'stop' : 'start/resume'} this computer time?`}</Text>
        { !computer.timestampStart && !computer.timestampEnd && <TimeSelect title={'Autostop:'} onValueChange={(value) => {
          setAutostop(value)
           autostopRef.current = value
        }} />}
      </View>,
      onPress: () => {
        
          computer.timestampStart && !computer.timestampEnd ?
          stopComputerTime({
            variables: {
              token: token,
              operator: operatorId,
              computer: computer.id,
              computerToken: ""
            },
          }) : startComputerTime({
            variables: {
              token: token,
              operator: operatorId,
              computer: computer.id,
              autostop: autostopRef.current ? parseInt(autostopRef.current) : 0,
              computerToken: ""
            },
          })
          setAutostop('')
          autostopRef.current = ''
        
      },
      icon: computer.timestampStart && !computer.timestampEnd ? <MaterialIcons name="stop" size={24} color={colorScheme === "dark" ? "white" : "black"} /> :<MaterialIcons name="play-arrow" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: true,
      icon: <View style={{ backgroundColor: "transparent", flexDirection: 'row' }}>
                  <Text style={[{fontSize: 18}]}>
                    {/* <MaterialCommunityIcons 
                      name="gold"
                      size={26}
                      color={colorScheme === "dark" ? "white" : "black"} /> */}
                    {minutes.toFixed(0)} min.
                  </Text>
            </View>
    },
    {
      condition: true,
      icon: <View style={{ backgroundColor: "transparent", flexDirection: 'row' }}>
                  <Text style={[{fontSize: 18}]}>
                    {/* <MaterialCommunityIcons 
                      name="gold"
                      size={26}
                      color={colorScheme === "dark" ? "white" : "black"} /> */}
                    {total.toFixed(2)}
                  </Text>
            </View>
    },
    {
      condition: operatorId !== computer.operator && computer.operator !== null  && !computerToken,
      confirm: 'Are you sure you want to take ownership?',
      onPress: () => {
        takeComputerOwnership({
          variables: {
            token: token,
            operator: operatorId,
            computer: computer.id
          }
        })
      },
      icon: <Feather name="refresh-ccw" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: operatorId === computer.operator && computer.operator !== null && !computerToken,
      onPress: () => {
        setComputerPickerVisible(true);
      },
      icon: <MaterialIcons name="swap-vert-circle" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
  ]);


  if (finalizeComputerLoading) return "Submitting...";
  if (finalizeComputerError) return `Submission error! ${error.message}`;

  if (clearComputerOrdersLoading) return "Submitting...";
  if (clearComputerOrdersError) return `Submission error! ${error.message}`;

  if (!computerId) {
    return null;
  }

  const renderProductItem = (type, item, index, extendedState) => <SwipeableProductItem products={products} item={item} onCreate={(orderData) => {
    createComputerOrder({
      variables: {
        ...orderData,
        token: token || computerToken,
        operator: operatorId || computerToken,
        computer: computer.id,
      },
    });
    setTimeout(() => {
      ordersRef.current.scrollToOffset(0, computer.orders.length * 160, true, true);
    }, 900);
  }}
  openOnTap={true}
  colorScheme={colorScheme}
  index={index}
  chooseVariantValue={extendedState.chooseVariantValue[index]}
  chooseVariant={(index, variant) => {
    const newState = {
      ...extendedState,
      chooseVariantValue: {
        ...extendedState.chooseVariantValue,
      }
    };
    newState.chooseVariantValue[index] = variant;
    setExtendedState(newState);
  }}
  imageValue={extendedState.imageValue[index]}
  setImageValue={(index, image) => {
    const newState = {
      ...extendedState,
      imageValue: {
        ...extendedState.imageValue,
      }
    };
    newState.imageValue[index] = image;
    setExtendedState(newState);
  }}
  commentValue={extendedState.commentValue[index]}
  setCommentValue={(index, comment) => {
    const newState = {
      ...extendedState,
      commentValue: {
        ...extendedState.commentValue,
      }
    };
    newState.commentValue[index] = comment;
    setExtendedState(newState);
  }}
/>
  
  const renderItem = (type, item) => {
    let swipeableRow;

    function updateRef (ref) {
      swipeableRow = ref;
    };


    return item.productIds.map((productId, index) => {
      const product = products.filter((p) => p.id === productId)[0];
      const indexOfVariant = product.variants.findIndex(
        (v) => v === item.variants[0]
      );
      const price = product.prices[indexOfVariant];
      return (
        <Swipeable
        ref={updateRef}
        useNativeAnimations={Platform.OS !== 'web'}
        enableTrackpadTwoFingerGesture
        onSwipeableOpen={(direction) => {
          if (computerToken) {
            if (swipeableRow) {
              swipeableRow.close();
            }
            return;
          }
          if (direction === 'right') {
            const index = products.findIndex((product) => product.id === item.id);
            const product = products[index];

            setTimeout(() => {
              deleteComputerOrder({
                variables: {
                  token: token,
                  operator: operatorId,
                  computer: computer.id,
                  id: item.id,
                },
              });
            }, 500)
            
            if (swipeableRow) {
              swipeableRow.close();
            }
          }
        }}
        onSwipeableClose={(direction) => {
          console.log(`Closing swipeable to the ${direction}`);
        }}
        renderRightActions={(progress, dragX) => {
        const trans = dragX.interpolate({
          inputRange: [0, 50, 100, 101],
          outputRange: [-20, 0, 0, 1],
        });
        return (
          <RectButton style={{
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
          } } onPress={() => {}}>
            <Animated.Text
              style={[
                {
                  color: 'white',
                  //transform: [{ translateX: trans }],
                },
              ]}>
              {!computerToken && 'BYE, Salut, Arrivederci, Чао, 😔'}
            </Animated.Text>
          </RectButton>
        );
      }}>
            <View style={{ flex: 1 }}>
              <ProductItem
                {...product}
                variant={indexOfVariant}
                itemStyle={{padding: 15}}
                comment={item.comment}
                colorScheme={colorScheme}
                onRemove={(options) => {
                  // const index = newOrderItems.findIndex(
                  //   (o) => o.tempId === options.tempId
                  // );
                  // newOrderItems.splice(index, 1);
                  // setNewOrderItems([...newOrderItems]);
                }}
              />
            </View>
        {/* </TouchableHighlight> */}
      </Swipeable>
      );
    })[0];
  };

  const renderStickyItem = (type, item, index) => (
    <FlatList
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{backgroundColor: colorScheme === 'light' ? 'white' : 'black'}}
                  data={categories}
                  keyExtractor={(item, index) => item.id}
                  renderItem={({ item, index }) => (
                    <TouchableHighlight onPress={() => {
                      if (productsListRef.current) {
                        let firstIndex = 0;
                        try {
                          firstIndex = Math.floor(products.findIndex(p => p.category === item.id) / numColumns);
                          //productsListRef.current.scrollToIndex({ viewOffset: 65, animated: true, index: firstIndex });
                          console.log(firstIndex);
                          productsListRef.current.scrollToOffset(0, firstIndex * 160, true);
                        } catch (e) {
                          console.log(firstIndex, e);
                        }
                      }
                    }}>
                      <View style={{ padding: 5 }}>
                        <Text style={{ fontSize: 32 }}>{i18n.t(item.name)}{index === categories.length - 1 ? '' : ' |'}</Text>
                      </View>
                    </TouchableHighlight>
                  )}
                />
  );


  return (
    <View style={styles.container}>
      { role === 'admin' && computer && ((computer.operator === operatorId) || (computer.operator === null && operatorId))  ?
        <View style={{ padding: 20}}>
          <View>
              <Text style={{fontSize: 16}}>Discount: {discount} % | Pay: {totalDiscount.toFixed(2)} | Play: { (totalDiscount - totalOrders).toFixed(2)} | Bar: {totalOrders.toFixed(2) }</Text>
          </View>
        <Slider
          style={{flex: 1}}
          minimumValue={0}
          maximumValue={30}
          onValueChange={(value) => { setDiscount(value)}}
          step={1}
          minimumTrackTintColor="#FFFFFF"
          maximumTrackTintColor="#0F0000"
        />
        </View>

        : null}
      <View style={styles.section1}>
        {banner && <View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}><Text style={{fontSize: 48}}>{banner}</Text></View>}
      { computer && computer.orders && computer.orders.length > 0 ? <RecyclerListView
              style={{ flex: 1 }}
              canChangeSize={true}
              ref={(ref) => { ordersRef.current = ref}}
              contentContainerStyle={{ margin: 3 }}
              dataProvider={dataProvider2}
              layoutProvider={layoutProvider2}
              renderAheadOffset={computer.orders.length}
              rowRenderer={renderItem}
          />
        : null}
      </View>
              
      {(computerToken ||
        (operatorId &&
          (operatorId === computer.operator ||
            computer.operator === null))) && (
        <View style={styles.section2}>
          <View style={[styles.newOrderSection2]}>
            {products && products.length > 0 ? <StickyContainer
                  stickyHeaderIndices={[0]}
                  overrideRowRenderer={renderStickyItem}
                >
                <RecyclerListView
                  style={{ flex: 1, paddingTop: 55 }}
                  ref={(ref) => { productsListRef.current = ref}}
                  contentContainerStyle={{ margin: 3 }}
                  canChangeSize={true}
                  extendedState={extendedState}
                  dataProvider={dataProvider}
                  layoutProvider={layoutProvider}
                  renderAheadOffset={products.length}
                  rowRenderer={renderProductItem}
                />
              </StickyContainer>
              : null}
          </View>
        </View>
      )}
      <AlertRoot />
      <Modal
        animationType="slide"
        transparent={true}
        visible={computerPickerVisible}
        onRequestClose={() => {
          setComputerPickerVisible(false);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Which one to swap with?</Text>
            <ComputerPicker type={computer.type} computers={computers} operatorId={operatorId} excludeComputerId={computer.id} onClick={(otherComputer) => {
              setComputerPickerVisible(false);
              Alert.alert(
                "Please confirm",
                `Swap ${computer.name} with ${otherComputer.name} ?`,
                [
                    {
                    text: "Cancel",
                    onPress: () => { }
                    },
                    {
                    text: "Yes",
                    style: "cancel",
                    onPress: () => {
                      swapComputers({
                        variables: {
                          token: token,
                          operator: operatorId,
                          computer: computer.id,
                          otherComputer: otherComputer.id
                        },
                      })
                    }
                        
                    },
                ],
                { cancelable: false },
                )
            }}
            onClose={() => {setComputerPickerVisible(false)}}
            />
          </View>
        </View>
      </Modal>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 5,
    paddingTop: 5,
    paddingRight: 5,
    alignItems: "stretch",
    justifyContent: "center",
  },
  containerListView: {
    flex: 1,
  },
  section1: {
    flex: 1,
  },
  section2: {
    borderColor: "white",
    borderTopWidth: 20,
    flex: 1,
  },
  newOrderSection1: {
    flex: 1,
  },
  newOrderSection2: {
    flex: 1,
  },
  button: {
    marginLeft: 50,
  },
  mainItem: {
    fontSize: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 2,
    height: 1,
    width: "80%",
  },
  backTextWhite: {
    color: "#FFF",
  },
  rowFrontContainer: {},
  rowFront: {
    alignItems: "stretch",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    flex: 1,
  },
  rowBack: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  backRightBtnRight: {
    backgroundColor: "red",
    right: 0,
  },
  productsBackRightBtnRight: {
    backgroundColor: "blue",
    right: 0,
  },
});
