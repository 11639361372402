import { StatusBar } from "expo-status-bar";
import {
  Animated,
  Platform,
  Button,
  StyleSheet,
  FlatList,
  TouchableHighlight,
  useWindowDimensions,
  useColorScheme,
  TextInput,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState, useEffect, useRef } from "react";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { RectButton } from 'react-native-gesture-handler';
import { MaterialIcons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { Text, View } from "./Themed";
import OrderItem from "./OrderItem";
import Alert, { AlertRoot } from "./Alert";
import ProductItem from "./ProductItem";
import SwipeableProductItem from './SwipeableProductItem';

import StickyContainer from 'recyclerlistview/dist/reactnative/core/StickyContainer';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import i18n from "../utils/localization";

import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";

const CREATE_OPERATOR_ORDER = gql`
  mutation createOperatorOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $productIds: [ID!]!
    $quantities: [Int!]!
    $variants: [String!]!
    $comment: String
    $capabilities: [String]!
  ) {
    createOperatorOrder(
      id: $id
      token: $token
      operator: $operator
      productIds: $productIds
      quantities: $quantities
      variants: $variants
      comment: $comment
      capabilities: $capabilities
    ) {
      id
      name
      timestampStart
      timestampEnd
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        capabilities
        timestamp
        status
      }
    }
  }
`;


const DELETE_OPERATOR_ORDER = gql`
  mutation deleteOperatorOrder(
    $id: ID!
    $token: String!
    $operator: ID!
  ) {
    deleteOperatorOrder(
      id: $id
      token: $token
      operator: $operator
    ) {
      id
      name
      orders {
        id
        operator
        productIds
        quantities
        variants
        capabilities
        comment
        timestamp
        status
      }
    }
  }
`;


const OPERATORS_SUBSCRIPTION = gql`
  subscription {
    operators {
      id
      name
      timestampStart
      timestampEnd
      orders {
        id
        operator
        productIds
        quantities
        comment
        timestamp
        capabilities
        status
      }
    }
  }
`;
const Jared = { width: 1920, numColumns: 1 };

export default function OperatorOrderView({ route, navigation, products, operator, categories }) {
  
  const ordersRef = useRef(null);

  const window = useWindowDimensions();
  const [newOrderItems, setNewOrderItems] = useState([]);
  const [rowTranslateAnimatedValues, setTranslateAnimatedValues] = useState({});
  const [variants, setVariants] = useState({});

  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState(null);
  const [token, setToken] = useState(null);

  const [comment, setComment] = useState('');
  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { operatorsSubscriptionData, operatorsSubscriptionLoading } =
    useSubscription(OPERATORS_SUBSCRIPTION);

  const [
    createOperatorOrder
  ] = useMutation(CREATE_OPERATOR_ORDER, {
    ignoreResults: true,
  });

  const [
    deleteOperatorOrder
  ] = useMutation(DELETE_OPERATOR_ORDER, {
    ignoreResults: true
  });

  const productsListRef = useRef(null);

  
  Jared.width = window.width;
  const [dataProvider, setDataProvider] = useState(() => { return new DataProvider((r1, r2) => r1 !== r2) });
  const [dataProvider2, setDataProvider2] = useState(() => { return new DataProvider((r1, r2) => r1 !== r2) });
  const [extendedState, setExtendedState] = useState({
    chooseVariantValue: {},
    commentValue: {},
    imageValue: {},
  });
  const [numColumns, setNumColumns] = useState(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    return numColumns;
  });

  const [layoutProvider, setLayoutProvider] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 30) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  const [layoutProvider2, setLayoutProvider2] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 30) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  useEffect(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    setNumColumns(numColumns);
    Jared.width = window.width;
    Jared.numColumns = numColumns;
  }, [window]);

  useEffect(() => {
    if (products) {
      setDataProvider(dataProvider.cloneWithRows(products))
    }
  }, [products]);

  useEffect(() => {
    if (operator && operator.orders) {
      setDataProvider2(dataProvider2.cloneWithRows(operator.orders))
    }
  }, [operator]);


  const renderProductItem = (type, item, index, extendedState) => <SwipeableProductItem products={products} item={item} onCreate={(orderData) => {
    createOperatorOrder({
      variables: {
        ...orderData,
        token: token,
        operator: operatorId,
      },
    });
    setTimeout(() => {
      ordersRef.current.scrollToOffset(0, operator.orders.length * 160, true, true);
    }, 900);
  }}
  openOnTap={true}
  colorScheme={colorScheme}
  index={index}
  chooseVariantValue={extendedState.chooseVariantValue[index]}
  chooseVariant={(index, variant) => {
    const newState = {
      ...extendedState,
      chooseVariantValue: {
        ...extendedState.chooseVariantValue,
      }
    };
    newState.chooseVariantValue[index] = variant;
    setExtendedState(newState);
  }}
  imageValue={extendedState.imageValue[index]}
  setImageValue={(index, image) => {
    const newState = {
      ...extendedState,
      imageValue: {
        ...extendedState.imageValue,
      }
    };
    newState.imageValue[index] = image;
    setExtendedState(newState);
  }}
  commentValue={extendedState.commentValue[index]}
  setCommentValue={(index, comment) => {
    const newState = {
      ...extendedState,
      commentValue: {
        ...extendedState.commentValue,
      }
    };
    newState.commentValue[index] = comment;
    setExtendedState(newState);
  }}
/>

  const renderItem = (type, item) => {
    let swipeableRow;

    function updateRef (ref) {
      swipeableRow = ref;
    };


    return item.productIds.map((productId, index) => {
      const product = products.filter((p) => p.id === productId)[0];
      const indexOfVariant = product.variants.findIndex(
        (v) => v === item.variants[0]
      );
      const price = product.prices[indexOfVariant];
      return (
        <Swipeable
        ref={updateRef}
        useNativeAnimations={Platform.OS !== 'web'}
        enableTrackpadTwoFingerGesture
        onSwipeableOpen={(direction) => {
          if (direction === 'right') {
            const index = products.findIndex((product) => product.id === item.id);
            const product = products[index];

            setTimeout(() => {
              deleteOperatorOrder({
                variables: {
                  token: token,
                  operator: operatorId,
                  id: item.id,
                },
              });
            }, 500)
            
            if (swipeableRow) {
              swipeableRow.close();
            }
          }
        }}
        onSwipeableClose={(direction) => {
          console.log(`Closing swipeable to the ${direction}`);
        }}
        renderRightActions={(progress, dragX) => {
        const trans = dragX.interpolate({
          inputRange: [0, 50, 100, 101],
          outputRange: [-20, 0, 0, 1],
        });
        return (
          <RectButton style={{
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
          } } onPress={() => {}}>
            <Animated.Text
              style={[
                {
                  color: 'white',
                  //transform: [{ translateX: trans }],
                },
              ]}>
              {'BYE, Salut, Arrivederci, Чао, 😔'}
            </Animated.Text>
          </RectButton>
        );
      }}>
            <View style={{ flex: 1 }}>
              <ProductItem
                {...product}
                variant={indexOfVariant}
                itemStyle={{padding: 15}}
                comment={item.comment}
                colorScheme={colorScheme}
                onRemove={(options) => {
                  // const index = newOrderItems.findIndex(
                  //   (o) => o.tempId === options.tempId
                  // );
                  // newOrderItems.splice(index, 1);
                  // setNewOrderItems([...newOrderItems]);
                }}
              />
            </View>
        {/* </TouchableHighlight> */}
      </Swipeable>
      );
    })[0];
  };

  
  const renderStickyItem = (type, item, index) => (
    <FlatList
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{backgroundColor: colorScheme === 'light' ? 'white' : 'black'}}
                  data={categories}
                  keyExtractor={(item, index) => item.id}
                  renderItem={({ item, index }) => (
                    <TouchableHighlight onPress={() => {
                      if (productsListRef.current) {
                        let firstIndex = 0;
                        try {
                          firstIndex = Math.floor(products.findIndex(p => p.category === item.id) / numColumns);
                          //productsListRef.current.scrollToIndex({ viewOffset: 65, animated: true, index: firstIndex });
                          console.log(firstIndex);
                          productsListRef.current.scrollToOffset(0, firstIndex * 160, true);
                        } catch (e) {
                          console.log(firstIndex, e);
                        }
                      }
                    }}>
                      <View style={{ padding: 5 }}>
                        <Text style={{ fontSize: 32 }}>{i18n.t(item.name)}{index === categories.length - 1 ? '' : ' |'}</Text>
                      </View>
                    </TouchableHighlight>
                  )}
                />
  );



  return (
    <View style={styles.container}>
      <View style={styles.section1}>
      { operator && operator.orders && operator.orders.length > 0 ? <RecyclerListView
              style={{ flex: 1}}
              ref={(ref) => { ordersRef.current = ref}}
              canChangeSize={true}
              contentContainerStyle={{ margin: 3 }}
              dataProvider={dataProvider2}
              layoutProvider={layoutProvider2}
              renderAheadOffset={operator.orders.length}
              rowRenderer={renderItem}
          />
        : null}
      </View>

        <View style={styles.section2}>
          <View style={[styles.newOrderSection2]}>
          {products && products.length > 0 ? <StickyContainer
                  stickyHeaderIndices={[0]}
                  overrideRowRenderer={renderStickyItem}
                >
                <RecyclerListView
                  style={{ flex: 1, paddingTop: 55, }}
                  ref={(ref) => { productsListRef.current = ref}}
                  contentContainerStyle={{ margin: 3 }}
                  canChangeSize={true}
                  extendedState={extendedState}
                  dataProvider={dataProvider}
                  layoutProvider={layoutProvider}
                  renderAheadOffset={products.length}
                  rowRenderer={renderProductItem}
                />
              </StickyContainer>
              : null}
          </View>
          <AlertRoot />
        </View>
        
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 5,
    paddingTop: 5,
    paddingRight: 5,
    alignItems: "stretch",
    justifyContent: "center",
  },
  containerListView: {
    flex: 1,
  },
  section1: {
    flex: 1,
  },
  section2: {
    borderColor: "white",
    borderTopWidth: 20,
    flex: 1,
  },
  newOrderSection1: {
    flex: 1,
  },
  newOrderSection2: {
    flex: 1,
  },
  button: {
    marginLeft: 50,
  },
  mainItem: {
    fontSize: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 2,
    height: 1,
    width: "80%",
  },
  backTextWhite: {
    color: "#FFF",
  },
  rowFrontContainer: {},
  rowFront: {
    alignItems: "stretch",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    flex: 1,
  },
  rowBack: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  backRightBtnRight: {
    backgroundColor: "red",
    right: 0,
  },
  productsBackRightBtnRight: {
    backgroundColor: "blue",
    right: 0,
  },
});
