const themes = {
    hrlm: {
        logoFontFamily: "Pricedown-Bl",
        defaultFontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
    },
    nizza: {
        logoFontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        defaultFontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
    }
}

const config = {
    hrlm: {
        menuTitle: "Harlem Menu",
        companyColor: 'red',
        invitationMessage: `You're invited to join HRLM @ REFRACTION X`,
        modules: {
            vr: true,
            esports: true,
            menu: true,
            benefits: true,
            delivery: false,
        }
    },
    nizza: {
        menuTitle: "Burger Nizza Menu",
        companyColor: '#c00a25',
        invitationMessage: `You're invited to join NIZZA @ THE VENUE`,
        modules: {
            vr: false,
            esports: false,
            menu: true,
            benefits: false,
            delivery: true,
        }
    }
}

export default {
    theme: themes[process.env.EXPO_PUBLIC_THEME],
    ...config[process.env.EXPO_PUBLIC_THEME]
}
