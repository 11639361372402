import { StatusBar } from "expo-status-bar";
import { Platform, StyleSheet } from "react-native";
import QRCode from "react-native-qrcode-svg";
import { Text, View } from "../components/Themed";
import { getStack } from "../constants/Stacks";

const stack = getStack();

export default function ModalScreen({ navigation, route }) {
  const tableId = route.params.tableId;
  const tableToken = route.params.tableToken;
  const computerId = route.params.computerId;
  const computerToken = route.params.computerToken;
  return (
    <View style={styles.container}>
      {tableId && tableToken && <QRCode
        value={`${stack.web}/menu/tables/${route.params.tableId}/token/${route.params.tableToken}`}
        size={256}
      />}
      {computerId && computerToken && <QRCode
        value={`${stack.web}/menu/computers/${route.params.computerId}/token/${route.params.computerToken}`}
        size={256}
      />}
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
