import React from "react";
import { View, Text } from "react-native";

export default function ({text, style, colorScheme}) {
  return (
    <View style={{ backgroundColor: "transparent" }}>
      <View style={{ backgroundColor: "transparent" }}>
        <Text
          style={[
            style,
            {
              color: colorScheme === "dark" ? "white" : "black",
              textShadowColor: colorScheme === "dark" ? "black" : "white",
              textShadowOffset: { width: -1, height: -1 },
              textShadowRadius: 1,
            },
          ]}
        >
          {text}
        </Text>
      </View>
      <View style={{ position: "absolute", backgroundColor: "transparent" }}>
        <Text
          style={[
            style,
            {
              color: colorScheme === "dark" ? "white" : "black",
              textShadowColor: colorScheme === "dark" ? "black" : "white",
              textShadowOffset: { width: 1, height: 1 },
              textShadowRadius: 1,
            },
          ]}
        >
          {text}
        </Text>
      </View>
      <View style={{ position: "absolute", backgroundColor: "transparent" }}>
        <Text
          style={[
            style,
            {
              color: colorScheme === "dark" ? "white" : "black",
              textShadowColor: colorScheme === "dark" ? "black" : "white",
              textShadowOffset: { width: -1, height: 1 },
              textShadowRadius: 1,
            },
          ]}
        >
          {text}
        </Text>
      </View>
      <View style={{ position: "absolute", backgroundColor: "transparent" }}>
        <Text
          style={[
            style,
            {
              color: colorScheme === "dark" ? "white" : "black",
              textShadowColor: colorScheme === "dark" ? "black" : "white",
              textShadowOffset: { width: 1, height: -1 },
              textShadowRadius: 1,
            },
          ]}
        >
          {text}
        </Text>
      </View>
    </View>
  );
}
