import { StatusBar } from "expo-status-bar";
import { useWindowDimensions, Platform, StyleSheet, FlatList, Text, TextInput, Pressable, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { View } from "../components/Themed";
import { useQuery, useMutation, gql } from "@apollo/client";
import {Picker} from '@react-native-picker/picker';
import Config from "../constants/Config";
const OPERATORS_QUERY = gql`
  query getOperators($token: String!) {
    operators(token: $token) {
      id
      name
      role
    }
  }
`;

const SEND_INVITE = gql`
  mutation sendInvite(
    $token: String!
    $operator: ID!
    $assignOperator: ID
    $type: String!
    $destination: String!
  ) {
    sendInvite(
      token: $token
      operator: $operator
      type: $type
      destination: $destination
      assignOperator: $assignOperator
    )
  }
`;


export default function OperatorSettingsInviteScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const window = useWindowDimensions();
  const colorScheme = useColorScheme();

  const [inviteSent, setInviteSent] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [assignedOperator, setAssignedOperator] = useState('')
  const [destination, setDestination] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}

      try {
        const storedAdminToken = await AsyncStorage.getItem("adminToken");
        setAdminToken(storedAdminToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const [
    sendInvite,
  ] = useMutation(SEND_INVITE);

  const { loading, error, data } = useQuery(OPERATORS_QUERY, {
    variables: { token: token },
  });

  return (
    <View style={styles.container}>
        {!inviteSent && <View style={{flex: 1}}>
          <View>
            <TextInput onChangeText={(text) => {
              setDestination(text);
            }} placeholder="Email" defaultValue={destination} style={{margin: 15, padding: 15, borderRadius: 12, color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24}} />
          </View>

          <Picker
            selectedValue={assignedOperator}
            onValueChange={(itemValue, itemIndex) =>
              setAssignedOperator(itemValue)
            }>
            <Picker.Item label="None" value="" />
            {data && data.operators.map(op => (<Picker.Item key={op.id} label={op.name} value={op.id} />))}
            
          </Picker>

          <Pressable onPress={async () => {
            const result = await sendInvite({
              variables: {
                token: token,
                operator: operatorId,
                type: 'email',
                destination: destination,
                assignOperator: assignedOperator ? assignedOperator : null,
              },
            })

            

            if (result && result.data && result.data.sendInvite) {
              setInviteSent(true);
              setErrorMessage(null)
            } else {
              setInviteSent(true)
              setErrorMessage('Couldn\'t send the invite at this time');
            }
            console.log(result)
          }}>
            <View style={{margin: 15, padding: 15, borderRadius: 12, backgroundColor: Config.companyColor}}>
              <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24}}>Invite</Text>
            </View>
          </Pressable>
        </View>}
        {inviteSent && <View style={{flex: 1}}>
          <View>
            <Text style={{margin: 15, padding: 15, borderRadius: 12, color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24}}>{errorMessage ? errorMessage : 'Invite sent!'}</Text>
          </View>
          <Pressable onPress={async () => {
            setErrorMessage(null);
            setInviteSent(false);
            setDestination(null)
          }}>
            <View style={{margin: 15, padding: 15, borderRadius: 12, backgroundColor: Config.companyColor}}>
              <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24}}>{errorMessage ? 'Try again' : 'Send another'}</Text>
            </View>
          </Pressable>
        </View>}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
