import { AntDesign } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import { useColorScheme } from "react-native";
import { useQuery, gql, useMutation, useSubscription } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { TouchableHighlight, Text } from "../components/Themed";
import DeliveryOrderView from "../components/DeliveryOrderView";

const DELIVERY_DETAILS = gql`
  query getDeliveryDetails($deliveryId: ID!, $token: String!) {
    locations(token: $token) {
      id
      name
      city
    }
    products(token: $token) {
      id
      title
      subtitle
      imageUrls
      prices
      capabilities
      category
      options {
        id
        title
        type
        required
        options {
          id
          title
          prices
        }
      }
      variants
    }
    categories(token: "token") {
      id
      name
      order
    }
    delivery(token: $token, id: $deliveryId) {
      id
      operator
      location
      status
      city
      comment
      street
      finalized
      phone
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        options
        capabilities
        timestamp
        status
      }
    }
  }
`;

const DELIVERIES_SUBSCRIPTION = gql`
  subscription {
    deliveries {
      id
      operator
      location
      status
      city
      comment
      street
      finalized
      phone
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        capabilities
        options
        timestamp
        status
      }
    }
  }
`;

export default function DeliveryDetailsScreen(props) {
  const deliveryId = props.route.params && props.route.params.deliveryId;
  const deliveryToken = props.route.params && props.route.params.deliveryToken;
  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(DELIVERY_DETAILS, {
    variables: { deliveryId, token: token || deliveryToken || ''},
    nextFetchPolicy: 'cache-only',
  });

  

  const { data: subscriptionData, loading: subscriptionLoading, error: subscriptionError } =
    useSubscription(DELIVERIES_SUBSCRIPTION);

  
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  const delivery = subscriptionData ? subscriptionData.deliveries.filter(t => t.id === deliveryId)[0] : data.delivery;

  return <DeliveryOrderView {...props} categories={data.categories} products={data.products} delivery={delivery} locations={data.locations} />;
}
