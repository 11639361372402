import React, {useState} from "react";
import { StatusBar } from "expo-status-bar";
import {
  Platform,
  StyleSheet,
  useColorScheme,
  TouchableOpacity,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { TextInput, Pressable } from 'react-native';
import { Text, View, TouchableHighlight } from "../components/Themed";
import { useLinkTo } from "@react-navigation/native";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
import RefractionXLogo from "../components/RefractionXLogo";
import * as Themed from "../components/Themed";
import Config from "../constants/Config";

const ThemedText = Themed.Text;

const INVITE_DETAILS = gql`
  query getInviteDetails($token: String!) {
    invite(token: $token) {
      id
      by
      created
      expires
      type
      destination
    }
  }
`;

const CREATE_CREDENTIAL = gql`
  mutation createCredential(
    $token: String!
    $password: String!
  ) {
    createCredential(
      token: $token
      password: $password
    )
  }
`;

export default function PublicInviteScreen(props) {
  const linkTo = useLinkTo();
  const token = props.route.params && props.route.params.token;
  const colorScheme = useColorScheme();

  const { loading, error, data } = useQuery(INVITE_DETAILS, {
    variables: { token: token },
    nextFetchPolicy: 'cache-only',
  });

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const passRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
  const symbolRegex = /[!@#$%^&*]+/;
  const lowerRegex = /[a-z]+/;
  const upperRegex = /[A-Z]+/;
  const digitRegex = /\d+/;
  
  const [
    createCredential,
  ] = useMutation(CREATE_CREDENTIAL);

  if (loading) return <ThemedText>Loading...</ThemedText>;
  
  if (!data || (data && !data.invite))
    return (
      <View style={styles.container}>
        <Text style={styles.title}>wasted</Text>
        <TouchableOpacity
          onPress={() => props.navigation.replace("Menu")}
          style={styles.link}
        >
          <Text style={styles.linkText}>Please, ask staff for help! | Click for main menu</Text>
        </TouchableOpacity>
      </View>
    );

  const expires = new Date(parseInt(data.invite.expires));
  if (expires < new Date()) {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>expired</Text>
        <TouchableOpacity
          onPress={() => props.navigation.replace("Menu")}
          style={styles.link}
        >
          <Text style={styles.linkText}>Your invite has expired please ask our staff for a new one! | Click for main menu</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return <View style={{flex: 1}}>
    <View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}><RefractionXLogo /></View>
    <View style={{position: 'absolute', backgroundColor: 'transparent', left: 0, right: 0, top: 0, bottom: 0, flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <Text style={[styles.title, {margin: 20, textShadowOffset: null}]}>{Config.invitationMessage}</Text>
      <Text style={[styles.title, { fontSize: 24, borderBottomColor: 'red', borderBottomWidth: 2 }]}>Create your password:</Text>
      <View style={{backgroundColor: 'transparent'}}>
          <View style={{backgroundColor: 'transparent'}}>
            <TextInput secureTextEntry={true} onChangeText={(text) => {
              setPassword(text)
            }} placeholder="Password" defaultValue={''} style={{margin: 15, padding: 15, borderRadius: 12, color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24, backgroundColor: 'transparent'}} />
          </View>
          <View style={{backgroundColor: 'transparent'}}>
            <TextInput secureTextEntry={true} onChangeText={(text) => {
              setRepeatPassword(text)
            }} placeholder="Repeat password" defaultValue={''} style={{margin: 15, padding: 15, borderRadius: 12, color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24, backgroundColor: 'transparent'}} />
          </View>
          {password && repeatPassword && password === repeatPassword && passRegex.test(password) && <Pressable onPress={async () => {
            const result = await createCredential({
              variables: {
                token: token,
                password: password
              },
            })
            if (result && result.data && result.data.createCredential) {
              setErrorMessage('');
              props.navigation.replace("OperatorRoot", { screen: 'OperatorSettingsRoot'})
            } else {
              setErrorMessage('Something went wrong creating your credentials');
            }
            
          }}>
            <View style={{margin: 15, padding: 15, borderRadius: 12, backgroundColor: 'red'}}>
              <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: Config.theme.defaultFontFamily, fontSize: 24}}>Create</Text>
            </View>
          </Pressable>}
          
        </View>
        <View>
          <Text style={[{ fontSize: 18 }]}>Minimum requirements:</Text>
          <Text style={[{ fontSize: 18, color: password && password.length > 9 ? 'green' : 'red' }]}>Length 10 or more</Text>
          <Text style={[{ fontSize: 18, color: symbolRegex.test(password) ? 'green' : 'red'}]}>At least one special symbol</Text>
          <Text style={[{ fontSize: 18, color: upperRegex.test(password) ? 'green' : 'red' }]}>At least one upper case letter</Text>
          <Text style={[{ fontSize: 18, color: lowerRegex.test(password) ? 'green' : 'red' }]}>At least one lower case letter</Text>
          <Text style={[{ fontSize: 18, color: digitRegex.test(password) ? 'green' : 'red' }]}>At least one number</Text>
          {password && repeatPassword && password.indexOf(repeatPassword) !== 0 && <Text style={[{ fontSize: 18, color: 'red' }]}>Your password don't match</Text>}
        </View>
        {errorMessage && <Text style={[{ fontSize: 18 }]}>{errorMessage}</Text>}
    </View>
  </View>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    fontFamily: Config.defaultFontFamily,
    textShadowOffset: { width: 1, height: 1 },
    textShadowColor: 'black'
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    fontFamily: Config.defaultFontFamily,
    color: "#2e78b7",
  },
});
