import {
  Text as DefaultText,
  View as DefaultView,
  TouchableHighlight as DefaultTouchableHightlight,
} from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import Config from "../constants/Config";
export function useThemeColor(
  props,
  colorName
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

export function Text(props) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");

  return (
    <DefaultText
      style={[{ color, fontFamily: Config.theme.defaultFontFamily }, style]}
      {...otherProps}
    />
  );
}

export function View(props) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background"
  );

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function TouchableHighlight(props) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const underlayColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "underlay"
  );

  return (
    <DefaultTouchableHightlight
      style={style}
      underlayColor={underlayColor}
      {...otherProps}
    />
  );
}
