import { StatusBar } from "expo-status-bar";
import { useWindowDimensions, Pressable, Platform, StyleSheet, ScrollView, Text, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { View } from "../components/Themed";
import ColorPicker from 'react-native-wheel-color-picker'
import space from 'color-space';
import { useQuery, gql, useMutation } from "@apollo/client";

const LIGHTS_QUERY = gql`query getLights($token: String!){
  lights(token: $token){
    id
    name
    type
    color
    secondaryColor
    effect
    proxy
    effects
    ips
  }
}`;

const CHANGE_LIGHT = gql`
  mutation changeLight(
    $token: String!
    $operator: ID!
    $targets: [String!]!
    $color: [Int!]
    $secondaryColor: [Int!]
    $effect: String
  ) {
    changeLight(
      token: $token
      operator: $operator
      targets: $targets
      color: $color
      secondaryColor: $secondaryColor
      effect: $effect
    )
  }
`;

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return [ parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16) ];
}


export default function OperatorSettingsLightsAndColorsScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const window = useWindowDimensions();
  const colorScheme = useColorScheme();
  const [colors, setColors] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(LIGHTS_QUERY, {
    variables: { token },
  });

  const [
    changeLight,
  ] = useMutation(CHANGE_LIGHT);
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  const lights = [
    {id: 'all', name: 'All'},
    ...data.lights,
  ]
  return (
        <View style={{flex: 1}}>
          <ScrollView>
          {(lights.map(light => (<View key={light.id} style={{padding: 20 }}>
            <Text style={{color: colorScheme === 'dark' ? 'white':'black', fontFamily: 'Pricedown-BL', fontSize: 40}}>{light.name}</Text>
            <View key={light.id} style={{flex: 1 }}>
            <ColorPicker
              onColorChangeComplete={(color) => {
                console.log(color);
                console.log(hexToRgb(color))
                if (color==='#ffffff') {
                  return;
                }
                let c = light.color && light.color.length > 3 ? [...hexToRgb(color), 255, 255] : hexToRgb(color)
                colors[light.id+'-primary'] = c
                console.log(colors)
                setColors({
                  ...colors,
                })
                changeLight({
                  variables: {
                    token: token,
                    operator: operatorId,
                    targets: [light.id],
                    color: colors[light.id+'-primary'],
                    secondaryColor: colors[light.id+'-secondary'],
                    effect: null,
                  },
                })
              }}
              style={{ flex: 1 }}
              thumbSize={40}
              sliderSize={40}
              noSnap={true}
              row={false}
            />
            {light.type === 'wled' && <ColorPicker
              onColorChangeComplete={(color) => {
                console.log(color);
                console.log(hexToRgb(color))
                if (color==='#ffffff') {
                  return;
                }
                let c = light.color && light.color.length > 3 ? [...hexToRgb(color), 255, 255] : hexToRgb(color)
                colors[light.id+'-secondary'] = c
                console.log(colors)
                setColors({
                  ...colors,
                })
                changeLight({
                  variables: {
                    token: token,
                    operator: operatorId,
                    targets: [light.id],
                    color: colors[light.id+'-primary'],
                    secondaryColor: colors[light.id+'-secondary'],
                    effect: null,
                  },
                })
              }}
              style={{ flex: 1 }}
              thumbSize={40}
              sliderSize={40}
              noSnap={true}
              row={false}
            />}
            
            </View>
            {light.effects && <ScrollView horizontal={true} style={{backgroundColor: 'purple'}}>
              {light.effects.map(effect => (<View key={effect}><Pressable><Text style={{color: colorScheme === 'dark' ? 'white':'black', fontFamily: 'Pricedown-BL', fontSize: 20}}>{effect}</Text></Pressable></View>))}
            </ScrollView>}
          </View>)))}
          </ScrollView>
          <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
        </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
