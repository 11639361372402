import { StatusBar } from "expo-status-bar";
import {
  Platform,
  TextInput,
  StyleSheet,
  FlatList,
  Image,
  useColorScheme,
  useWindowDimensions,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Text, View } from "../components/Themed";
import OrderItem from "../components/OrderItem";
import Alert, { AlertRoot } from "../components/Alert";
import ProductItem from "../components/ProductItem";
import { useLinkTo } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import { useActionsHeader } from "../components/ActionsHeader";
import i18n from "../utils/localization";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
import DeliveryItem from "../components/DeliveryItem";

const DELIVERIES = gql`
  query getDeliveryDetails($token: String!, $operatorId: ID!) {
    locations(token: $token) {
      id
      name
      city
    }
    operator(token: $token, id: $operatorId) {
      id
      defaultLocation
    }
    products(token: $token) {
      id
      title
      imageUrls
      prices
      capabilities
      variants
    }
    deliveries(token: $token) {
      id
      operator
      status
      city
      street
      location
      phone
      comment
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        options
        timestamp
        capabilities
        status
      }
    }
  }
`;

const DELIVERIES_SUBSCRIPTION = gql`
  subscription {
    deliveries {
      id
      operator
      status
      city
      street
      location
      orders {
        id
        operator
        productIds
        quantities
        comment
        variants
        timestamp
        capabilities
        status
      }
    }
  }
`;

const CREATE_DELIVERY = gql`
  mutation createDelivery(
    $token: String!
    $operator: ID!
    $id: ID!
    $location: ID!
  ) {
    createDelivery(
      token: $token
      operator: $operator
      id: $id
      location: $location
    ) {
      id
      location
      operator
    }
  }
`;


export default function DeliveriesScreen({ route, navigation }) {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [locationId, setLocationId] = useState("All");
  const colorScheme = useColorScheme();
  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const locationId = await AsyncStorage.getItem("location");
        if (locationId) {
          setLocationId(locationId);
        }
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const linkTo = useLinkTo();

  
  const { deliverySubscriptionData, deliverySubscriptionLoading } =
    useSubscription(DELIVERIES_SUBSCRIPTION);


  const { loading, error, data, refetch } = useQuery(DELIVERIES, {
    variables: { token: token, operatorId: operatorId },
  });


  

  useActionsHeader(navigation, {name: i18n.t('Deliveries')}, token, operatorId, '0', [
    {
      condition: true,
      onPress: async () => {
        const result = await createDelivery({
          variables: {
            token: token,
            operator: operatorId,
            location: data.operator.defaultLocation,
            id: uuidv4(),
          },
        })

        const newDeliveryId = result.data.createDelivery.id;
        linkTo(`/tables/deliveries/${newDeliveryId}`);
      },
      icon: <AntDesign name="addfile" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    }])


  const [
    createDelivery,
  ] = useMutation(CREATE_DELIVERY, {
    refetchQueries: [DELIVERIES]
  });


  const window = useWindowDimensions();

  if (!operatorId) return <Text>'Loading operator...'</Text>;

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  let numColumns = 1;
  if (window.width > 720) {
    numColumns = 2;
  }
  if (window.width > 1000) {
    numColumns = 3;
  }
  if (window.width > 1800) {
    numColumns = 4;
  }
  
  const renderItem = ({ item }) => (
    <DeliveryItem products={data.products} {...item} />
  );


  return (
    <View style={styles.container}>
      <View style={styles.section1}>
        <FlatList
          key={numColumns}
          onRefresh={() => refetch()}
          refreshing={loading}
          data={locationId === "All" ? data.deliveries : data.deliveries.filter(d => d.location === locationId)}
          style={{flex: 1}}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          numColumns={numColumns}
        />
      </View>

      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section1: {
    flex: 1,
  },
  mainItem: {
    fontSize: 20,
    fontFamily: "Pricedown-Bl",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
